import React from "react";
import { Field, Form, useFormikContext } from "formik";
import { Stack } from "@mui/system";
import { Box, Button, Divider, } from "@mui/material";
import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check";
import { FIELDS_NAME } from "../const";
import { TChemistryType } from "@/api/types";
import ChemistryAutocomplete
  from "@/components/Autocompletes/ChemistryAutocomplete";
import { CHEMISTRY_TYPES } from "@/const";

interface IProps {
  handleClose: () => void;
  modalProps: {
    type: TChemistryType;
  };
}

const SelectChemistryForm: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const formik = useFormikContext();
  const type = modalProps.type || CHEMISTRY_TYPES.FERTILIZER;
  const label = type === CHEMISTRY_TYPES.FERTILIZER ? "Удобрение" : "Средство защиты"

  return (
    <Form noValidate>
      <Stack p={3} spacing={2.5}>
        <ChemistryAutocomplete name={FIELDS_NAME.CHEMISTRY}
                               label={label}
                               q={{ type: type }}
                               required/>
        <Field name={FIELDS_NAME.QUANTITY_TOTAL}>
          {({ field, meta }) => (
            <TextField
              {...field}
              required
              type="number"
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              label="Количество"
              fullWidth
              size="small"
            />
          )}
        </Field>
        <Field name={FIELDS_NAME.PRICE}>
          {({ field, meta }) => (
            <TextField
              {...field}
              required
              type="number"
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              label={"Цена за ед."}
              fullWidth
              size="small"
            />
          )}
        </Field>
      </Stack>
      <Divider/>
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon/>}
          disabled={formik.isSubmitting}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default SelectChemistryForm;
