import React, { Fragment } from "react";
import { Field, Form, useFormikContext } from "formik";
import { useStores } from "@/hooks";

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { METH_FIELD_NAME, IProps, TForm } from "../const";
import { DatePickerField } from "@/components/Fields";
import FileDropzone from "@/components/FileDropzone";
import { IFile } from "@/components/FileDropzone/const";
import theme from "@/theme";
import { StyledChip } from "../../AddOBCultivar/styles";
import AddChip from "@/components/Chips/AddChip";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { ICultivar } from "@/api/interfaces/responses";

/**
 * Форма создания/редактирования
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const MethodologyForm: React.FC<IProps> = ({
  modalProps,
}) => {
  const { api, modalStore } = useStores();
  const form = useFormikContext<TForm>();

  const title = modalProps.isEdit
    ? "Редактировать запись"
    : "Добавить запись";

  const handleChangeFiles = (files: IFile[]) => {
    form.setFieldValue(METH_FIELD_NAME.FILES, files);
  };

  const handleAddCultivar = () => {
    modalStore.open(VARIANT_MODAL.SELECT_CULTIVAR, {
      onSubmit: handleSelectCultivars,
      initialValue: form.values.cultivar ?? [],
    });
  };

  const handleSelectCultivars = (payload: ICultivar[]) => {
    modalStore.setModalPropsByKey(modalProps.key, {
      initialValues: {
        ...form.values,
        [METH_FIELD_NAME.CULTIVAR]: payload,
      },
    });
  };

  const handleDeleteChip = () => {
    form.setFieldValue(METH_FIELD_NAME.CULTIVAR, null);
  };

  const deleteMutation = (fileId: number) => {
    let promise;
    if (modalProps.methodologyId) {
      promise = api.regulatoryInfo.methodologyDeleteFile(
        modalProps.methodologyId,
        fileId,
      );
    }
    return promise;
  };

  return (
    <Fragment>
      <Box p={3}>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "14px",
            color: theme.palette.blackAndWhite.gray,
          }}
        >
          Методика
        </Typography>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>
        <Stack direction={"column"} gap={2} p={2}>
          <Field name={METH_FIELD_NAME.TYPE_TRIAL}>
            {({ field }) => (
              <FormControl>
                <RadioGroup {...field}>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                  >
                    <FormLabel>Вид испытаний: </FormLabel>
                    <FormControlLabel
                    disabled={modalProps.isEdit}
                      value={"oos"}
                      control={<Radio color="secondary" sx={{
                        "&.Mui-disabled": {
                          color: theme.palette.action.disabled,
                        },
                      }} />}
                      label="ООС"
                    />
                    <FormControlLabel
                    disabled={modalProps.isEdit}
                      value={"hp"}
                      control={<Radio color="secondary" sx={{
                        "&.Mui-disabled": {
                          color: theme.palette.action.disabled,
                        },
                      }} />}
                      label="ХП"
                    />
                  </Stack>
                </RadioGroup>
              </FormControl>
            )}
          </Field>
        </Stack>

        <Stack direction={"column"} gap={2} p={2} pt={1}>
          <Field name={METH_FIELD_NAME.CULTIVAR}>
            {({ field, meta }) => {
              return (
                <FormControl required>
                  <FormLabel required>Культура</FormLabel>
                  <Stack
                    direction={"row"}
                    flexWrap={"wrap"}
                    alignItems={"center"}
                    gap={1}
                  >
                    {field.value ? (
                      <StyledChip
                        key={field.value?.id}
                        size="small"
                        variant="outlined"
                        label={field.value?.name}
                        onDelete={handleDeleteChip}
                      />
                    ) : (
                      <AddChip
                        onClick={handleAddCultivar}
                      />
                    )}
                  </Stack>
                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              );
            }}
          </Field>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field name={METH_FIELD_NAME.NAME}>
                {({ field, meta }) => (
                  <FormControl
                    required
                    size="small"
                    fullWidth
                  >
                    <TextField
                      {...field}
                      required
                      size="small"
                      label="Название"
                      fullWidth
                      variant="outlined"
                    />

                    <FormHelperText
                      error={meta.touched && !!meta.error}
                    >
                      {meta.touched && meta.error}
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Grid item xs={6}>
              <Field name={METH_FIELD_NAME.NUMBER}>
                {({ field, meta }) => (
                  <FormControl
                    required
                    size="small"
                    fullWidth
                  >
                    <TextField
                      {...field}
                      label="Номер"
                      size="small"
                      fullWidth
                      required
                      variant="outlined"
                    />
                    <FormHelperText
                      error={meta.touched && !!meta.error}
                    >
                      {meta.touched && meta.error}
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <DatePickerField
                name={METH_FIELD_NAME.CONFIRMATION_DATE}
                label="Дата утверждения"
                fullWidth
                required
              />
            </Grid>
          </Grid>

          <Field name={METH_FIELD_NAME.ADDITIONAL_INFO}>
            {({ field, meta }) => (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  type="text"
                  inputProps={{
                    maxLength: 100,
                  }}
                  label="Дополнительная информация"
                  fullWidth
                  multiline
                  rows={5}
                  variant="outlined"
                />

                <Box
                  sx={{
                    color: theme.palette.text.secondary,
                  }}
                >
                  {form.values.additional_info?.length ?? 0}
                  /100
                </Box>

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>

          <Field name={METH_FIELD_NAME.FILES}>
            {({ field, meta }) => {
              const formatedFiles = field.value?.map(
                (file) => ({
                  id: file.id, // для удаления
                  url: file.file_url,
                  name: file.file_name,
                  formatSize: file.file_size,
                }),
              );
              return (
                <FormControl>
                  <FormLabel>
                    <Typography variant="p14Medium">
                      Документы
                    </Typography>
                  </FormLabel>

                  <FileDropzone
                    multiple
                    isFileAdd={true}
                    name={"file_list"}
                    value={formatedFiles}
                    setValue={handleChangeFiles}
                    deleteMutation={deleteMutation}
                  />
                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              );
            }}
          </Field>
        </Stack>

        <Divider />
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            color="red"
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type="submit"
            disabled={form.isSubmitting}
            variant="contained"
            color="primary"
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  );
};

export default MethodologyForm;
