import yup from "@/utils/yup";
import {
  IVegetationCause,
  IBranchItem,
  IPlot
} from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  CULTIVAR: "cultivar",
  YEAR: "year",
  BRANCH: 'branch',
  PLOT: 'plot',
} as const;

export interface IFormik {
  [FIELDS_NAME.CULTIVAR]: IVegetationCause | null,
  [FIELDS_NAME.BRANCH]?: IBranchItem | null,
  [FIELDS_NAME.PLOT]?: IPlot | null,
  [FIELDS_NAME.YEAR]: number | null,
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.CULTIVAR]: yup.mixed().required(),
  [FIELDS_NAME.YEAR]: yup.number().required(),
  [FIELDS_NAME.BRANCH]: yup.mixed().required(),
});
export const validationSchemaWithPlot = yup.object().shape({
  [FIELDS_NAME.CULTIVAR]: yup.mixed().required(),
  [FIELDS_NAME.YEAR]: yup.number().required(),
  [FIELDS_NAME.PLOT]: yup.mixed().required(),
});

export const initialValues = {
  [FIELDS_NAME.CULTIVAR]: null,
  [FIELDS_NAME.YEAR]: null,
  [FIELDS_NAME.BRANCH]: null,
};
export const initialValuesWithPlot = {
  [FIELDS_NAME.CULTIVAR]: null,
  [FIELDS_NAME.YEAR]: null,
  [FIELDS_NAME.PLOT]: null,
};