import React, { useEffect } from "react";
import {
  FastField,
  Field,
  Form,
  useFormikContext,
} from "formik";
import {
  HP_TYPE_VALUES,
  THpFormik,
  TRIAL_FIELD_NAME,
} from "@/apps/EditTrial/components/HpTrial/const";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  StyledList,
  StyledRoot,
} from "@/apps/EditTrial/styles";
import CheckIcon from "@mui/icons-material/Check";
import { HP_TYPE } from "@/const";
import FastRegexTextField
  from "@/components/FastRegexTextField";
import {
  AddressAutocomplete,
} from "@/components/Autocompletes";
import WarningIcon from "@/components/icons/WarningIcon";
import useSWR from "swr";
import { SWR_KEYS } from "@/const";
import { YearDatePicker } from "@/components/Fields";
import { useStores } from "@/hooks";
import { StyledChip } from "@/apps/Modals/ModalContent/CreateVariety/styles";
import AddChip from "@/components/Chips/AddChip";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { IBranchTrial } from "@/api/interfaces/responses";

/**
 * Форма для испытаний на хп
 * @component
 */

const HpTrialForm = ({ statementId }) => {
  const formik = useFormikContext<THpFormik>();
  const { values } = formik;
  const { api, modalStore } = useStores();
  const theme = useTheme();

  const { data } = useSWR(
    formik.values.year_of_testing && statementId ? SWR_KEYS.getAmountsContextStatmentById(statementId, 'hp', formik.values.year_of_testing) : null,
    () => api.statement.getAmountsContextStatmentById(statementId, 'hp', formik.values.year_of_testing)
  );
  /**
   * Метод для изменений региона
   * @param event
   */
  const handleRegionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const id = Number(event.target.name);
    const checkedRegions =
      formik.values[TRIAL_FIELD_NAME.REGIONS];
    if (event.target.checked) {
      formik.setFieldValue(TRIAL_FIELD_NAME.REGIONS, [
        ...checkedRegions,
        id,
      ]);
    } else {
      formik.setFieldValue(
        TRIAL_FIELD_NAME.REGIONS,
        checkedRegions.filter((val) => val !== id),
      );
    }
  };

  /**
   * Метод ля изменений световых зон
   * @param event
   */

  const handleLightZoneChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const id = Number(event.target.name);
    const checkedZones =
      formik.values[TRIAL_FIELD_NAME.LIGHT_ZONES];
    if (event.target.checked) {
      formik.setFieldValue(TRIAL_FIELD_NAME.LIGHT_ZONES, [
        ...checkedZones,
        id,
      ]);
    } else {
      formik.setFieldValue(
        TRIAL_FIELD_NAME.LIGHT_ZONES,
        checkedZones.filter((val) => val !== id),
      );
    }
  };


  /**
   * Метод для измений Направления использования
   * @param e
   */

  const handleChangeCultivarUsage = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
    formik.setFieldValue(TRIAL_FIELD_NAME.GROUP, "");
  };

  /**
   * Список направлений использования
   */

  const renderCultivarUsageITem =
    values.context.cultivar_usage_types.map((el) => (
      <MenuItem key={el.id} value={el.id}>
        {el.name}
      </MenuItem>
    ));
  /**
   * Список испытаний хп в зависимости от id направления исполизования
   */
  const renderCultivarGroupITem =
    values[TRIAL_FIELD_NAME.USE_DIRECTION_ID] &&
      values.context.cultivar_groups[
      values[TRIAL_FIELD_NAME.USE_DIRECTION_ID]
      ]
      ? values.context.cultivar_groups[
        values[TRIAL_FIELD_NAME.USE_DIRECTION_ID]
      ].map((el) => (
        <MenuItem key={el.id} value={el.id}>
          {el.name}
        </MenuItem>
      ))
      : [];

  /**
   * Список регионов
   */
  const renderRegionItems = values.context.regions.map(
    (el: any) => {
      return (
        <Box key={el.id}>
          {el?.is_deleted ? null : (
            <ListItem>
              <FormControlLabel
                name={String(el.id)}
                checked={values[
                  TRIAL_FIELD_NAME.REGIONS
                ].includes(el.id)}
                control={<Checkbox color="secondary" />}
                label={
                  <Typography
                    fontSize={14}
                  >{`${el.number} - ${el.name}`}</Typography>
                }
              />
            </ListItem>
          )}
        </Box>
      );
    },
  );

  /**
   * Список световых зон
   */
  const renderLightZoneItems =
    values.context.light_zones.map((el) => {
      return (
        <Box key={el.id}>
          <FormControlLabel
            name={String(el.id)}
            checked={values[
              TRIAL_FIELD_NAME.LIGHT_ZONES
            ].includes(el.id)}
            control={<Checkbox color="secondary" />}
            label={
              <Typography fontSize={14}>
                {el.rome_number}
              </Typography>
            }
          />
        </Box>
      );
    });

  const showExpertField = formik.values.type_hp === HP_TYPE.ACCORDING_EXPERT_ASSESSMENT;

  const handleTypeHpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    void formik.setFieldValue(TRIAL_FIELD_NAME.TYPE_HP, e.target.value)
    void formik.setFieldValue(TRIAL_FIELD_NAME.INSPECTION_ADDRESS, "")
    void formik.setFieldValue(TRIAL_FIELD_NAME.INSPECTIONS_NUMBER, "")
    void formik.setFieldValue(TRIAL_FIELD_NAME.BRANCHES, [])
  }

  const applicant = data && (data?.subject === 'individual' ? 'ФЛ' : data?.subject === 'legal_entity' ? 'ЮЛ' : '');

  useEffect(() => {
    formik.setFieldError('errors', undefined)
  }, [formik.values])

  const handleSelectBranches = (value: IBranchTrial) => {
    modalStore.close()
    formik.setFieldValue(TRIAL_FIELD_NAME.BRANCHES, value);
  };

  const handleAddBranches = () => {
    modalStore.open(VARIANT_MODAL.SELECT_BRANCHES,
      {
        initialValue: formik.values.branches ?? [],
        onSubmit: handleSelectBranches,
        saveButtonLabel: "Добавить",
      }
    )
  }

  const handleDeleteChipBranch = (branchId) => {
    const updatedBranches = (formik.values[TRIAL_FIELD_NAME.BRANCHES] ?? []).filter((branch) => branch.id !== branchId);
    formik.setFieldValue(TRIAL_FIELD_NAME.BRANCHES, updatedBranches);
  };

  return (
    <StyledRoot>
      <Box sx={{ maxWidth: 600, paddingRight: "40px" }}>
        <Form noValidate>
          <Stack direction="column" spacing="20px">
            {formik.values.year_of_testing && data !== undefined &&
              <Box sx={{ border: `1px solid ${theme.palette.blackAndWhite.stroke}`, padding: '16px 17px', borderRadius: "6px", display: 'flex', gap: '10px' }}>
                {data !== undefined && data?.result <= 0 && <WarningIcon />}
                <Typography fontSize={'14px'} textAlign={'center'}>
                  {typeof data?.result === 'number' ? (
                    <>
                      Осталось{' '}
                      <Box
                        component="span"
                        sx={{
                          color: data?.result <= 0 ? theme.palette.error.main : theme.palette.primary.main,
                          fontWeight: 700,
                        }}
                      >
                        {data.result}
                      </Box>
                      {' '}бюджетных сортоопытов на ХП ({applicant})
                    </>
                  ) : (
                    <Box
                      component="span"
                      sx={{
                        color: theme.palette.error.main,
                        fontWeight: 700,
                      }}
                    >
                      {data?.result} бюджетных сортоопытов на ХП ({applicant})
                    </Box>
                  )}
                </Typography>
              </Box>
            }
            <Typography variant="h3">
              Данные испытания
            </Typography>
            <YearDatePicker
              name={TRIAL_FIELD_NAME.YEAR_OF_TESTING}
              label="Год испытания"
              required
            />
            <FastField name={TRIAL_FIELD_NAME.TYPE_HP}>
              {({ field, meta }) => (
                <FormControl required>
                  <FormLabel>Подвид испытаний</FormLabel>
                  <RadioGroup {...field}
                    onChange={handleTypeHpChange}>
                    <Stack direction="column" spacing="5px">
                      {HP_TYPE_VALUES.map((el) => {
                        return (
                          <FormControlLabel
                            key={el.value}
                            value={el.value}
                            control={
                              <Radio color="secondary" />
                            }
                            label={el.label}
                            sx={{width: 'fit-content'}}
                          />
                        );
                      })}
                    </Stack>
                  </RadioGroup>
                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </FastField>
            <FastField name={TRIAL_FIELD_NAME.PAYMENT_TYPE}>
              {({ field }) => (
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Тип оплаты
                  </FormLabel>
                  <RadioGroup {...field}>
                    <Stack direction="row">
                      <FormControlLabel
                        value="budget"
                        control={<Radio color="secondary" />}
                        label="Бюджет"
                      />
                      <FormControlLabel
                        value="paid"
                        control={<Radio color="secondary" />}
                        label="Платные"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )}
            </FastField>
            {
              showExpertField && (
                <>
                  <Field
                    name={TRIAL_FIELD_NAME.BRANCHES}
                  >
                    {({ field, meta }) => {
                      return (
                        <FormControl>
                          <FormLabel>
                            Филиалы
                          </FormLabel>
                          <Stack
                            direction={"row"}
                            flexWrap={"wrap"}
                            alignItems={"center"}
                            gap={1}
                          >
                            {field.value
                              ? field?.value?.map(
                                (item) => (
                                  <StyledChip
                                    key={item?.id}
                                    size="small"
                                    variant="outlined"
                                    label={item?.name}
                                    onDelete={() => handleDeleteChipBranch(item?.id)}
                                  />
                                ),
                              )
                              : null}

                            <AddChip
                              onClick={handleAddBranches}
                            />
                          </Stack>
                          <FormHelperText
                            error={!!meta.error}
                          >
                            {meta.error}
                          </FormHelperText>
                        </FormControl>
                      );
                    }}
                  </Field>
                  <AddressAutocomplete
                    name={TRIAL_FIELD_NAME.INSPECTION_ADDRESS}
                    label="Адрес выезда для осмотра"
                    required />
                  <FastRegexTextField
                    name={TRIAL_FIELD_NAME.INSPECTIONS_NUMBER}
                    regexType="number" label="Кол-во осмотров"
                    required />
                </>
              )
            }
            <Field name={TRIAL_FIELD_NAME.USE_DIRECTION_ID}>
              {({ field, meta }) => (
                <FormControl
                  size="small"
                  variant="outlined"
                  required
                >
                  <InputLabel id="select-label">
                    Направление использования
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChangeCultivarUsage}
                    label="Направление использования"
                    fullWidth
                  >
                    {renderCultivarUsageITem}
                  </Select>
                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </Field>
            <Field name={TRIAL_FIELD_NAME.GROUP}>
              {({ field, meta }) => (
                <FormControl
                  size="small"
                  variant="outlined"
                  required
                >
                  <InputLabel id="select-label">
                    Группа ХП
                  </InputLabel>
                  <Select
                    {...field}
                    label="Группа ХП"
                    fullWidth
                    disabled={
                      !values[
                      TRIAL_FIELD_NAME.USE_DIRECTION_ID
                      ]
                    }
                  >
                    {renderCultivarGroupITem}
                  </Select>
                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </Field>
            <FastField name={TRIAL_FIELD_NAME.REGIONS}>
              {({ meta }) => (
                <FormControl required>
                  <FormLabel>Регионы допуска</FormLabel>
                  <FormGroup onChange={handleRegionChange}>
                    <StyledList>
                      {renderRegionItems}
                    </StyledList>
                  </FormGroup>
                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </FastField>
            <FastField name={TRIAL_FIELD_NAME.LIGHT_ZONES}>
              {({ meta }) => (
                <FormControl>
                  <FormLabel>Световые зоны</FormLabel>
                  <FormGroup
                    onChange={handleLightZoneChange}
                  >
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      spacing={1}
                    >
                      {renderLightZoneItems}
                    </Stack>
                  </FormGroup>
                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </FastField>
          </Stack>
          {formik.errors?.errors &&
            <Alert
              severity="error" sx={{ marginTop: '10px' }}>
              {formik.errors?.errors}
            </Alert>
          }
          <Box mt={5}>
            <Button
              startIcon={<CheckIcon />}
              disabled={formik.isSubmitting}
              variant="contained"
              type="submit"
            >
              Готово
            </Button>
          </Box>
        </Form>
      </Box>
    </StyledRoot>
  );
};

export default HpTrialForm;
