import theme from "@/theme";
import { TOffbudgetStatus } from "@/api/types";
import { IValue } from "@/interfaces";

export const OFFBUDGET_KEYS = {
  year: "year",
  name: "name",
  branch__name: "branch__name",
  status: "status",
  editing: "editing",
} as const;

export const OFFBUDGET_HEADER_LABELS = {
  year: "Год плана",
  name: "Название",
  branch: "Филиал",
  status: "Статус",
};

export const OFFBUDGET_STATUS: {
  [key in TOffbudgetStatus]: string;
} = {
  draft: "Черновик",
  approved: "Утверждено",
  agreed: "Согласовано",
  rejected: "Отклонено",
  revision: "Доработка",
};

export const OFFBUDGET_STATUS_COLOR: {
  [key in TOffbudgetStatus]: string;
} = {
  draft: theme.palette.blackAndWhite.gray,
  approved: theme.palette.green.main,
  agreed: theme.palette.blue.main,
  rejected: theme.palette.red.main,
  revision: theme.palette.yellow.main,
};

export const OFFBUDGET_STATUS_ICON: {
  [key in TOffbudgetStatus]: string;
} = {
  draft: "draft",
  approved: "approved",
  agreed: "agreed",
  rejected: "rejected",
  revision: "revision",
};

export const EMPTY_LIST_OFFBUDGET_TEXT =
  "Еще не создано ни одного плана";
export const EMPTY_LIST_OFFBUDGET_TEXT_WITH_FILTERS =
  "По указанным параметрам планы не найдены";
export const ADD_OFFBUDGET_TEXT = "Добавить план";

export const EMPTY_LIST_OFFBUDGET_FACTS_TEXT =
  "Еще не создано ни одного факта";
export const EMPTY_LIST_OFFBUDGET_FACTS_TEXT_WITH_FILTERS =
  "По указанным параметрам факты не найдены";
export const ADD_OFFBUDGET_FACTS_TEXT = "Добавить факт";

export const OFFBUDGET_STATUS_INPUT: IValue[] = [
  {
    key: "draft",
    label: "Черновик",
  },
  {
    key: "approved",
    label: "Утверждено",
  },
  {
    key: "agreed",
    label: "Согласовано",
  },
  {
    key: "revision",
    label: "Доработка",
  },
  {
    key: "rejected",
    label: "Отклонено",
  },
];

export const OFFBUDGET_FACT_STATUS_INPUT: IValue[] = [
  {
    key: "draft",
    label: "Черновик",
  },
  {
    key: "agreed",
    label: "Согласовано",
  },
];

export const BRANCH_KEY = "getBranchInfinite";

export const OFFBUDGET_SIDEBAR_TABS_NAMES = {
  plan: "plan",
  production: "production",
  costs: "costs",
  investment: "investment",
  finance: "finance",
  efficiency: "efficiency",
};

export const OFFBUDGET_STATUS_NAMES: {
  [key in TOffbudgetStatus]: string;
} = {
  draft: "draft",
  approved: "approved",
  agreed: "agreed",
  rejected: "rejected",
  revision: "revision",
} as const;

export const OFFBUDGET_TYPES = {
  plan: "plan",
  fact: "fact",
} as const;
