import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";
import { EditIcon, DeleteIcon, } from "@/components/icons";
import { buttonDotsStyle } from "../../style";
import {
  StyledMenuItem
} from "@/components/StyledMenuItem";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import { useQuery, useStores } from "@/hooks";
import {
  CELL_TYPES
} from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { resInterface } from "@/api/interfaces";
import { SWR_KEYS } from "@/const";
import { useNavigate } from "react-router";
import { ROUTES } from "@/apps/AppRouter/const";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

export const EDITING_CELL = {
  editing: "Редактировать",
  delete: "Удалить",
};

export const NameCell = (
  _: string,
  cell: resInterface.ITechMapPlan,
): JSX.Element => {
  const { name } = cell;
  return (
    <Box>
      <Typography fontSize={14}>
        {name ?? "-"}
      </Typography>
    </Box>
  );
};

export const CultivarCell = (
  _: string,
  cell: resInterface.ITechMapPlan,
): JSX.Element => {
  return (
    <Box>
      <Typography fontSize={14}>
        {cell?.cultivar?.name ?? "-"}
      </Typography>
    </Box>
  );
};

export const PlotCell = (
  _: string,
  cell: resInterface.ITechMapPlan,
): JSX.Element => {
  return (
    <Box>
      <Typography fontSize={14}>
        {cell.plot?.name ?? "-"}
      </Typography>
    </Box>
  );
};

/**
 * @component EditingCell
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCell = (
  _: string,
  cell: resInterface.ITechMapPlan,
  itemsInPage: any[],
): JSX.Element => {
  const { api, modalStore, swrStore, userStore } = useStores();
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: "true" },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickEdit = (e) => {
    navigate(`/${ROUTES.addTechMap}/${cell.id}/${ROUTES.addTechMapForm}`)
    handleClose(e)
  };

  const mutateList =
    swrStore.mutators[
      SWR_KEYS.getTechMapPlanList()
      ];

  const deleteWithCheckingLast = () => {
    // если один эл-т на странице перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateList && mutateList();
    }
  };

  const handleDelete = (resolve, reject) => {
    api.agriculturalLabour
      .deleteTechMapPlanById(cell.id)
      .then(() => {
        deleteWithCheckingLast();
        resolve();
      })
      .catch(reject);
    setAnchorEl(null);
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.handbook,
      id: cell.id,
      handleDelete: handleDelete,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(
      VARIANT_MODAL.OK_OR_NOT_DELETE,
      modalProp,
    );
  };

  const canEditTechMap = userStore.isPermission(
    PERMISSIONS.agricultural_labourTechMapPlan,
    PERMISSION_CRUD.change
  )
  const canDeleteTechMap = userStore.isPermission(
    PERMISSIONS.agricultural_labourTechMapPlan,
    PERMISSION_CRUD.delete
  )

  if (!canEditTechMap && !canDeleteTechMap) {
    return <></>
  }

  return (
    <Box>
      <IconButton
        sx={buttonDotsStyle()}
        onClick={handleToggle}
      >
        <MoreVertIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {canEditTechMap && 
        <StyledMenuItem onClick={handleClickEdit}>
          <EditIcon/>
          {EDITING_CELL.editing}
        </StyledMenuItem>
        }
        {canEditTechMap && canDeleteTechMap && 
        <Divider/>
        }
        {canDeleteTechMap && 
        <StyledMenuItem onClick={handleClickDelete}>
          <DeleteIcon color={"error"}/>
          <Typography color="error">
            {EDITING_CELL.delete}
          </Typography>
        </StyledMenuItem>
        }
      </Menu>
    </Box>
  );
};
