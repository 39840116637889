import theme from "@/theme";
import { TPlanningTrialType, } from "@/api/types";
import {
  flower,
  hub,
  labs,
  microbiology,
} from "@/assets/common";
import { IValue } from "@/interfaces";

export const CULTIVAR_FIELD_KEYS = {
  typeTrial: "type_trial",
  plotName: "field__location__plot__name",
  year: "field__planting_year",
  cultivarName: "cultivar__name",
  fieldName: "field__name",
  editing: "editing",
} as const;

export const CULTIVAR_FIELD_LABELS = {
  year: "Год посева",
  plot: "ГСУ",
  cultivar: "Культура",
  fieldName: "Название поля",
  typeTrial: "Вид испытания",
};

export const CULTIVAR_FIELD_TYPES_ICON: {
  [key in TPlanningTrialType]: string;
} = {
  hp: flower,
  oos: hub,
  phyto: microbiology,
  lab: labs,
};

export const CULTIVAR_FIELD_TRIALS_TYPES: {
  [key in TPlanningTrialType]: string;
} = {
  hp: "Хоз. полезность",
  oos: "ООС",
  phyto: "Фитопатология",
  lab: "В лаборатории",
};

export const CULTIVAR_FIELD_TYPES_COLOR: {
  [key in TPlanningTrialType]: string;
} = {
  hp: theme.palette.green.main,
  oos: theme.palette.another.orange,
  phyto: theme.palette.red.main,
  lab: theme.palette.another.violet,
};

export const EMPTY_LIST_PLANING_TEXT =
  "Еще не создано ни одного плана";
export const EMPTY_LIST_PLANING_TEXT_WITH_FILTERS =
  "По указанным параметрам планы не найдены";
export const ADD_PLANING_TEXT = "Добавить план";

export const CULTIVAR_FIELD_TYPE_INPUT: IValue[] = [
  {
    key: "hp",
    label: "Хоз. полезность",
  },
  {
    key: "oos",
    label: "ООС",
  },
  {
    key: "phyto",
    label: "Фитопатология",
  },
];

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};
