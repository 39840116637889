import { IColumn } from "@/apps/Table/interfaces";
import { TParameterTypeKeys } from "../interfaces";
import { PARAMETERS_KEYS, PARAMETERS_LABELS } from "../const";
import { PointerTextCell } from "../cells";
import { cellButtonsMaterialType, cellPointText, headerCellStyle } from "../style";
import { EditingCellsIndicator, UnitCell } from "../templates/Parameters/cells";

export const COLUMN_EDITING = {
    value: "editing",
    label: "",
  };
  
  export const EDITING_CELL = {
    editing: "Редактировать",
    duplicate: "Копировать",
    delete: "Удалить",
  };

export const parametersColumns: IColumn<TParameterTypeKeys>[] =
  [
    {
      label: PARAMETERS_LABELS.name,
      key: PARAMETERS_KEYS.name,
      contentHeader: PARAMETERS_LABELS.name,
      bodyCellProps: {
        render: PointerTextCell,
        styles: cellPointText,
      },
      headerCellProps: {
        styles: headerCellStyle,
      },
    },
    {
      label: PARAMETERS_LABELS.unit,
      key: PARAMETERS_KEYS.unit,
      contentHeader: PARAMETERS_LABELS.unit,
      bodyCellProps: {
        render: UnitCell,
        styles: cellPointText,
      },
      headerCellProps: {
        styles: headerCellStyle,
      },
    },
    {
      label: PARAMETERS_LABELS.min_parameter_value,
      key: PARAMETERS_KEYS.min_parameter_value,
      contentHeader: PARAMETERS_LABELS.min_parameter_value,
      bodyCellProps: {
        render: PointerTextCell,
        styles: cellPointText,
      },
      headerCellProps: {
        styles: headerCellStyle,
      },
    },
    {
      label: PARAMETERS_LABELS.max_parameter_value,
      key: PARAMETERS_KEYS.max_parameter_value,
      contentHeader: PARAMETERS_LABELS.max_parameter_value,
      bodyCellProps: {
        render: PointerTextCell,
        styles: cellPointText,
      },
      headerCellProps: {
        styles: headerCellStyle,
      },
    },
    {
      key: "editing",
      label: PARAMETERS_LABELS.editing,
      contentHeader: COLUMN_EDITING.label,
      bodyCellProps: {
        render: EditingCellsIndicator,
        styles: cellButtonsMaterialType,
      },
    },
  ];
