import React from "react";
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack
} from "@mui/material";
import { FastField, Field, useFormikContext } from "formik";
import {
  BENEFIT_TYPES,
  FIELDS_NAME,
  STEP_LABELS
} from "@/pages/AddStatement/const";
import FormAccordion from "@/components/Accordions/FormAccordion";
import { ChipWrap } from "@/pages/AddStatement/components/SubjectsFormStep/styles";
import { DateTimePicker } from "@mui/x-date-pickers";
import AddChip from "@/components/Chips/AddChip";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { useStores } from "@/hooks";
import { KeyedMutator } from "swr";
import { IStatement } from "@/api/interfaces/responses";
import { useAddStatement } from "@/pages/AddStatement/hooks/useAddStatement";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { STATEMENT_TYPE } from "@/const";
import dayjs from "dayjs";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";

interface IProps {
  mutate: KeyedMutator<IStatement>;
}

const DataFormStep: React.FC<IProps> = ({ mutate }) => {
  const { modalStore, userStore } = useStores();
  const { checkDisabledByName, saveStatementValue } = useAddStatement();
  const { values, getFieldMeta, setFieldValue, handleBlur } =
    useFormikContext<IStatement>();

  const isPatent = values.type === STATEMENT_TYPE.PATENT_STATEMENT;

  const handleOpenCultivarModal = () => {
    modalStore.open(VARIANT_MODAL.ADD_CULTIVAR_TO_STATEMENT, {
      onClose: mutate,
      initialValue: values.cultivar
    });
  };

  const handleOpenEditModal = () => {
    modalStore.open(VARIANT_MODAL.EDIT_CULTIVAR_VARIETY_STATEMENT, {
      theme: THEME_MODAL.W_555,
      initialValues: {
        variety: values.variety,
        cultivar: values.cultivar
      },
      onClose: mutate
    });
  };

  const handleOpenVarietyModal = () => {
    modalStore.open(VARIANT_MODAL.VARIETY, {
      onClose: mutate,
      initialValue: values.variety,
      currentCultivar: values.cultivar
    });
  };
  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleBlur(e);
    const { name, value } = e.target;
    if (!getFieldMeta(name).error) {
      saveStatementValue(name, { [name]: value });
    }
  };
  const handleChangeResident = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === "true";
    setFieldValue(FIELDS_NAME.IS_RESIDENT, value);
    if (value === false) {
      setFieldValue(FIELDS_NAME.BENEFIT, BENEFIT_TYPES.WITHOUT_BENEFIT);
    }
  };

  const handleBlurResident = (e: React.FocusEvent<HTMLInputElement>) => {
    handleBlur(e);
    const { name } = e.target;
    const value = e.target.value === "true";
    const payload = {
      [name]: value,
      [FIELDS_NAME.BENEFIT]:
        value === false ? BENEFIT_TYPES.WITHOUT_BENEFIT : undefined
    };
    saveStatementValue(name, payload);
  };

  const handleChangeDate = (e) => {
    setFieldValue(FIELDS_NAME.REGISTRATION_DATE, e);

    if (!getFieldMeta(FIELDS_NAME.REGISTRATION_DATE).error) {
      saveStatementValue(FIELDS_NAME.REGISTRATION_DATE, {
        [FIELDS_NAME.REGISTRATION_DATE]: dayjs(e).local().toISOString()
      });
    }
  };

  const isCultivar = userStore.isPermission(
    PERMISSIONS.regulatory_infoСultivar,
    PERMISSION_CRUD.view
  );

  const isSort = userStore.isPermission(
    PERMISSIONS.regulatory_infoVariety,
    PERMISSION_CRUD.view
  );

  return (
    <Box>
      <Stack direction="column" spacing="20px">
        <Typography variant="h3">{STEP_LABELS.DATA}</Typography>
        <FastField name={FIELDS_NAME.PAYMENT_TYPE}>
          {({ field }) => (
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Тип оплаты
              </FormLabel>
              <RadioGroup {...field} onBlur={onBlur}>
                <Stack direction="row">
                  <FormControlLabel
                    value="budget"
                    control={<Radio color="secondary" />}
                    label="Бюджет"
                  />
                  <FormControlLabel
                    value="paid"
                    control={<Radio color="secondary" />}
                    label="Платные"
                  />
                </Stack>
              </RadioGroup>
            </FormControl>
          )}
        </FastField>
        {isPatent && (
          <FastField name={FIELDS_NAME.IS_RESIDENT}>
            {({ field }) => (
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Тип заявителя
                </FormLabel>
                <RadioGroup
                  {...field}
                  onChange={handleChangeResident}
                  onBlur={handleBlurResident}
                >
                  <Stack direction="row">
                    <FormControlLabel
                      value={true}
                      control={<Radio color="secondary" />}
                      label="Резидент"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio color="secondary" />}
                      label="Нерезидент"
                    />
                  </Stack>
                </RadioGroup>
              </FormControl>
            )}
          </FastField>
        )}
        {isPatent && (
          <Field name={FIELDS_NAME.BENEFIT}>
            {({ field }) => (
              <FormControl size="small" variant="outlined" fullWidth>
                <InputLabel id="select-label">Льгота</InputLabel>
                <Select
                  {...field}
                  label="Льгота"
                  onBlur={onBlur}
                  disabled={values.is_resident === false}
                >
                  <MenuItem value={BENEFIT_TYPES.WITHOUT_BENEFIT}>
                    Без льгот
                  </MenuItem>

                  <MenuItem value={BENEFIT_TYPES.BENEFIT_20}>
                    Льгота 20%
                  </MenuItem>

                  <MenuItem value={BENEFIT_TYPES.BENEFIT_50}>
                    Льгота 50%
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </Field>
        )}
        <Field name={FIELDS_NAME.CULTIVAR}>
          {({ field }) => (
            <FormAccordion
              label="Культура"
              defaultExpanded
              noExpandable={
                !field.value ||
                (Array.isArray(field.value) && field.value.length === 0)
              }
            >
              <ChipWrap>
                {field.value && (
                  <Chip
                    key={field.name}
                    size="small"
                    variant="outlined"
                    label={field.value.name}
                    onDelete={!isCultivar ? undefined : handleOpenEditModal}
                    disabled={checkDisabledByName(field.name)}
                  />
                )}
                {!field.value && (
                  <AddChip
                    disabled={!isCultivar}
                    onClick={handleOpenCultivarModal}
                  />
                )}
              </ChipWrap>
            </FormAccordion>
          )}
        </Field>
        <Field name={FIELDS_NAME.VARIETY}>
          {({ field }) => (
            <FormAccordion
              label="Сорт"
              defaultExpanded
              noExpandable={
                !field.value ||
                (Array.isArray(field.value) && field.value.length === 0)
              }
            >
              <ChipWrap>
                {field.value && (
                  <Chip
                    key={field.name}
                    size="small"
                    variant="outlined"
                    label={field.value.name}
                    onDelete={!isSort ? undefined : handleOpenEditModal}
                    disabled={checkDisabledByName(field.name)}
                  />
                )}
                {!field.value && (
                  <AddChip
                    disabled={!values.cultivar || !isSort}
                    onClick={handleOpenVarietyModal}
                  />
                )}
              </ChipWrap>
            </FormAccordion>
          )}
        </Field>
        <Stack pt={"20px"}>
          <Field name={FIELDS_NAME.REGISTRATION_DATE}>
            {({ field, meta }) => (
              <DateTimePicker
                name={field.name}
                onChange={handleChangeDate}
                value={field.value}
                label="Дата регистрации"
                format="DD.MM.YYYY HH:mm"
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: false,
                    required: true,
                    error: meta.touched && !!meta.error,
                    helperText: meta.touched && meta.error,
                    onChange: handleChangeDate
                  }
                }}
              />
            )}
          </Field>
        </Stack>
      </Stack>
    </Box>
  );
};

export default DataFormStep;
