import yup from "@/utils/yup";

export const FIELDS_NAME = {
  INDICATOR: "indicator",
  INDICATOR_VALUE: "indicator_value",
} as const;

interface Indicator {
  id: number,
  name: string,
  min_parameter_value: string,
  max_parameter_value: string,
  unit: any
}
export interface IFormik {
  [FIELDS_NAME.INDICATOR]: Indicator | null,
  [FIELDS_NAME.INDICATOR_VALUE]: number | null
}


export const validationSchema = yup.object().shape({
  [FIELDS_NAME.INDICATOR]: yup.mixed().required('Параметр обязателен'),
  [FIELDS_NAME.INDICATOR_VALUE]: yup.number().test(
    'indicator-value',
    'Значение должно соответствовать выбранному параметру',
    function(value) {
      const { parent } = this;
      
      const indicator = parent[FIELDS_NAME.INDICATOR];
      
      if (!indicator || !value) {
        return false; 
      }

      if ((indicator.min_parameter_value !== undefined && value < indicator.min_parameter_value) ||
      (indicator.max_parameter_value !== undefined && value > indicator.max_parameter_value)) {
        return this.createError({
          message: `Значение должно быть между ${indicator.min_parameter_value} и ${indicator.max_parameter_value}`
        });
      }
      if ((indicator.min_parameter_value === null) || (indicator.max_parameter_value === null)) {
        return true
      }
      
      return true;
    }
  ),
})

export const initialValues = {
  [FIELDS_NAME.INDICATOR]: null,
  [FIELDS_NAME.INDICATOR_VALUE]: null,
};

export const serializeValuesToForm = (values) => {
  return {
    [FIELDS_NAME.INDICATOR_VALUE]: values?.indicator_value || null,
    [FIELDS_NAME.INDICATOR]: values?.indicator || null,
  };
};


