import { IIndicator, IMethList, IVariantMeth } from "@/api/interfaces/responses";
import yup from "@/utils/yup";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    varietyId: number,
    indicatorType: string,
  };
  onSubmit?: () => void;
}

export enum FIELD_NAME {
  VARIETY = "variety",
  INDICATOR_TYPE = "indicator_type",
  METHODOLOGY = "methodology",
  INDICATOR_METHODOLOGY = "indicator_methodology",
  VARIANT_INDICATOR_METHODOLOGY = "variant_indicator_methodology",
}

export interface IFormik {
  [FIELD_NAME.VARIETY]: number,
  [FIELD_NAME.INDICATOR_TYPE]: string,
  [FIELD_NAME.METHODOLOGY]?: IMethList | null,
  [FIELD_NAME.INDICATOR_METHODOLOGY]?: IIndicator | null,
  [FIELD_NAME.VARIANT_INDICATOR_METHODOLOGY]?: IVariantMeth | null,
}

export const validationSchema = yup.object().shape({
  [FIELD_NAME.VARIANT_INDICATOR_METHODOLOGY]: yup.mixed().required(),
});

export const INDICATOR_TYPE = {
  morphological: "morphological",
  economically_valuable: "economically_valuable",
}
