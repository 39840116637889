import React, { useEffect, useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Box,
  Divider,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";
import {
  EditIcon,
  DeleteIcon,
  HpIcon,
  OSIcon,
} from "@/components/icons";
import { buttonDotsStyle } from "../../style";
import { StyledMenuItem } from "@/components/StyledMenuItem";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import { useQuery, useStores } from "@/hooks";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { SWR_KEYS } from "@/const";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import theme from "@/theme";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  delete: "Удалить",
};
/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */

export const IndicatorTypeCell = (
  _: string,
  cell: any,
): JSX.Element => {
  const { type_trial: type } = cell;
  const decoratedType = TYPES_ARRAY.find(
    (item) => item.name === type,
  );
  return (
    <Box>
      <Typography
        sx={{
          display: "inline-flex",
          alignItems: "center",
          gap: "5px",
        }}
        fontSize={14}
        color={decoratedType?.textColor}
      >
        {decoratedType?.icon}
        {decoratedType?.label}
      </Typography>
    </Box>
  );
};

export const TYPE = {
  HP: "hp",
  OOS: "oos",
};

export const TYPE_LABEL = {
  HP: "Хоз. полезность",
  OOS: "ООС",
} as const;

export const TYPES_ARRAY = [
  {
    name: TYPE.HP,
    label: TYPE_LABEL.HP,
    icon: <HpIcon sx={{ width: "20px", height: "20px" }} />,
    textColor: theme.palette.green.main,
  },
  {
    name: TYPE.OOS,
    label: TYPE_LABEL.OOS,
    icon: <OSIcon sx={{ width: "20px", height: "20px" }} />,
    textColor: theme.palette.another.orange,
  },
] as const;

export const EditingCellsIndicator = (
  _: string,
  cell: any,
  itemsInPage: any[],
): JSX.Element => {
  const { api, modalStore, swrStore, userStore } = useStores();
  const [anchorEl, setAnchorEl] =
    useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: "true" },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickEdit = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    modalStore.open(VARIANT_MODAL.ADD_INDICATOR, {
      theme: THEME_MODAL.W_555,
      initialValues: cell,
      indicatorId: cell.id,
      isEdit: true,
    });
    setAnchorEl(null);
  };

  const mutateList =
    swrStore.mutators[SWR_KEYS.getIndicatorList()];

  const mutateWithCheckingLast = () => {
    // если один эл-т на странице перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateList && mutateList();
    }
  };

  const handleDelete = (resolve, reject) => {
    api.regulatoryInfo
      .deleteIndicatorsItem(cell.id)
      .then(() => {
        mutateWithCheckingLast();
        resolve();
      })
      .catch(reject);
    setAnchorEl(null);
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.handbook,
      id: cell.id,
      handleDelete: handleDelete,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(
      VARIANT_MODAL.OK_OR_NOT_DELETE,
      modalProp,
    );
  };

  const canEditIndicator = userStore.isPermission(
    PERMISSIONS.regulatory_infoIndicator,
    PERMISSION_CRUD.change
  )
  const canDeleteIndicator = userStore.isPermission(
    PERMISSIONS.regulatory_infoIndicator,
    PERMISSION_CRUD.delete
  )

  if (!canEditIndicator && !canDeleteIndicator) {
    return <></>
  }

  return (
    <Box>
      <IconButton
        sx={buttonDotsStyle()}
        onClick={handleToggle}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {canEditIndicator &&
        <StyledMenuItem onClick={handleClickEdit}>
          <EditIcon />
          {EDITING_CELL.editing}
        </StyledMenuItem>
        }
        {canEditIndicator && canDeleteIndicator && 
        <Divider />
        }
        {canDeleteIndicator  && 
        <StyledMenuItem onClick={handleClickDelete}>
          <DeleteIcon color={"error"} />
          <Typography color="error">
            {EDITING_CELL.delete}
          </Typography>
        </StyledMenuItem>
        }
      </Menu>
    </Box>
  );
};
