import React, { useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";
import Autocomplete, {
  SearchFilterProps,
} from "@/components/Autocomplete";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import { IOrderList } from "@/api/interfaces/requests";
import { IOrder } from "@/api/interfaces/responses";
import dayjs from "dayjs";

interface IProps
  extends Partial<SearchFilterProps<IOrder>> {
  name: string;
  nameForClear?: string;
  handleChange?: (payload: IOrder) => void;
  q?: IOrderList;
  initialSearch?: string;
  isModified?: boolean;
}

export interface IOrderValue {
  key: number | string;
  name: string;
}

/**
 * Автокомплит со списком приказов
 * @param name - имя
 * @param q - квери параметры для запроса
 * @param handleChange - калбек при выборе
 * @param initialSearch - начальное значение поиска
 * @param isModified - флаг для отображения модифицированного списка
 */

const OrderAutocomplete: React.FC<IProps> = ({
  name,
  nameForClear,
  initialSearch = "",
  handleChange,
  q = {},
  isModified = false,
  ...props
}) => {
  const swrKey = SWR_KEYS.getOrderList();
  const [search, setSearch] = useState(initialSearch);
  const formik = useFormikContext();
  const { api } = useStores();
  const getKeyRegion = (index: number) => {
    return {
      _key: swrKey,
      page: index + 1,
      search: search,
      ...q,
    };
  };
  const { data, handleScroll, mutate } = useInfiniteScroll(
    getKeyRegion,
    api.regulatoryInfo.getOrderList,
    {
      revalidateFirstPage: false,
    },
  );

  useEffect(() => {
    mutate();
  }, []);

  const onChange = (_, payload: IOrder) => {
    nameForClear && formik.setFieldValue(nameForClear, "");
    handleChange
      ? handleChange(payload)
      : formik.setFieldValue(name, payload);
  };

  const handleClear = () => {
    void formik.setFieldValue(name, null);
  };

  if (!formik) {
    throw new Error(
      "OrderAutocomplete has to be used within <Formik>",
    );
  }

  const modifiedOrders = data.map((item) => ({
    ...item,
    name: `${item.number} - ${dayjs(item.order_date).format(
      "DD.MM.YYYY",
    )}`,
  }));

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <Autocomplete<IOrder>
          label="Приказ"
          onClear={handleClear}
          {...field}
          {...props}
          onChange={onChange}
          search={setSearch}
          data={isModified ? modifiedOrders : data}
          handleScroll={handleScroll}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
        />
      )}
    </Field>
  );
};

export default OrderAutocomplete;
