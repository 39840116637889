import React from "react";
import { Field, useFormikContext } from "formik";
import {
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";

import { CultivarAutocomplete } from "@/components/Autocompletes";
import {
  IOrderCultivarForm,
  ORDER_CULTIVAR_FIELDS_NAME,
  STEP_LABELS,
} from "@/pages/AddOrderCultivar/const";
import { OrderSelect } from "@/components/Selects";

/**
 * Компонент для шага Культура
 * */

const CultivarFormStep: React.FC = () => {
  const formik = useFormikContext<IOrderCultivarForm>();
  const { setFieldValue } = formik;

  const handleChangeCultivar = (payload) => {
    setFieldValue(
      ORDER_CULTIVAR_FIELDS_NAME.CULTIVAR,
      payload,
    );
  };

  return (
    <Stack spacing={3}>
      <Typography variant="h3">
        {STEP_LABELS.CULTIVAR}
      </Typography>
      <Stack direction="column" spacing="20px">
        <OrderSelect
          name={ORDER_CULTIVAR_FIELDS_NAME.ORDER}
          required
          q={{
            order_type: "cultivar",
          }}
        />
        <CultivarAutocomplete
          required={true}
          name={ORDER_CULTIVAR_FIELDS_NAME.CULTIVAR}
          handleChange={handleChangeCultivar}
        />
        <Field
          type="checkbox"
          name={ORDER_CULTIVAR_FIELDS_NAME.SHARED_APPENDIX}
        >
          {({ field }) => (
            <FormControlLabel
              {...field}
              control={<Checkbox />}
              label="Общее приложение"
              sx={{width: 'fit-content'}}
            />
          )}
        </Field>
      </Stack>
    </Stack>
  );
};
export default CultivarFormStep;
