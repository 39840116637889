import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import { IPlanningHpList } from "@/api/interfaces/responses";
import {
  EDITING_CELL,
  PLANNING_HP_STATUS,
  PLANNING_STATUS_COLOR,
  PLANNING_STATUS_ICON,
} from "../const";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import CheckIcon from "@mui/icons-material/Check";
import {
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { buttonDotsStyle } from "@/apps/Table/style";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DeleteIcon, EditIcon } from "@/components/icons";
import dayjs from "dayjs";
import { ROUTES } from "@/apps/AppRouter/const";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { useQuery, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import qs from "qs";
import { StyledMenuItem } from "@/components/StyledMenuItem";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

/**
 * @component NumberCell
 * * компонент ячейки таблицы номера заявки
 * @param
 */

export const NumberCell = (
  _: string,
  storage: IPlanningHpList,
): JSX.Element => {
  return (
    <Box>
      <Typography variant="body2">
        {storage.number}
      </Typography>
    </Box>
  );
};

/**
 * @component CultivarCell
 * * компонент ячейки таблицы Типа Испытаний
 * @param
 */
export const CultivarCell = (
  _: string,
  storage: IPlanningHpList,
): JSX.Element => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
    >
      <Typography variant="body2">
        {storage?.cultivar}
      </Typography>
    </Stack>
  );
};

/**
 * @component YearCell
 * * компонент ячейки таблицы филиал
 * @param
 */

export const YearCell = (
  _: string,
  storage: IPlanningHpList,
): JSX.Element => {
  const formattedDate = dayjs(storage.order_date).format(
    "DD.MM.YYYY",
  );
  return (
    <Box>
      <Typography
        variant="body2"
        sx={{
          maxWidth: "300px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {formattedDate}
      </Typography>
    </Box>
  );
};

/**
 * @component ApprovalCell
 * * компонент ячейки таблицы филиал
 * @param
 */
export const ApprovalCell = (
  _: string,
  storage: IPlanningHpList,
): JSX.Element => {
  return (
    <Box>
      <Typography
        variant="body2"
        sx={{
          maxWidth: "300px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {storage.status === "approved"
          ? storage.created_at
          : "-"}
      </Typography>
    </Box>
  );
};

/**
 * @component StatusCell
 * * компонент ячейки таблицы статуса плана
 * @param
 */
export const StatusCell = (
  _: string,
  storage: IPlanningHpList,
): JSX.Element => {
  const status = storage.status;
  const statusText = status
    ? PLANNING_HP_STATUS[status]
    : "";
  const statusColors = status
    ? PLANNING_STATUS_COLOR[status]
    : "";
  return (
    <Stack
      color={statusColors}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        border: "1px solid",
        p: "2px 5px 3px 3px",
        borderRadius: 20,
        width: "fit-content",
      }}
    >
      {status === PLANNING_STATUS_ICON.draft ? (
        <TaskOutlinedIcon />
      ) : (
        <CheckIcon />
      )}
      <Typography
        color={"inherit"}
        sx={{
          fontSize: "12px",
          marginLeft: "3px",
        }}
      >
        {statusText}
      </Typography>
    </Stack>
  );
};

/**
 * @component EditingCell
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCell = (
  _,
  cell,
  itemsInPage: any[],
): JSX.Element => {
  const status = cell.status;
  const isHidden = status == "approved";
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] =
    useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { api, modalStore, swrStore, userStore } = useStores();

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();

  const mutateList =
    swrStore.mutators[SWR_KEYS.getPlanningHpTable()];

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: "true" },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);

  const mutateWithCheckingLast = () => {
    // если один эл-т на странице - перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateList && mutateList();
    }
  };

  const handleDelete = (resolve, reject) => {
    api.planning
      .deletePlanningHp(cell.id)
      .then(() => {
        mutateWithCheckingLast();
        resolve();
      })
      .catch(reject);
    setAnchorEl(null);
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.universal,
      entityName: "культуру",
      id: cell.id,
      handleDelete: handleDelete,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(
      VARIANT_MODAL.OK_OR_NOT_DELETE,
      modalProp,
    );
  };

  const handleClickEdit = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    navigate(`${cell.id}/${ROUTES.hpAdd}`);
  };

  const canChangePlan = userStore.isPermission(
    PERMISSIONS.planningPlanHP,
    PERMISSION_CRUD.change
  );

  const canDeletePlan = userStore.isPermission(
    PERMISSIONS.planningPlanHP,
    PERMISSION_CRUD.delete
  );

  if (!canChangePlan && !canDeletePlan) return <></>;

  return (
    <>
      {isHidden ? null : (
        <Box>
          <IconButton
            sx={buttonDotsStyle()}
            onClick={handleToggle}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {canChangePlan &&
              <>
                <StyledMenuItem onClick={handleClickEdit}>
                  <EditIcon />
                  {EDITING_CELL.editing}
                </StyledMenuItem>
                <Divider />
              </>
            }

            {canDeletePlan &&
              <StyledMenuItem onClick={handleClickDelete}>
                <DeleteIcon color="error" />
                <Typography color="error">
                  {" "}
                  {EDITING_CELL.delete}
                </Typography>
              </StyledMenuItem>
            }
          </Menu>
        </Box>
      )}
    </>
  );
};
