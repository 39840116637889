import React from "react";
import { Form, useFormikContext } from "formik";
import { Stack } from "@mui/system";
import { Box, Button, Divider,  } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { FIELDS_NAME, IFormik } from "../const";
import {
  IIndicatorMethodologyCreateParams
} from "@/api/interfaces/requests";
import FastRegexTextField from "@/components/FastRegexTextField";
import IndicatorAutocomplete from "@/components/Autocompletes/Indicator";

interface IProps {
  handleClose: () => void;
  q?: IIndicatorMethodologyCreateParams,
}

const SelectParameterForm: React.FC<IProps> = ({
  handleClose,
  q,
}) => {
  const formik = useFormikContext<IFormik>();
  return (
    <Form noValidate>
      <Stack p={3} spacing={2.5}>
        <IndicatorAutocomplete
          label="Параметр"
          name={FIELDS_NAME.INDICATOR}
          q={q} required />
        <FastRegexTextField name={FIELDS_NAME.INDICATOR_VALUE} label='Значение параметра' required />
      </Stack>
      <Divider />
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          disabled={formik.isSubmitting}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default SelectParameterForm;
