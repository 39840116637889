import { PERMISSIONS } from "@/premissions";

export const MODULES = {
  addres: {
    id: 1,
    title: "Адреса",
    module: "address",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  posts: {
    id: 6,
    title: "Должности",
    module: "posts",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  units: {
    id: 10,
    title: "Единицы измерения",
    module: "units",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  disease: {
    id: 9,
    title: "Заболевания культур",
    module: "disease",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  remark: {
    id: 13,
    title: "Замечания",
    module: "remark",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  quantity: {
    id: 8,
    title: "Количество в разнарядках",
    module: "quantity",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  cultivars: {
    id: 4,
    title: "Культуры",
    module: "cultivars",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  methodology: {
    id: 20,
    title: "Методики",
    module: "methodology",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  cultivar_usage_type: {
    id: 27,
    title: "Направления использования",
    module: "cultivar_usage_type",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  organizations: {
    id: 15,
    title: "Организации",
    module: "organizations",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  departments: {
    id: 26,
    title: "Отделы",
    module: "departments",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  duty: {
    id: 21,
    title: "Пошлины",
    module: "duty",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  indicators: {
    id: 18,
    title: "Признаки",
    module: "indicator",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  order: {
    id: 16,
    title: "Приказы",
    module: "order",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  regions: {
    id: 3,
    title: "Регионы",
    module: "regions",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  districts: {
    id: 24,
    title: "Регионы РФ",
    module: "districts",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  reproductions: {
    id: 17,
    title: "Репродукции",
    module: "reproduction",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  light_zone: {
    id: 2,
    title: "Световые зоны",
    module: "light_zone",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  varieties: {
    id: 22,
    title: "Сорта",
    module: "varieties",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  employee: {
    id: 7,
    title: "Сотрудники",
    module: "employee",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  countries: {
    id: 5,
    title: "Страны",
    module: "countries",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  subject: {
    id: 14,
    title: "Субъекты",
    module: "subject",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  tеchnologicalMaps: {
    id: 23,
    title: "Технологические карты",
    module: "tеchnologicalMaps",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  material_type: {
    id: 12,
    title: "Типы материала",
    module: "material_type",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  vegetation_stages: {
    id: 25,
    title: "Фазы вегетации",
    module: "vegetation_stages",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  agricultural_machinery: {
    id: 28,
    title: "Сельхозтехника",
    module: "agricultural_machinery",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  branches: {
    id: 19,
    title: "Филиалы",
    module: "branches",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  vegetation_causes: {
    id: 29,
    title: "Причины",
    module: "vegetation_causes",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  cultivar_group: {
    id: 30,
    title: "Группы культуры",
    module: "cultivar_group",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  plot: {
    id: 35,
    title: "ГСУ",
    module: "plot",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  budgetary_trials_calc: {
    id: 31,
    title: "Счетчик бюджетных испытаний",
    module: "budgetary_trials_calc",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  expense: {
    id: 33,
    title: "Статьи затрат",
    module: "expense",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  chemistry: {
    id: 34,
    title: "Химия",
    module: "chemistry",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  rate: {
    id: 36,
    title: "Показатели",
    module: "rate",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  agreement: {
    id: 38,
    title: "Договоры",
    module: "agreement",
    canView: true,
    canDelete: true,
    canEdit: true,
  },
  parameters: {
    id: 39,
    title: "Параметры",
    module: "parameters",
    canView: true,
    canDelete: true,
    canEdit: true,
  },

};

export const PERMISSIONS_MAPPING = {
  agreement: PERMISSIONS.registrationAgreement,
  countries: PERMISSIONS.regulatory_infoCountry,
  cultivars: PERMISSIONS.regulatory_infoСultivar,
  disease: PERMISSIONS.regulatory_infoDisease,
  regions: PERMISSIONS.regulatory_infoRegion,
  posts: PERMISSIONS.regulatory_infoPost,
  light_zone: PERMISSIONS.regulatory_infoLightZone,
  remark: PERMISSIONS.registrationRemark,
  employee: PERMISSIONS.regulatory_infoEmployee,
  address: PERMISSIONS.regulatory_infoAddress,
  plot: PERMISSIONS.regulatory_infoPlot,
  agricultural_machinery: PERMISSIONS.regulatory_infoAgriculturalmachinery,
  rate: PERMISSIONS.regulatory_infoRate,
  chemistry: PERMISSIONS.regulatory_infoChemistry,
  expense: PERMISSIONS.regulatory_infoExpense,
  cultivar_group: PERMISSIONS.regulatory_infoСultivarGroup,
  units: PERMISSIONS.regulatory_infoUnit,
  quantity: PERMISSIONS.regulatory_infoQuantityDistributionList,
  methodology: PERMISSIONS.regulatory_infoMethodology,
  organizations: PERMISSIONS.registrationOrganizationDischarge,
  cultivar_usage_type: PERMISSIONS.regulatory_infoCultivarUsageType,
  departments:PERMISSIONS.regulatory_infoDepartment,
  duty: PERMISSIONS.registrationDuty,
  order: PERMISSIONS.regulatory_infoOrder,
  districts: PERMISSIONS.regulatory_infoDistrict,
  reproduction: PERMISSIONS.regulatory_infoReproduction,
  subject: PERMISSIONS.registrationSubject,
  tеchnologicalMaps: PERMISSIONS.agricultural_labourTechMapPlan,
  material_type: PERMISSIONS.regulatory_infoMaterialType,
  vegetation_stages: PERMISSIONS.regulatory_infoVegetationStage,
  vegetation_causes: PERMISSIONS.regulatory_infoVegetationCauses,
  budgetary_trials_calc: PERMISSIONS.trialBudgetaryTrialsCalc,
  indicator: PERMISSIONS.regulatory_infoIndicator,
  branches: PERMISSIONS.regulatory_infoBranch,
  varieties: PERMISSIONS.regulatory_infoVariety,
  parameters: PERMISSIONS.regulatory_infoIndicator,
};
