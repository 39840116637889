import { useState } from "react";
import {
  FiltersType
} from "@/components/Filters/interfaces";
import { IFilter } from "@/interfaces";
import {
  useInfiniteScroll,
  useQuery,
  useStores
} from "@/hooks";
import useDebounce from "@/hooks/useDebounce";
import { SWR_KEYS } from "@/const";
import {
  ICultivarFieldVarietyQueryParams
} from "@/pages/CultivarFieldVariety/interfaces";
import { STATUSES_CULTIVAR_FIELD_VARIETY_LIST } from "../const";

const useFilters = (): IFilter[] => {
  const { api } = useStores();

  const query = useQuery<ICultivarFieldVarietyQueryParams>();

  const [searchCultivar, setSearchCultivar] = useState("");
  const dbouncedSearchCultivarValue = useDebounce(
    searchCultivar,
    500,
  );

  const getKey = (index: number) => {
    return {
      _key: SWR_KEYS.getCultivarFieldVarietyList(),
      page: index + 1,
      search: dbouncedSearchCultivarValue,
    };
  };

  const {
    data: cultivarData,
    handleScroll: handleCultivarScroll
  } =
    useInfiniteScroll(getKey, api.regulatoryInfo.getCultivarList, {
      revalidateFirstPage: false,
    });

  const cultivarOptions = cultivarData.map(({
    name,
    id
  }) => {
    return { label: name, key: id };
  });

  const filters = [
    {
      name: "cultivar",
      label: "Культура",
      type: FiltersType.autoComplete,
      selectedValue: query.cultivar,
      options: cultivarOptions,
      value: "",
      handleScroll: handleCultivarScroll,
      style: { minWidth: "230px", flexGrow: 1 },
      setValue: setSearchCultivar,
    },
    {
      name: "year",
      label: "Год испытания",
      type: FiltersType.yearPicker,
      selectedValue: query.year,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
    },
    {
      name: "status",
      label: "Статус",
      type: FiltersType.autoComplete,
      selectedValue: query.status,
      options: STATUSES_CULTIVAR_FIELD_VARIETY_LIST,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
    },
  ];

  return filters;
};

export default useFilters;