import { makeAutoObservable } from "mobx";
import { RootStore } from "./Root";
import { QS_KEYS } from "@/apps/RightSidebar/const";
import qs from "qs";
import { ValueOf } from "@/utils/types";
import { KEYS_SIDEBAR_VARIANT_MODULES } from "@/const";

// Определяем интерфейс для значений параметров запроса
interface QueryValue {
  [key: string]: string | string[] | undefined;
}

interface ISetupQsSidebar {
  tab: string | undefined;
  inner_tab?: string | undefined;
  keyContent: ValueOf<typeof KEYS_SIDEBAR_VARIANT_MODULES>;
  module: number | string;
  module_id: number | string;
  detail_id?: string | number | null;
  keyDetail?: string | null;
  trial_type?:
    {
      key: string | undefined,
      label: string | undefined,
    } 
    | string 
    | null;
  typeTesting?: string;
}

export class QueryStringSidebarCollector {
  rootStore: RootStore;
  params: {
    [key: string]: string;
  };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.params = {};
    makeAutoObservable(this);
  }

  private getQuery = () => {
    const queryParams: QueryValue = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    }) as QueryValue;

    return queryParams;
  };

  private getModule = (qs: QueryValue) => {
    if (qs[QS_KEYS.KEY_SIDEBAR_DETAIL_TYPE]) {
      return qs[QS_KEYS.KEY_SIDEBAR_DETAIL_TYPE];
    }
    if (qs[QS_KEYS.KEY_SIDEBAR_TYPE]) {
      return qs[QS_KEYS.KEY_SIDEBAR_TYPE];
    }
    return false;
  };

  private getModuleId = (qs: QueryValue) => {
    try {
      const isFindQuery = qs[`${qs[QS_KEYS.KEY_SIDEBAR_TYPE]}_id`];
      if (isFindQuery) {
        return isFindQuery;
      }
    } catch (error) {
      throw new Error("no find module id");
    }
  };

  getCurrentModuleId = (): number | undefined => {
    try {
      const currentModuleId = this.getModuleId(this.getQuery());
      if (currentModuleId) {
        return Number(currentModuleId);
      }
    } catch (error) {
      throw new Error("no find module id");
    }
  };

  private getDetailId = (qs: QueryValue) => {
    try {
      const isFindQuery = qs[`${qs[QS_KEYS.KEY_SIDEBAR_DETAIL_TYPE]}_id`];
      if (isFindQuery) {
        return isFindQuery;
      }
    } catch (error) {
      throw new Error("no find detail id");
    }
  };

  clear = () => {
    const query = this.getQuery();
    const queryParams = qs.stringify(
      {
        ...query,
        [`${query.keySidebar}_id`]: undefined,
        [`${query.keyDetail}_id`]: undefined,
        [QS_KEYS.KEY_SIDEBAR_TYPE]: undefined,
        [QS_KEYS.KEY_SIDEBAR_DETAIL_TYPE]: undefined,
        [QS_KEYS.TAB]: undefined,
        [QS_KEYS.INNER_TAB]: undefined,
        [QS_KEYS.TRIAL_TYPE]: undefined,
        [QS_KEYS.TYPE_TESTING]: undefined,
      },
      { arrayFormat: "comma" }
    );
    return queryParams;
  };

  getType = (qs: QueryValue) => {
    try {
      const isFindQuery = qs[QS_KEYS.TYPE];
      if (isFindQuery) {
        return isFindQuery;
      }
    } catch (error) {
      throw new Error("no find type");
    }
  };

  getModuleFromHandbook = () => {
    const qs = this.getQuery();
    try {
      const isFindQuery = qs[QS_KEYS.KEY_HANDBOOCK_MODULE];
      if (isFindQuery) {
        return isFindQuery;
      }
    } catch (error) {
      throw new Error("no find type");
    }
  };

  setup = ({
    tab,
    inner_tab,
    keyContent,
    module,
    keyDetail,
    module_id,
    detail_id,
    trial_type,
    typeTesting,
  }: Partial<ISetupQsSidebar>) => {
    const query = this.getQuery();

    const newQuery = {
      ...query,
      [`${keyContent}_id`]: module_id,
      [`${keyDetail}_id`]: detail_id,
      [QS_KEYS.KEY_SIDEBAR_TYPE]: keyContent,
      [QS_KEYS.KEY_HANDBOOCK_MODULE]: module,
      [QS_KEYS.KEY_SIDEBAR_DETAIL_TYPE]: keyDetail,
      [QS_KEYS.TAB]: tab,
      [QS_KEYS.INNER_TAB]: inner_tab,
      [QS_KEYS.TRIAL_TYPE]: trial_type,
      [QS_KEYS.TYPE_TESTING]: typeTesting,
    };
    const queryParams = qs.stringify(newQuery, { arrayFormat: "comma" });

    return {
      obj: {
        module: keyContent,
        modalProps: {
          id: module_id,
          detail_id: this.getDetailId(query)
        }
      },
      str: queryParams
    };
  };

  init = () => {
    const qs = this.getQuery();
    const module = this.getModule(qs);
    const params = {
      id: this.getModuleId(qs),
      detail_id: this.getDetailId(qs)
    };
    return {
      module,
      modalProps: params
    };
  };
}
