import React from "react";
import { IIcon } from "./interfaces";

const Video = ({ className }: IIcon): React.ReactElement => {
  return (
    <svg 
      className={className} 
      xmlns="http://www.w3.org/2000/svg" 
      width="20.000000" 
      height="20.000000" 
      viewBox="0 0 20 20" 
      fill="currentColor">
      <g clipPath="url(#clip3_87521)">
        <path 
          id="Vector" 
          d="M13.29 9.6L9.14 6.59C9.07 6.53 8.98 6.5 8.89 6.49C8.79 6.48 8.7 6.5 8.62 6.54C8.54 6.59 8.47 6.65 8.42 6.73C8.37 6.81 8.35 6.9 8.35 6.99L8.35 13.02C8.35 13.12 8.37 13.21 8.42 13.29C8.47 13.37 8.54 13.43 8.62 13.47C8.7 13.51 8.79 13.53 8.89 13.52C8.98 13.52 9.07 13.48 9.14 13.43L13.29 10.41C13.36 10.37 13.41 10.31 13.45 10.24C13.48 10.16 13.5 10.09 13.5 10.01C13.5 9.93 13.48 9.85 13.45 9.78C13.41 9.71 13.36 9.65 13.29 9.6Z" 
          fill="currentColor" 
          fillOpacity="1.000000" 
          fillRule="nonzero" />
        <path 
          id="Vector" 
          d="M10 0C4.47 0 0 4.47 0 10C0 15.52 4.47 19.99 10 19.99C15.52 19.99 20 15.52 20 10C20 4.47 15.52 0 10 0ZM10 18.33C5.4 18.33 1.67 14.6 1.67 10C1.67 5.4 5.4 1.67 10 1.67C14.59 1.67 18.32 5.4 18.32 10C18.32 14.6 14.59 18.33 10 18.33Z" 
          fill="currentColor" 
          fillOpacity="1.000000" 
          fillRule="nonzero" />
      </g>
    </svg>
  );
};

export default Video;
