import React, { useEffect } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Box,
  Divider,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";
import {
  EditIcon,
  DeleteIcon,
  HpIcon,
  OSIcon,
  PhytoIcon,
  FrostIcon,
  CollectionIcon,
  CustomIcon,
  GmoIcon,
} from "@/components/icons";
import { buttonDotsStyle } from "../../style";
import { StyledMenuItem } from "@/components/StyledMenuItem";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import { useQuery, useStores } from "@/hooks";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { resInterface } from "@/api/interfaces";
import { stringWithSuffix } from "@/utils/helpers";
import theme from "@/theme";
import { SWR_KEYS } from "@/const";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  delete: "Удалить",
};

export const TYPE = {
  HP: "hp",
  OOS: "oos",
  PHYTO: "phyto",
  FROST: "frost",
  COLLECTION: "collection",
  CUSTOMS: "customs",
  GMO: "gmo",
};

export const TYPE_LABEL = {
  HP: "Хоз. полезность",
  OOS: "ООС",
  PHYTO: "Фитопатология",
  FROST: "Морозоустойчивость",
  COLLECTION: "Коллекция",
  CUSTOMS: "Таможенный контроль",
  GMO: "Испытания на ГМО",
} as const;

export const TYPES_ARRAY = [
  {
    name: TYPE.HP,
    label: TYPE_LABEL.HP,
    icon: <HpIcon sx={{ width: "20px", height: "20px" }} />,
    textColor: theme.palette.green.main,
  },
  {
    name: TYPE.OOS,
    label: TYPE_LABEL.OOS,
    icon: <OSIcon sx={{ width: "20px", height: "20px" }} />,
    textColor: theme.palette.another.orange,
  },
  {
    name: TYPE.PHYTO,
    label: TYPE_LABEL.PHYTO,
    icon: (
      <PhytoIcon sx={{ width: "20px", height: "20px" }} />
    ),
    textColor: theme.palette.red.main,
  },
  {
    name: TYPE.FROST,
    label: TYPE_LABEL.FROST,
    icon: (
      <FrostIcon sx={{ width: "20px", height: "20px" }} />
    ),
    textColor: theme.palette.blue.main,
  },
  {
    name: TYPE.COLLECTION,
    label: TYPE_LABEL.COLLECTION,
    icon: (
      <CollectionIcon
        sx={{ width: "20px", height: "20px" }}
      />
    ),
    textColor: theme.palette.yellow.main,
  },
  {
    name: TYPE.CUSTOMS,
    label: TYPE_LABEL.CUSTOMS,
    icon: (
      <CustomIcon sx={{ width: "20px", height: "20px" }} />
    ),
    textColor: theme.palette.blue.superDarkBlue,
  },
  {
    name: TYPE.GMO,
    label: TYPE_LABEL.GMO,
    icon: (
      <GmoIcon sx={{ width: "20px", height: "20px" }} />
    ),
    textColor: theme.palette.another.violet,
  },
] as const;

export const TypeCell = (
  _: string,
  cell: resInterface.IQDList,
): JSX.Element => {
  const { type } = cell;
  const decoratedType = TYPES_ARRAY.find(
    (item) => item.name === type,
  );
  return (
    <Box>
      <Typography
        sx={{
          display: "inline-flex",
          alignItems: "center",
          gap: "5px",
        }}
        fontSize={14}
        color={decoratedType?.textColor}
      >
        {decoratedType?.icon}
        {decoratedType?.label}
      </Typography>
    </Box>
  );
};

export const СultivarCell = (
  _: string,
  cell: resInterface.IQDList,
): JSX.Element => {
  const { cultivar } = cell;
  return (
    <Box>
      <Typography fontSize={14}>
        {cultivar?.name ?? "-"}
      </Typography>
    </Box>
  );
};

export const MaterialTypeCell = (
  _: string,
  cell: resInterface.IQDList,
): JSX.Element => {
  const { material_type } = cell;
  return (
    <Box>
      <Typography fontSize={14}>
        {material_type?.name ?? "-"}
      </Typography>
    </Box>
  );
};

export const QuantityCell = (
  _: string,
  cell: resInterface.IQDList,
): JSX.Element => {
  const { quantity, unit } = cell;
  return (
    <Box>
      <Typography
        fontSize={14}
        color={theme.palette.blackAndWhite.gray}
      >
        {stringWithSuffix(
          quantity.toString(),
          unit?.name || "",
          "-",
          true,
        )}
      </Typography>
    </Box>
  );
};

/**
 * @component EditingCell
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCell = (
  _: string,
  cell: resInterface.IQDList,
  itemsInPage: any[],
): JSX.Element => {
  const { api, modalStore, swrStore, userStore } = useStores();
  const [anchorEl, setAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: "true" },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickEdit = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    const initialValues = {
      ...cell,
      branch: {
        ...cell?.plot?.branch,
        label: cell?.plot?.branch?.name,
      },
      region: {
        ...cell?.plot?.branch?.region,
        label: cell?.plot?.branch?.region?.name,
      },
    };

    event.stopPropagation();
    modalStore.open(VARIANT_MODAL.QUANTITY, {
      theme: THEME_MODAL.W_555,
      initialValues: initialValues,
      quantityId: cell.id,
    });
    setAnchorEl(null);
  };

  const mutateList =
    swrStore.mutators[
      SWR_KEYS.getQuantityDistributionList()
    ];

  const deleteWithCheckingLast = () => {
    // если один эл-т на странице перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateList && mutateList();
    }
  };

  const handleDelete = (resolve, reject) => {
    api.regulatoryInfo
      .deleteQuantityDistributionItem(cell.id)
      .then(() => {
        deleteWithCheckingLast();
        resolve();
      })
      .catch(reject);
    setAnchorEl(null);
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.handbook,
      id: cell.id,
      handleDelete: handleDelete,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(
      VARIANT_MODAL.OK_OR_NOT_DELETE,
      modalProp,
    );
  };

  const canEditQuantity= userStore.isPermission(
    PERMISSIONS.regulatory_infoСultivarGroup,
    PERMISSION_CRUD.change
  )
  const canDeleteQuantity = userStore.isPermission(
    PERMISSIONS.regulatory_infoСultivarGroup,
    PERMISSION_CRUD.delete
  )

  if (!canEditQuantity && !canDeleteQuantity) {
    return <></>
  }


  return (
    <Box>
      <IconButton
        sx={buttonDotsStyle()}
        onClick={handleToggle}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {canEditQuantity && 
        <StyledMenuItem onClick={handleClickEdit}>
          <EditIcon />
          {EDITING_CELL.editing}
        </StyledMenuItem>
        }
        {canEditQuantity && canDeleteQuantity && 
        <Divider />
        }
        {canDeleteQuantity && 
        <StyledMenuItem onClick={handleClickDelete}>
          <DeleteIcon color={"error"} />
          <Typography color="error">
            {EDITING_CELL.delete}
          </Typography>
        </StyledMenuItem>
        }
      </Menu>
    </Box>
  );
};
