import React, {
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useLocation,
  useSearchParams,
} from "react-router-dom";
import Table from "@/apps/Table";
import { observer } from "mobx-react";
import { useQuery, useStores } from "@/hooks";
import {
  IMethList,
  IPaginatedResult,
} from "@/api/interfaces/responses";
import { DEFAULT_SIZE_PAGE } from "@/components/Pagination/const";
import { SWR_KEYS } from "@/const";
import { IErrorResponse } from "@/interfaces";
import useSWR, { SWRResponse } from "swr";
import { IPropsSidebar, TMethKeys } from "../../interfaces";
import AddIcon from "@mui/icons-material/Add";
import qs from "qs";
import Filters from "@/components/Filters";
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  Typography,
} from "@mui/material";

import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import { isUndefined } from "@/utils/helpers";
import { TYPES_ARRAY } from "./cells";
import { getFilters } from "./utils";
import { methodlogyColumns } from "../../models/methodology";
import { IMethodologyQueryParams } from "./types";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

/**
 * Компонент, представляющий таблицу методик.
 * @returns {ReactElement}
 */

const MethodologyTable: React.FC<IPropsSidebar> = ({
  handleOpenRightSidebar,
}): ReactElement => {
  const { api, swrStore, modalStore, userStore } = useStores();
  const query = useQuery<IMethodologyQueryParams>();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [queryParams, setQueryParams] =
    useState<IMethodologyQueryParams>(query);

  // переменная которая показывает применены ли фильтры
  const isFilters: boolean =
    Object.keys(queryParams).length > 0;

  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orderingArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering)
      ? query.ordering
      : [query.ordering];
  }, [query.ordering]);

  const [selectedType, setSelectedType] = useState("");

  const [orderBy, setOrderBy] =
    useState<string[]>(orderingArray);

  const {
    data,
    error,
    isLoading,
    mutate,
  }: SWRResponse<
    IPaginatedResult<IMethList>,
    IErrorResponse
  > = useSWR(
    {
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      search: queryParams.search,
      type_trial: queryParams.type,
      key: SWR_KEYS.getMethodologyList(),
    },
    api.regulatoryInfo.getMethodologyList,
  );

  const handleOpenCreateModal = () => {
    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.ADD_METHODOLOGY, {
      theme: THEME_MODAL.W_555,
    });
  };

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleChangeOrderBy = (value) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  useEffect(() => {
    setQueryParams(query);
  }, [location.search]);

  swrStore.addMutator(
    SWR_KEYS.getMethodologyList(),
    mutate,
  );

  const renderTypeMenuItems = TYPES_ARRAY.map((el) => {
    return (
      <MenuItem key={el.name} value={el.name}>
        <Box
          display={"flex"}
          gap={"10px"}
          alignItems={"center"}
        >
          <ListItemIcon>{el.icon}</ListItemIcon>
          <Typography color={el.textColor}>
            {el.label}
          </Typography>
        </Box>
      </MenuItem>
    );
  });

  const handleTypeSelectChange = (event) => {
    const queryParams = qs.stringify(
      { ...query, type: event.target.value, page: 1 },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setSelectedType(event.target.value);
  };

  const handleClearTypeSelect = () => {
    const queryParams = qs.stringify(
      { ...query, type: undefined },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setSelectedType("");
  };

  const filters = getFilters(
    handleTypeSelectChange,
    handleClearTypeSelect,
    renderTypeMenuItems,
    selectedType,
  );

  const canAddMethodology = userStore.isPermission(
    PERMISSIONS.regulatory_infoMethodology,
    PERMISSION_CRUD.add
  )

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Filters
          filters={filters}
          defaultSearch={query.search}
        />
        {canAddMethodology && 
        <Button
          sx={{
            whiteSpace: "nowrap",
            flexShrink: "0",
          }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenCreateModal}
        >
          Добавить запись
        </Button>
        }
      </Box>

      <Table<IMethList, TMethKeys>
        columns={methodlogyColumns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={"Добавить запись"}
        textEmptyTableWithFilter={
          "Не найдено ни одной записи"
        }
        textEmptyTable={"Еще не создано ни одной записи"}
        onRowClick={handleOpenRightSidebar}
        emptyTableAddEntity={handleOpenCreateModal}
        overflowY="scroll"
      />
    </Fragment>
  );
};

export default observer(MethodologyTable);
