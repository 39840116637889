import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import DownloadPlanningForm
  from "./components/DownloadPlanningForm";
import {
  IFormik,
  initialValues,
  validationSchema,
} from "./const";

/**
 * Компонент модалки реализующий скачивание сводного плана по испытаниям
 */

interface IProps {
  handleClose: () => void;
  modalProps: {
   title: string,
  handleSubmit: (values: IFormik,
    helpers: FormikHelpers<FormikValues>) => void
  };
}

const DownloadPlanning: React.FC<IProps> = ({ modalProps }) => {
  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography variant="h2" fontSize={24}>
          {modalProps.title}
        </Typography>
      </Box>
      <Divider/>
      <Formik<IFormik>
        initialValues={initialValues}
        onSubmit={modalProps.handleSubmit}
        validationSchema={validationSchema}
        component={DownloadPlanningForm}
      />
    </Box>
  );
};

export default DownloadPlanning;
