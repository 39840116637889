import React, { useEffect, useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Box,
  Divider,
  IconButton,
  Menu,
  SxProps,
  Typography,
} from "@mui/material";
import { EditIcon, DeleteIcon } from "@/components/icons";
import { buttonDotsStyle } from "../../style";
import { StyledMenuItem } from "@/components/StyledMenuItem";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { useQuery, useStores } from "@/hooks";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import theme from "@/theme";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import { SWR_KEYS } from "@/const";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

export const cellOrderStyle = (
  backgroundColor: string,
  textColor: string,
): SxProps => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
  padding: "3px 5px",
  color: textColor,
  backgroundColor: backgroundColor,
  borderRadius: "100px",
  minWidth: "150px",
  maxWidth: "160px",
  textWrap: "nowrap",
});

export const ORDER_TYPE_COLORS = {
  cultivar: {
    background: theme.palette.blackAndWhite.grayLight,
    text: theme.palette.blackAndWhite.black,
  },
  hp_grouping: {
    background: theme.palette.blackAndWhite.grayLight,
    text: theme.palette.blackAndWhite.black,
  },
};

/**
 * @component type order
 * * компонент ячейки таблицы
 * @param
 */
export const OrderTypeCell = (order: any): JSX.Element => {
  const ORDER_TYPE = {
    cultivar: "Места испытаний",
    hp_grouping: "Правила группировки",
  };

  const backgroundColor =
    ORDER_TYPE_COLORS[order]?.background ?? theme.palette.blackAndWhite.white;
  const textColor =
    ORDER_TYPE_COLORS[order]?.text ?? theme.palette.blackAndWhite.white;

  return (
    <Box sx={cellOrderStyle(backgroundColor, textColor)}>
      <Typography fontSize={14}>{ORDER_TYPE[order] ?? "-"}</Typography>
    </Box>
  );
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCellOrder = (_: string, cell: any, itemsInPage: any[]): JSX.Element => {
  const { api, modalStore, swrStore, userStore } = useStores();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: 'true' },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);



  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    modalStore.open(VARIANT_MODAL.ORDER, {
      theme: THEME_MODAL.W_555,
      initialValues: cell,
      orderId: cell.id,
    });
    setAnchorEl(null);
  };

  const mutateList =
    swrStore.mutators[SWR_KEYS.getOrderList()];

  const deleteWithCheckingLast = () => {
    // если один эл-т на странице перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateList && mutateList();
    }
  };

  const handleDelete = (resolve, reject) => {
    api.regulatoryInfo.deleteOrder(cell.id).then(
      () => {
        deleteWithCheckingLast();
        resolve()
      }
    ).catch(reject);
    setAnchorEl(null);
  };

  const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.handbook,
      id: cell.id,
      handleDelete: handleDelete,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.OK_OR_NOT_DELETE, modalProp);
  };

  const canEditOrder= userStore.isPermission(
    PERMISSIONS.regulatory_infoOrder,
    PERMISSION_CRUD.change
  )
  const canDeleteOrder = userStore.isPermission(
    PERMISSIONS.regulatory_infoOrder,
    PERMISSION_CRUD.delete
  )

  if (!canEditOrder && !canDeleteOrder) {
    return <></>
  }

  return (
    <Box>
      <IconButton sx={buttonDotsStyle()} onClick={handleToggle}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {canEditOrder && 
        <StyledMenuItem onClick={handleClickEdit}>
          <EditIcon />
          {EDITING_CELL.editing}
        </StyledMenuItem>
        }
        {canEditOrder && canDeleteOrder && 
        <Divider />
        }
        {canDeleteOrder && 
        <StyledMenuItem onClick={handleClickDelete}>
          <DeleteIcon color={"error"} />
          <Typography color='error'>{EDITING_CELL.delete}</Typography>
        </StyledMenuItem>
        }
      </Menu>
    </Box>
  );
};
