import { useMemo } from "react";
import {
  ROUTES
} from "@/apps/AppRouter/const";
import { useStores } from "@/hooks";
import {
  PERMISSIONS,
  PERMISSION_CRUD
} from "@/premissions";

export const usePlanningPermission = () => {
  const { userStore } = useStores()

  const canViewPlanning = userStore.isPermission(
    PERMISSIONS.planningPlan,
    PERMISSION_CRUD.view
  );

  const canViewPlanningEA = userStore.isPermission(
    PERMISSIONS.planningEAPlan,
    PERMISSION_CRUD.view
  );

  const canViewPlanningCrop = userStore.isPermission(
    PERMISSIONS.regulatory_infoOrderCultivar,
    PERMISSION_CRUD.view
  );

  const canViewPlanningPlanHP = userStore.isPermission(
    PERMISSIONS.planningPlanHP,
    PERMISSION_CRUD.view
  );

  const redirectPath = useMemo(() => {
    switch (true) {
      case canViewPlanningEA:
        return ROUTES.expertAssessment
      case canViewPlanningCrop:
        return ROUTES.crop
      case canViewPlanningPlanHP:
        return ROUTES.hp
      default:
        return "/"
    }
  }, [
    canViewPlanning,
    canViewPlanningEA,
    canViewPlanningCrop,
    canViewPlanningPlanHP
  ])

  return {
    canViewPlanning,
    redirectPath
  };
};