import React from "react";
import { Field, Form, useFormikContext } from "formik";
import { useStores } from "@/hooks";
import {
  DISEASE_FIELD_NAME,
  ICultivarDisease,
  IProps,
  TForm,
} from "../../const";

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import { StyledChip } from "../../../CreateVariety/styles";
import AddChip from "@/components/Chips/AddChip";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { resInterface } from "@/api/interfaces";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";

/**
 * Форма создания/редактирования культуры
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const CountryForm: React.FC<IProps> = ({ modalProps }) => {
  const { modalStore, userStore } = useStores();
  const form = useFormikContext<TForm>();

  const title = modalProps.diseaseId
    ? "Редактировать запись"
    : "Добавить запись";

  const handleAddCultivar = () => {
    modalStore.open(VARIANT_MODAL.SELECT_CULTIVARS, {
      onSubmit: handleSelectCultivar,
      initialValue: form.values.cultivar
    });
  };

  const handleSelectCultivar = (payload: resInterface.ICultivar[]) => {
    modalStore.setModalPropsByKey(modalProps.key, {
      initialValues: {
        ...form.values,
        [DISEASE_FIELD_NAME.CULTIVAR]: payload,
      },
    });
  };

  const handleCancelCultivar = (id, index) => {
    let newValue: (ICultivarDisease | undefined)[] = [];

    if (form.values.cultivar.length) {
      newValue = form.values.cultivar;
      if (newValue[index]?.id === id) {
        newValue.splice(index, 1);
      }
    }
    if (newValue[0] === undefined) {
      newValue = [];
    }
    form.setFieldValue(DISEASE_FIELD_NAME.CULTIVAR, newValue);
  };

  const isShowCultivar = userStore.isPermission(
    PERMISSIONS.regulatory_infoСultivar,
    PERMISSION_CRUD.view
  )

  return (
    <>
      <Box p={3}>
        <Typography>Заболевание культур</Typography>
        <Typography variant='h2'>{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>
        <Stack direction={"column"} gap={2} p={2}>
          <Field name={DISEASE_FIELD_NAME.NAME}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label='Название'
                  fullWidth
                  size='small'
                  required
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[DISEASE_FIELD_NAME.NAME]}
                  disabled={meta.isSubmitting}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>

          <Field name={DISEASE_FIELD_NAME.CULTIVAR}>
            {({ field, meta }) => (
              <FormControl>
                <FormLabel>Культура</FormLabel>
                <Tooltip
                  followCursor
                  title={
                    !isShowCultivar
                      ? "У вас недостаточно прав для просмотра списка культур"
                      : ""
                  }
                >
                <Box>
                  {field.value?.length ? (
                    <>
                      {field.value?.map((item, index) => (
                        <StyledChip
                          disabled={!isShowCultivar}
                          key={item?.id}
                          size='small'
                          variant='outlined'
                          label={item?.name}
                          onDelete={() => handleCancelCultivar(item?.id, index)}
                          sx={{
                            marginRight: "10px",
                          }}
                        />
                      ))}
                      <AddChip onClick={handleAddCultivar} disabled={!isShowCultivar}/>
                    </>
                  ) : (
                    <AddChip onClick={handleAddCultivar}  disabled={!isShowCultivar}/>
                  )}
                </Box>
                </Tooltip>
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={DISEASE_FIELD_NAME.DESCRIPTION}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label='Описание'
                  fullWidth
                  size='small'
                  multiline
                  minRows={4}
                  maxRows={10}
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[DISEASE_FIELD_NAME.DESCRIPTION]}
                  disabled={meta.isSubmitting}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Stack>

        <Divider />
        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red' onClick={() => modalStore.close()}>
            отмена
          </Button>
          <Button
            type='submit'
            disabled={form.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </>
  );
};

export default CountryForm;
