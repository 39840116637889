import AbstractApi from "@/api/abstract";
import { apiInterface, resInterface } from "@/api/interfaces";

class SettingsApi implements apiInterface.ISettingsApi {
  ctx: AbstractApi;

  constructor(ctx: AbstractApi) {
    this.ctx = ctx;
  }

  getManualLink = async () => {
    const res = await this.ctx.get<
      unknown,
      resInterface.IManualLinks
    >("/settings/manual/");
    return res.data;
  }
}

export default SettingsApi;
