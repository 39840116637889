import { IColumn } from "@/apps/Table/interfaces";
import {
  PlotCells,
  CultivarCells,
  TrialTypeCells,
  FieldNameCells,
  VarietyCells,
  TrialStatusCells,
  YearCells
} from "../components/Cells";

import {
  CULTIVAR_FIELD_VARIETY_LABELS,
  CULTIVAR_FIELD_VARIETY_KEYS
} from "../const";

import { TCultivarFieldVarietyKeys } from "../interfaces";

import {
  cell,
  headerCellStyle,
  trialTypeCellsStyle
} from "../style";

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const columns: IColumn<TCultivarFieldVarietyKeys>[] = [

  {
    label: CULTIVAR_FIELD_VARIETY_LABELS.plot,
    key: CULTIVAR_FIELD_VARIETY_KEYS.plotName,
    contentHeader: CULTIVAR_FIELD_VARIETY_LABELS.plot,
    bodyCellProps: {
      render: PlotCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: CULTIVAR_FIELD_VARIETY_LABELS.yearTrial,
    key: CULTIVAR_FIELD_VARIETY_KEYS.yearTrial,
    contentHeader: CULTIVAR_FIELD_VARIETY_LABELS.yearTrial,
    bodyCellProps: {
      render: YearCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: CULTIVAR_FIELD_VARIETY_LABELS.typeTrial,
    key: CULTIVAR_FIELD_VARIETY_KEYS.trialType,
    contentHeader: CULTIVAR_FIELD_VARIETY_LABELS.typeTrial,
    bodyCellProps: {
      render: TrialTypeCells,
      styles: trialTypeCellsStyle
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: CULTIVAR_FIELD_VARIETY_LABELS.nameField,
    key: CULTIVAR_FIELD_VARIETY_KEYS.nameField,
    contentHeader: CULTIVAR_FIELD_VARIETY_LABELS.nameField,
    bodyCellProps: {
      render: FieldNameCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: CULTIVAR_FIELD_VARIETY_LABELS.cultivar,
    key: CULTIVAR_FIELD_VARIETY_KEYS.cultivarName,
    contentHeader: CULTIVAR_FIELD_VARIETY_LABELS.cultivar,
    bodyCellProps: {
      render: CultivarCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: CULTIVAR_FIELD_VARIETY_LABELS.variety,
    key: CULTIVAR_FIELD_VARIETY_KEYS.varietyName,
    contentHeader: CULTIVAR_FIELD_VARIETY_LABELS.variety,
    bodyCellProps: {
      render: VarietyCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: CULTIVAR_FIELD_VARIETY_LABELS.status,
    key: CULTIVAR_FIELD_VARIETY_KEYS.status,
    contentHeader: CULTIVAR_FIELD_VARIETY_LABELS.status,
    bodyCellProps: {
      render: TrialStatusCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
];
