import React, { ReactElement } from "react";

import Handboock from "@/apps/Handboock";
import { Stack } from "@mui/material";

const HandbookPage = (): ReactElement => {
  return (
    <Stack direction={"column"} sx={{ width: "100%", height: "100%", overflow: "scroll"}} p={3}>
      <Handboock />
    </Stack>
  );
};

export default HandbookPage;
