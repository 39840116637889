import React from "react";
import { RouteObject } from "react-router";
import { Navigate } from "react-router-dom";

import Login from "@/pages/auth/Login";
import Error500 from "@/pages/Error500";
import {
  WaitingForRole
} from "@/pages/auth/WaitingForRole/WaitingForRole";
import {
  EmailConfirm
} from "@/pages/auth/EmailConfirm/EmailConfirm";
import {
  EmailIsSend
} from "@/pages/auth/EmailIsSend/EmailIsSend";
import {
  PasswordSuccess
} from "@/pages/auth/PasswordSuccess/PasswordSuccess";
import StatementsPage from "@/pages/Statement";
import MainPage from "@/pages/Main";
import AgriculturalWork
  from "@/pages/AgriculturalWorkLocation";
import OffBudget from "@/pages/OffBudget";
import HandbookPage from "@/pages/Handbook";
import ReportsPage from "@/pages/Reports";
import AddStatementPage from "@/pages/AddStatement";
import Profile from "@/pages/Profile";
import PlanningPage from "@/pages/Planning";
import PlaningCropPage from "@/pages/PlaningCrop";
import Map from "@/pages/maps/Map";
import EditStatementPage from "@/pages/EditStatement";
import {
  PasswordResetConfirm
} from "@/pages/auth/PasswordResetConfirm/PasswordResetConfirm";
import PasswordResetRequest
  from "@/pages/auth/PasswordResetRequest/PasswordResetRequest";
import HpTrialPage from "@/pages/HpTrial";
import OosTrialPage from "@/pages/OosTrial";
import PhytoTrialPage from "@/pages/PhytoTrial";
import AddPlanPage from "@/pages/AddPlan";
import AddFactPage from "@/pages/AddFact";
import AddArea from "@/pages/AddArea";
import AddField from "@/pages/AddField";
import AddOrderCultivar from "@/pages/AddOrderCultivar";
import EditOrderCultivar from "@/pages/EditOrderCultivar";
import ExpertAssessment from "@/pages/ExpertAssessment";

import { Role, SEO_TITLES } from "@/const";
import { ROUTES, ROUTES_PATHS } from "./const";

import { GuestRoute } from "./customRoutes/guest";

import OffBudgetFactPage from "@/pages/OffBudgetFact";
import EditPlanPage from "@/pages/EditPlan";
import Error404 from "@/pages/Error404";
import Error404Modules from "@/pages/Error404Modules";
// import AgriculturalWorkFields from "@/pages/AgriculturalWorkFields";
import MainLayout from "@/apps/MainLayout";
import MainLayoutHandbook from "@/apps/MainLayoutHandbook";
import MapAreaDetail from "@/pages/maps/MapAreaDetail";
import MapFieldDetail from "@/pages/maps/MapFieldDetail";
import MapSortDetail from "@/pages/maps/MapSortDetail";
import MapAreaDraw from "@/pages/maps/MapAreaDraw";
import PlanningHp from "@/pages/PlanningHp";
import MapFieldDraw from "@/pages/maps/MapFieldDraw";
import MapSortDraw from "@/pages/maps/MapSortDraw";
import AddPlanningHp from "@/pages/AddPlanningHp";
import SeedAccounting from "@/pages/SeedAccounting";
import CultivarFieldVariety
  from "@/pages/CultivarFieldVariety";
import CultivarFieldVarietyLab
  from "@/pages/CultivarFieldVarietyLab";
import CultivarField from "@/pages/CultivarField";
import AddSeedArrival from "@/pages/AddSeedArrival";
import PlanningAddPlanPage from "@/pages/PlanningAddPlan";
import EditPlanningGroup from "@/pages/EditPlanningGroup";
import AddCultivarFieldWork
  from "@/pages/AddCultivarFieldWork";
import AddCultivarField from "@/pages/AddCultivarField";
import AddCultivarFieldVarietyLab
  from "@/pages/AddCultivarFieldVarietyLab";
import BranchPage from "@/pages/Branch";
import GSUPage from "@/pages/GSU";
import LandAreasPage from "@/pages/LandAreas";
import AddObservationPage from "@/pages/AddObservation";
import LabTrialPage from "@/pages/LabTrial";
import AddExpertAssessmentPage
  from "@/pages/AddExpertAssessment";
import AddTechMap from "@/pages/AddTechMap";
import MapPlotDetail from "@/pages/maps/MapPlotDetail";
import TestingExpertReview from "@/pages/TestingExpertReview";
import AddCultivarFieldVarietyTestingExpertReview from "@/pages/AddCultivarFieldVarietyTestingExpertReview";
import {
  useAgriculturalLabourPermission
} from "@/hooks/routePermission/useAgriculturalLabourPermission";
import { usePlanningPermission } from "@/hooks/routePermission/usePlanningPermission";

export const appRoutes: (
  role?: Role,
) => RouteObject[] = () =>
  // role
{
  const { canViewCultivarField, redirectPath: agriculturalLabourRedirectPath } = useAgriculturalLabourPermission();
  const { canViewPlanning, redirectPath: planningRedirectPath } = usePlanningPermission();

  return [
    {
      path: ROUTES_PATHS.login,
      element: <Login/>,
    },
    {
      path: ROUTES_PATHS.passwordResetRequest,
      element: <PasswordResetRequest/>,
    },
    {
      path: ROUTES_PATHS.passwordResetConfirm,
      element: <PasswordResetConfirm/>,
    },
    {
      path: ROUTES_PATHS.emailConfirm,
      element: <EmailConfirm/>,
    },
    {
      path: ROUTES_PATHS.Error500,
      element: <Error500/>,
    },
    {
      path: ROUTES_PATHS.Error404,
      element: <Error404/>,
    },
    {
      path: ROUTES_PATHS.emailIsSend,
      element: (
        <GuestRoute>
          <EmailIsSend/>
        </GuestRoute>
      ),
    },
    {
      path: ROUTES_PATHS.waitingForRole,
      element: (
        <GuestRoute>
          <WaitingForRole/>
        </GuestRoute>
      ),
    },
    {
      path: ROUTES_PATHS.passwordSuccess,
      element: <PasswordSuccess/>,
    },
    {
      path: ROUTES_PATHS.map,
      element: <Map/>,
    },
    {
      path: ROUTES_PATHS.mapAreaDetail,
      element: <MapAreaDetail/>,
    },
    {
      path: ROUTES_PATHS.mapFieldDetail,
      element: <MapFieldDetail/>,
    },
    {
      path: ROUTES_PATHS.mapSortDetail,
      element: <MapSortDetail/>,
    },
    {
      path: ROUTES_PATHS.mapPlotDetail,
      element: <MapPlotDetail/>,
    },
    {
      path: ROUTES_PATHS.mapAreaDraw,
      element: <MapAreaDraw/>,
    },
    {
      path: ROUTES_PATHS.mapFieldDraw,
      element: <MapFieldDraw/>,
    },
    {
      path: ROUTES_PATHS.mapSortDraw,
      element: <MapSortDraw/>,
    },
    {
      path: `/${ROUTES.statements}`,
      element: <MainLayout title={SEO_TITLES.STATEMENT}/>,
      children: [
        {
          index: true,
          element: <StatementsPage/>,
        },
        {
          path: `:id/${ROUTES.addStatement}`,
          element: <AddStatementPage/>,
        },
        {
          path: `:id/${ROUTES.editStatement}`,
          element: <EditStatementPage/>,
        },
        {
          path: `:statementId/${ROUTES.hpTrial}`,
          element: <HpTrialPage/>,
        },
        {
          path: `:statementId/${ROUTES.hpTrial}/:trialId`,
          element: <HpTrialPage/>,
        },
        {
          path: `:statementId/${ROUTES.oosTrial}`,
          element: <OosTrialPage/>,
        },
        {
          path: `:statementId/${ROUTES.oosTrial}/:trialId`,
          element: <OosTrialPage/>,
        },
        {
          path: `:statementId/${ROUTES.phytoTrial}`,
          element: <PhytoTrialPage/>,
        },
        {
          path: `:statementId/${ROUTES.phytoTrial}/:trialId`,
          element: <PhytoTrialPage/>,
        },
        {
          path: `:statementId/${ROUTES.LabTrial}`,
          element: <LabTrialPage/>,
        },
        {
          path: `:statementId/${ROUTES.LabTrial}/:trialId`,
          element: <LabTrialPage/>,
        },
      ],
    },
    {
      path: ROUTES_PATHS.planning,
      element: <MainLayout title={SEO_TITLES.PLANNING}/>,
      children: [
        {
          index: true,
          element: canViewPlanning
            ? <PlanningPage/> 
            : <Navigate to={planningRedirectPath} replace/>,
        },
        {
          path: `${ROUTES.crop}`,
          element: <PlaningCropPage/>,
        },
        {
          path: `${ROUTES.expertAssessment}`,
          element: <ExpertAssessment/>,
        },
        {
          path: `${ROUTES.expertAssessment}/:id?/${ROUTES.addExpertAssessment}`,
          element: <AddExpertAssessmentPage/>,
        },
        {
          path: `:id?/${ROUTES.addCultivar}`,
          element: <AddOrderCultivar/>,
        },
        {
          path: `:id/${ROUTES.editCultivar}`,
          element: <EditOrderCultivar/>,
        },
        {
          path: `${ROUTES.hp}`,
          element: <PlanningHp/>,
        },
        {
          path: `${ROUTES.hp}/${ROUTES.hpAdd}`,
          element: <AddPlanningHp/>,
        },
        {
          path: `${ROUTES.hp}/:id/${ROUTES.hpAdd}`,
          element: (
            <AddPlanningHp
              headerTitle="Редактировать культуру"/>
          ),
        },
        {
          path: `${ROUTES.addPlanPageHP}`,
          element: <PlanningAddPlanPage/>,
        },
        {
          path: `${ROUTES.addPlanPageOOS}`,
          element: <PlanningAddPlanPage/>,
        },
        {
          path: `${ROUTES.addPlanPagePhyto}`,
          element: <PlanningAddPlanPage/>,
        },
        {
          path: `${ROUTES.addPlanPageLAB}`,
          element: <PlanningAddPlanPage/>,
        },
        {
          path: `:id/${ROUTES.editPlanningGroup}`,
          element: <EditPlanningGroup/>,
        },
      ],
    },
    {
      path: ROUTES_PATHS.agriculturalWork,
      element: <MainLayout
        title={SEO_TITLES.AGRICULTURAL}/>,
      children: [
        {
          index: true,
          element: <AgriculturalWork/>,
        },
        {
          path: ROUTES_PATHS.addArea,
          element: <AddArea/>,
        },
        {
          path: `:id/${ROUTES.addArea}`,
          element: <AddArea/>,
        },
        {
          path: `${ROUTES.fields}/${ROUTES.addField}`,
          element: <AddField/>,
        },
        {
          path: `${ROUTES.fields}/${ROUTES.addField}/:locationId`,
          element: <AddField/>,
        },
        {
          path: `${ROUTES.fields}/:id/${ROUTES.addField}`,
          element: <AddField/>,
        },
        {
          path: `${ROUTES.fields}/:id/${ROUTES.addField}/:locationId`,
          element: <AddField/>,
        },
      ],
    },
    {
      path: ROUTES_PATHS.agriculturalWorkDoc,
      element: <MainLayout
        title={SEO_TITLES.AGRICULTURAL_WORK}/>,
      children: [
        {
          index: true,
          element: canViewCultivarField
            ? <CultivarField/>
            : <Navigate to={agriculturalLabourRedirectPath} replace/>,
        },
        {
          path: `${ROUTES.addCultivarField}`,
          element: <AddCultivarField/>,
        },
        {
          path: `:id/${ROUTES.addCultivarField}`,
          element: <AddCultivarField/>,
        },
        {
          path: `:cultivarFieldId/${ROUTES.addCultivarFieldWork}`,
          element: <AddCultivarFieldWork/>,
        },
        {
          path: `:cultivarFieldId/${ROUTES.addCultivarFieldWork}/:id`,
          element: <AddCultivarFieldWork/>,
        },
        {
          path: `${ROUTES.seedAccounting}`,
          element: <SeedAccounting/>,
        },
        {
          path: `${ROUTES.seedAccounting}/:id/${ROUTES.addSeedArrival}`,
          element: <AddSeedArrival/>,
        },
        {
          path: `${ROUTES.seedAccounting}/${ROUTES.addSeedArrival}`,
          element: <AddSeedArrival/>,
        },
        {
          path: `${ROUTES.cultivarFieldVariety}`,
          element: <CultivarFieldVariety/>,
        },
        {
          path: `${ROUTES.cultivarFieldVariety}/:id/${ROUTES.addObservation}`,
          element: <AddObservationPage/>,
        },
        {
          path: `${ROUTES.cultivarFieldVariety}/:id/${ROUTES.addObservation}/:observationId`,
          element: <AddObservationPage/>,
        },
        {
          path: `${ROUTES.cultivarFieldVarietyLab}`,
          element: <CultivarFieldVarietyLab/>,
        },
        {
          path: `${ROUTES.cultivarFieldVarietyLab}/${ROUTES.addLab}`,
          element: <AddCultivarFieldVarietyLab/>,
        },
        {
          path: `${ROUTES.testingExpertReview}/${ROUTES.addTestingExpertReview}`,
          element: <AddCultivarFieldVarietyTestingExpertReview />,
        },
        {
          path: `${ROUTES.cultivarFieldVarietyLab}/:id/${ROUTES.addLab}`,
          element: <AddCultivarFieldVarietyLab/>,
        },
        {
          path: `${ROUTES.testingExpertReview}`,
          element: <TestingExpertReview/>,
        },
      ],
    },
    {
      path: ROUTES_PATHS.offBudget,
      element: <MainLayout title={SEO_TITLES.OFF_BUDGET}/>,
      children: [
        {
          index: true,
          element: <OffBudget/>,
        },
        {
          path: `${ROUTES.fact}`,
          element: <OffBudgetFactPage/>,
        },
        {
          path: `:id?/${ROUTES.addPlan}`,
          element: <AddPlanPage/>,
        },
        {
          path: `:id/${ROUTES.editPlan}`,
          element: <EditPlanPage/>,
        },
        {
          path: `:id/${ROUTES.editFact}`,
          element: <AddFactPage/>,
        },
      ],
    },
    {
      path: ROUTES_PATHS.handbook,
      element: <MainLayoutHandbook
        title={SEO_TITLES.HANDBOOK}/>,
      children: [
        {
          index: true,
          element: <HandbookPage/>,
        },
      ],
    },
    {
      path: ROUTES_PATHS.reports,
      element: <MainLayout/>,
      children: [
        {
          index: true,
          element: <ReportsPage/>,
        },
      ],
    },
    {
      element: <MainLayout/>,
      path: ROUTES_PATHS.branch,
      children: [
        {
          path: `:id/`,
          element: <BranchPage/>,
        },
      ],
    },
    {
      element: <MainLayout/>,
      path: ROUTES_PATHS.gsu,
      children: [
        {
          path: `:id/`,
          element: <GSUPage/>,
        },
      ],
    },
    {
      element: <MainLayout/>,
      path: ROUTES_PATHS.landAreas,
      children: [
        {
          path: `:id/`,
          element: <LandAreasPage/>,
        },
      ],
    },
    {
      element: <MainLayout/>,
      path: ROUTES_PATHS.main,
      children: [
        {
          path: ROUTES_PATHS.main,
          element: <MainPage/>,
        },
        {
          path: ROUTES_PATHS.profile,
          element: <Profile/>,
        },
        {
          path: `/${ROUTES.Error404Modules}`,
          element: <Error404Modules/>,
        },
      ],
    },
    {
      element: <MainLayout/>,
      path: `/${ROUTES.addTechMap}`,
      children: [
        {
          path: `${ROUTES.addTechMapForm}`,
          element: <AddTechMap/>,
        },
        {
          path: `:id/${ROUTES.addTechMapForm}`,
          element: <AddTechMap/>,
        },
      ],
    },
  ];
};