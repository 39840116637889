import React, { Fragment, useEffect } from "react";
import {
  Form,
  useFormikContext,
} from "formik";
import { useStores } from "@/hooks";

import {
  Alert,
  Box,
  Button,
  Divider,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import {
  IProps,
  PARAMETER_FIELD_NAME,
  TForm,
} from "../const";
import { UnitAutocomplete } from "@/components/Autocompletes";
import FastRegexTextField from "@/components/FastRegexTextField";


/**
 * Форма создания/редактирования
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const ParameterForm: React.FC<IProps> = ({
  modalProps,
}) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<TForm>();

  const title = modalProps.parameterId
    ? "Редактировать запись"
    : "Добавить запись";

    useEffect(() => {
      formik.setFieldError('non_field_errors', undefined)
    }, [formik.values])

  return (
    <Fragment>
      <Box p={3}>
        <Typography>Параметр</Typography>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>
        <Stack direction={"column"} gap={2} p={2}>
          <FastRegexTextField  name={PARAMETER_FIELD_NAME.NAME} label="Название" size="small" fullWidth required/>
          <UnitAutocomplete name={PARAMETER_FIELD_NAME.UNIT} label="Единица измерения" required />
          <Stack direction={"row"} justifyContent={'space-between'}>
          <Box sx={{width:"250px"}}>
          <FastRegexTextField  name={PARAMETER_FIELD_NAME.MIN_PARAMETER_VALUE} fullWidth size="small" label="Минимальное значение" />
          </Box>
          <Box sx={{width:"250px"}}>
          <FastRegexTextField  name={PARAMETER_FIELD_NAME.MAX_PARAMETER_VALUE} fullWidth size="small" label="Максимальное значение" />
          </Box>
          </Stack>
        </Stack>
        <Divider />
        {formik.errors?.non_field_errors &&
          <Alert
            severity="error">
            {formik.errors?.non_field_errors}
          </Alert>
        }
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            color="red"
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type="submit"
            disabled={formik.isSubmitting}
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  );
};

export default ParameterForm;
