import AbstractApi from "@/api/abstract";
import {
  apiInterface,
  reqInterface,
  resInterface
} from "@/api/interfaces";
import {
  IWeatherCultivarFieldWorkReq
} from "@/api/interfaces/requests";
import {
  IPaginatedResult,
  IWeatherCultivarFieldWork
} from "@/api/interfaces/responses";
import { AxiosResponse } from "axios";

class AgriculturalLabourApi implements apiInterface.IAgriculturalLabourApi {
  ctx: AbstractApi;

  constructor(ctx: AbstractApi) {
    this.ctx = ctx;
  }

  getWorkList = async (param: reqInterface.IAgriculturalLabourWorkList) => {
    const res = await this.ctx.get<
      reqInterface.IAgriculturalLabourWorkList,
      IPaginatedResult<resInterface.IAgriculturalLabourWork>
    >(`/agricultural_labour/work/`, param);
    return res.data;
  };

  postWork = async (param: reqInterface.IAgriculturalLabourWorkReq) => {
    const res = await this.ctx.post<
      reqInterface.IAgriculturalLabourWorkReq,
      resInterface.IAgriculturalLabourWork
    >(`/agricultural_labour/work/`, param);
    return res.data;
  };

  patchWorkById = async (id: number | string, param: Partial<reqInterface.IAgriculturalLabourWorkReq>) => {
    const res = await this.ctx.patch<
      Partial<reqInterface.IAgriculturalLabourWorkReq>,
      resInterface.IAgriculturalLabourWork
    >(`/agricultural_labour/work/${id}/`, param);
    return res.data;
  };

  getWorkByID = async (id: number | string) => {
    const res = await this.ctx.get<unknown, resInterface.IAgriculturalLabourWork>(`/agricultural_labour/work/${id}/`);
    return res.data;
  };

  deleteWorkByID = async (id: number | string) => {
    const res = await this.ctx.delete<unknown, AxiosResponse>(
      `/agricultural_labour/work/${id}/`
    );
    return res.data;
  };

  getLocationList = async (
    param: reqInterface.IAgriculturalLabourLocationList
  ) => {
    const res = await this.ctx.get<
      reqInterface.IAgriculturalLabourLocationList,
      IPaginatedResult<resInterface.IAgriculturalLabourLocation>
    >(`/agricultural_labour/location/`, param);
    return res.data;
  };

  getLocationById = async (id: number | string) => {
    const res = await this.ctx.get<
      unknown,
      resInterface.IAgriculturalLabourLocation
    >(`/agricultural_labour/location/${id}/`);
    return res.data;
  };

  deleteLocation = async (id: number | string) => {
    const res = await this.ctx.delete<unknown, AxiosResponse>(
      `/agricultural_labour/location/${id}/`
    );
    return res.data;
  };

  getFieldList = async (param: reqInterface.IAgriculturalLabourFieldList) => {
    const res = await this.ctx.get<
      reqInterface.IAgriculturalLabourFieldList,
      IPaginatedResult<resInterface.IAgriculturalLabourField>
    >(`/agricultural_labour/field/`, param);
    return res.data;
  };

  getAllFIeldFromArea = async (param) => {
    const res = await this.ctx.get<
      reqInterface.IAgriculturalLabourFieldList,
      resInterface.IAgriculturalLabourField[]
    >(`/agricultural_labour/field/get_all_fields/`, param);
    return res.data;
  };

  getAllAreaFromPlot = async (param) => {
    const res = await this.ctx.get<
      reqInterface.IAgriculturalLabourFieldList,
      resInterface.IAgriculturalLabourLocation[]
    >(`/agricultural_labour/location/get_all_location/`, param);
    return res.data;
  };

  getAllSortFromField = async (param) => {
    const res = await this.ctx.get<
      reqInterface.IAgriculturalLabourFieldList,
      resInterface.IAgriculturalLabourCultivarField[]
    >(`/agricultural_labour/cultivar_field/get_all_cultivar_fields/`, param);
    return res.data;
  };

  deleteField = async (id: number | string) => {
    const res = await this.ctx.delete<unknown, AxiosResponse>(
      `/agricultural_labour/field/${id}/`
    );
    return res.data;
  };

  copyFieldById = async (id: number | string) => {
    const res = await this.ctx.post<unknown, any>(
      `/agricultural_labour/field/${id}/copy_obj/`,
      {}
    );
    return res.data;
  };

  getFieldById = async (id: number | string) => {
    const res = await this.ctx.get<
      unknown,
      resInterface.IAgriculturalLabourField
    >(`/agricultural_labour/field/${id}/`);
    return res.data;
  };

  getCultivarFieldList = async (
    param: reqInterface.IAgriculturalLabourCultivarFieldList
  ) => {
    const res = await this.ctx.get<
      reqInterface.IAgriculturalLabourCultivarFieldList,
      IPaginatedResult<resInterface.IAgriculturalLabourCultivarField>
    >(`/agricultural_labour/cultivar_field/`, param);
    return res.data;
  };

  postCultivarField = async (params: reqInterface.ICultivarFieldReq) => {
    const res = await this.ctx.post<reqInterface.ICultivarFieldReq, resInterface.IAgriculturalLabourCultivarField>(
      `/agricultural_labour/cultivar_field/`, params
    );
    return res.data;
  };

  patchCultivarFieldById = async (id: number | string, params: Partial<reqInterface.ICultivarFieldReq>) => {
    const res = await this.ctx.patch<Partial<reqInterface.ICultivarFieldReq>, resInterface.IAgriculturalLabourCultivarField>(
      `/agricultural_labour/cultivar_field/${id}/`, params
    );
    return res.data;
  };
  deleteCultivarFieldById = async (id: number | string) => {
    const res = await this.ctx.delete<unknown, AxiosResponse>(
      `/agricultural_labour/cultivar_field/${id}/`
    );
    return res.data;
  };

  getCultivarFieldById = async (id: number | string) => {
    const res = await this.ctx.get<
      unknown,
      resInterface.IAgriculturalLabourCultivarField
    >(`/agricultural_labour/cultivar_field/${id}/`);
    return res.data;
  };
  createCultivarField = async (
    param: reqInterface.IAgriculturalLabourCreateCultivarField
  ) => {
    const res = await this.ctx.post<
      reqInterface.IAgriculturalLabourCreateCultivarField,
      resInterface.IAgriculturalLabourCultivarField
    >("/agricultural_labour/cultivar_field/", param);
    return res.data;
  };

  updateCultivarField = async (
    id: number | string,
    param: reqInterface.IAgriculturalLabourCreateCultivarField
  ) => {
    const res = await this.ctx.patch<
      reqInterface.IAgriculturalLabourCreateCultivarField,
      resInterface.IAgriculturalLabourCultivarField
    >(`/agricultural_labour/cultivar_field/${id}/`, param);
    return res.data;
  };

  createLocation = async (
    param: reqInterface.IAgriculturalLabourCreateLocation
  ) => {
    const res = await this.ctx.post<
      reqInterface.IAgriculturalLabourCreateLocation,
      resInterface.IAgriculturalLabourLocation
    >("/agricultural_labour/location/", param);
    return res.data;
  };

  updateLocation = async (
    id: number | string,
    param: reqInterface.IAgriculturalLabourCreateLocation
  ) => {
    const res = await this.ctx.patch<
      reqInterface.IAgriculturalLabourCreateLocation,
      resInterface.IAgriculturalLabourLocation
    >(`/agricultural_labour/location/${id}/`, param);
    return res.data;
  };

  createField = async (param: reqInterface.IAgriculturalLabourCreateField) => {
    const res = await this.ctx.post<
      reqInterface.IAgriculturalLabourCreateField,
      resInterface.IAgriculturalLabourField
    >("/agricultural_labour/field/", param);
    return res.data;
  };

  updateField = async (
    id: number | string,
    param: reqInterface.IAgriculturalLabourCreateField
  ) => {
    const res = await this.ctx.patch<
      reqInterface.IAgriculturalLabourCreateField,
      resInterface.IAgriculturalLabourField
    >(`/agricultural_labour/field/${id}/`, param);
    return res.data;
  };

  getSeedArrivalList = async (param: reqInterface.ISeedArrivalList) => {
    const res = await this.ctx.get<reqInterface.ISeedArrivalList, IPaginatedResult<resInterface.ISeedArrivalListItem>>("/agricultural_labour/seed_arrival/", param);
    return res.data;
  };

  deleteSeedArrivalById = async (id: number | string) => {
    const res = await this.ctx.delete<unknown, AxiosResponse>(`/agricultural_labour/seed_arrival/${id}/`);
    return res.data;
  };

  createSeedArrival = async (param: FormData) => {
    const res = await this.ctx.post<FormData, resInterface.ISeedArrival>(`/agricultural_labour/seed_arrival/`, param);
    return res.data;
  };
  updateSeedArrivalById = async (id: number | string, param: FormData) => {
    const res = await this.ctx.patch<FormData, resInterface.ISeedArrival>(`/agricultural_labour/seed_arrival/${id}/`, param);
    return res.data;
  };
  getSeedArrivalById = async (id: number | string) => {
    const res = await this.ctx.get<unknown, resInterface.ISeedArrival>(`/agricultural_labour/seed_arrival/${id}/`);
    return res.data;
  };

  addSeedArrivalFile = async (id: number | string, params: FormData) => {
    const res = await this.ctx.post<FormData, resInterface.ISeedArrivalFile>(`/agricultural_labour/seed_arrival/${id}/add_file/`, params);
    return res.data;
  };

  patchSeedArrivalFileById = async (id: number | string, fileId: number, params: FormData) => {
    const res = await this.ctx.patch<FormData, resInterface.ISeedArrivalFile>(`/agricultural_labour/seed_arrival/${id}/edit_file/${fileId}/`, params);
    return res.data;
  };
  deleteSeedArrivalFileById = async (id: number | string, fileId: number) => {
    const res = await this.ctx.delete<unknown, AxiosResponse>(`/agricultural_labour/seed_arrival/${id}/delete_file/${fileId}/`);
    return res.data;
  };

  getCultivarFieldVarietyList = async (params: reqInterface.ICultivarFieldVarietyListParams) => {
    const res = await this.ctx.get<reqInterface.ICultivarFieldVarietyListParams, IPaginatedResult<resInterface.ICultivarFieldVariety>>(`/agricultural_labour/cultivar_field_variety/`, params);
    return res.data;
  };
  getCultivarFieldVarietyById = async (id: number | string) => {
    const res = await this.ctx.get<unknown, resInterface.ICultivarFieldVariety>(`/agricultural_labour/cultivar_field_variety/${id}/`);
    return res.data;
  };

  deleteCultivarFieldVarietyById = async (id: number) => {
    const res = await this.ctx.delete<unknown, AxiosResponse>(`/agricultural_labour/cultivar_field_variety/${id}/`);
    return res.data;
  };
  patchCultivarFieldVarietyById = async (id: number | string, params: Partial<reqInterface.ICultivarFieldVarietyReq>) => {
    const res = await this.ctx.patch<Partial<reqInterface.ICultivarFieldVarietyReq>, resInterface.ICultivarFieldVariety>(`/agricultural_labour/cultivar_field_variety/${id}/`, params);
    return res.data;
  };

  postCultivarFieldVariety = async (params: Partial<reqInterface.ICultivarFieldVarietyReq>) => {
    const res = await this.ctx.post<Partial<reqInterface.ICultivarFieldVarietyReq>, resInterface.ICultivarFieldVariety>(`/agricultural_labour/cultivar_field_variety/`, params);
    return res.data;
  };

  generateCultivarFieldVariety = async (params: reqInterface.IGenerateCultivarFieldVarietyReq) => {
    const res = await this.ctx.post<reqInterface.IGenerateCultivarFieldVarietyReq, resInterface.ICultivarFieldVariety>(`/agricultural_labour/cultivar_field_variety/generate/`, params);
    return res.data;
  };

  randomizeCultivarFieldVariety = async (id: number) => {
    const res = await this.ctx.post<unknown, AxiosResponse>(`/agricultural_labour/cultivar_field/${id}/randomize/`, {});
    return res.data;
  };
  getCultivarFieldWorkList = async (params: reqInterface.ICultivarFieldVarietyWorkParams) => {
    const res = await this.ctx.get<reqInterface.ICultivarFieldVarietyWorkParams, IPaginatedResult<resInterface.ICultivarFieldWork>>(`/agricultural_labour/cultivar_field_work/`, params);
    return res.data;
  };

  deleteCultivarFieldWorkById = async (id: number, cultivarFieldId: number) => {
    const res = await this.ctx.delete<unknown, AxiosResponse>(`/agricultural_labour/cultivar_field_work/${id}/`, { cultivar_field: cultivarFieldId });
    return res.data;
  };

  getCultivarFieldWorkById = async (id: number | string) => {
    const res = await this.ctx.get<unknown, resInterface.ICultivarFieldWork>(`/agricultural_labour/cultivar_field_work/${id}/`);
    return res.data;
  };

  getCultivarFieldWorkChemistryList = async (params: reqInterface.ICultivarFieldVarietyWorkChemistryParams) => {
    const res = await this.ctx.get<reqInterface.ICultivarFieldVarietyWorkChemistryParams, IPaginatedResult<resInterface.ICultivarFieldChemistry>>(`/agricultural_labour/cultivar_field_work_chemistry/`, params);
    return res.data;
  };


  postCultivarFieldWorkChemistry = async (params: reqInterface.ICultivarFieldChemistryReq) => {
    const res = await this.ctx.post<reqInterface.ICultivarFieldChemistryReq, resInterface.ICultivarFieldChemistry>(`/agricultural_labour/cultivar_field_work_chemistry/`, params);
    return res.data;
  };

  patchCultivarFieldWorkChemistry = async (id: number | string, params: Partial<reqInterface.ICultivarFieldChemistryReq>) => {
    const res = await this.ctx.patch<Partial<reqInterface.ICultivarFieldChemistryReq>, resInterface.ICultivarFieldChemistry>(`/agricultural_labour/cultivar_field_work_chemistry/${id}/`, params);
    return res.data;
  };

  deleteCultivarFieldWorkChemistry = async (id: number | string, params: Partial<reqInterface.ICultivarFieldChemistryReq>) => {
    const res = await this.ctx.delete<Partial<reqInterface.ICultivarFieldChemistryReq>, resInterface.ICultivarFieldChemistry>(`/agricultural_labour/cultivar_field_work_chemistry/${id}/`, params);
    return res.data;
  };

  getTechMapPlanList = async (params: reqInterface.ITechMapPlanListReq) => {
    const res = await this.ctx.get<reqInterface.ITechMapPlanListReq, IPaginatedResult<resInterface.ITechMapPlan>>(`/agricultural_labour/tech_map_plan/`, params);
    return res.data;
  };

  getTechMapPlanById = async (id: number | string) => {
    const res = await this.ctx.get<unknown, resInterface.ITechMapPlan>(`/agricultural_labour/tech_map_plan/${id}/`);
    return res.data;
  };

  postTechMapPlan = async (params: reqInterface.ITechMapPlanReq) => {
    const res = await this.ctx.post<reqInterface.ITechMapPlanReq, resInterface.ITechMapPlan>(
      `/agricultural_labour/tech_map_plan/`, params
    );
    return res.data;
  };

  patchTechMapPlanById = async (id: number | string, params: Partial<reqInterface.ITechMapPlanReq>) => {
    const res = await this.ctx.patch<Partial<reqInterface.ITechMapPlanReq>, resInterface.ITechMapPlan>(
      `/agricultural_labour/tech_map_plan/${id}/`, params
    );
    return res.data;
  };

  deleteTechMapPlanById = async (id: number | string) => {
    const res = await this.ctx.delete<unknown, AxiosResponse>(
      `/agricultural_labour/tech_map_plan/${id}/`
    );
    return res.data;
  };
  
  postCultivarFieldWork = async (params: reqInterface.ICultivarFieldWorkReq) => {
    const res = await this.ctx.post<reqInterface.ICultivarFieldWorkReq, resInterface.ICultivarFieldWork>(`/agricultural_labour/cultivar_field_work/`, params);
    return res.data;
  };

  patchCultivarFieldWork = async (id: number | string, params: Partial<reqInterface.ICultivarFieldWorkReq>) => {
    const res = await this.ctx.patch<reqInterface.ICultivarFieldWorkReq, resInterface.ICultivarFieldWork>(`/agricultural_labour/cultivar_field_work/${id}/`, params);
    return res.data;
  };

  getObservationList = async (params: reqInterface.IObservationListParams) => {
    const res = await this.ctx.get<reqInterface.IObservationListParams, IPaginatedResult<resInterface.IObservation>>(`/agricultural_labour/observation/`, params);
    return res.data;
  };

  getObservationById = async (id: number | string) => {
    const res = await this.ctx.get<unknown, resInterface.IObservation>(`/agricultural_labour/observation/${id}/`);
    return res.data;
  };

  postObservation = async (params: reqInterface.IObservationReq | FormData) => {
    const res = await this.ctx.post<reqInterface.IObservationReq, resInterface.IObservation>(`/agricultural_labour/observation/`, params);
    return res.data;
  };

  patchObservationById = async (id: number | string, params: Partial<reqInterface.IObservationReq> | FormData) => {
    const res = await this.ctx.patch<Partial<reqInterface.IObservationReq>, resInterface.IObservation>(`/agricultural_labour/observation/${id}/`, params);
    return res.data;
  };

  deleteObservationById = async (id: number | string) => {
    const res = await this.ctx.delete<unknown, AxiosResponse>(`/agricultural_labour/observation/${id}/`);
    return res.data;
  };

  getLocationMainById = async (id: number | string) => {
    const res = await this.ctx.get<unknown, resInterface.ILocationMain>(`/agricultural_labour/location/main/${id}/`)
    return res.data
  }

  getLocationMainTrialsById = async (id: number | string, params: reqInterface.IBranchMainReq) => {
    const res = await this.ctx.get<reqInterface.IBranchMainReq, resInterface.IBranchMainTrials>(`/agricultural_labour/location/main_trials/${id}/`, params)
    return res.data
  }

  getLocationMainProductionById = async (id: number | string, params: reqInterface.IBranchMainReq) => {
    const res = await this.ctx.get<reqInterface.IBranchMainReq, resInterface.IBranchMainProduction>(`/agricultural_labour/location/main_production/${id}/`, params)
    return res.data
  }

  addObservationFileById = async (observationId: number | string, payload: FormData) => {
    const res = await this.ctx.post<FormData,
      resInterface.IStatementFile>(`/agricultural_labour/observation/${observationId}/add_file/`, payload)
    return res.data
  }

  deleteObservationFileById = async (
    observationId: string | number,
    fileId: string | number,
  ) => {
    const res = await this.ctx.delete<unknown, AxiosResponse>(
      `/agricultural_labour/observation/${observationId}/delete_file/${fileId}/`,
    );
    return res.data
  };

  getObservationIndicatorList = async (params: reqInterface.IObservationIndicatorReqList) => {
    const res = await this.ctx.get<reqInterface.IObservationIndicatorReqList, IPaginatedResult<resInterface.IObservationIndicator>>("/agricultural_labour/observation_indicator/", params)
    return res.data
  }

  postObservationIndicator = async (params: reqInterface.IObservationIndicatorReq) => {
    const res = await this.ctx.post<reqInterface.IObservationIndicatorReq, AxiosResponse>("/agricultural_labour/observation_indicator/", params)
    return res.data
  }

  patchObservationIndicator = async (id: number | string, params: Partial<reqInterface.IObservationIndicatorReq>) => {
    const res = await this.ctx.patch<Partial<reqInterface.IObservationIndicatorReq>, AxiosResponse>(`/agricultural_labour/observation_indicator/${id}/`, params)
    return res.data
  }

  deleteObservationIndicatorById = async (id: string | number) => {
    const res = await this.ctx.delete<unknown, AxiosResponse>(`/agricultural_labour/observation_indicator/${id}/`)
    return res.data
  }

  getObservationDiseaseList = async (params: reqInterface.IObservationIndicatorReqList) => {
    const res = await this.ctx.get<reqInterface.IObservationIndicatorReqList, IPaginatedResult<resInterface.IObservationDisease>>("/agricultural_labour/observation_disease/", params)
    return res.data
  }

  postObservationDisease = async (params: reqInterface.IObservationDiseaseReq) => {
    const res = await this.ctx.post<reqInterface.IObservationDiseaseReq, AxiosResponse>("/agricultural_labour/observation_disease/", params)
    return res.data
  }

  patchObservationDisease = async (id: number | string, params: Partial<reqInterface.IObservationDiseaseReq>) => {
    const res = await this.ctx.patch<Partial<reqInterface.IObservationDiseaseReq>, AxiosResponse>(`/agricultural_labour/observation_disease/${id}/`, params)
    return res.data
  }

  deleteObservationDiseaseById = async (id: string | number) => {
    const res = await this.ctx.delete<unknown, AxiosResponse>(`/agricultural_labour/observation_disease/${id}/`)
    return res.data
  }

  getObservationWeatherResistanceList = async (params: reqInterface.IObservationIndicatorReqList) => {
    const res = await this.ctx.get<reqInterface.IObservationIndicatorReqList, IPaginatedResult<resInterface.IObservationWeatherResistance>>("/agricultural_labour/observation_weather_resistance/", params)
    return res.data
  }

  postObservationWeatherResistance = async (params: reqInterface.IObservationWeatherResistanceReq) => {
    const res = await this.ctx.post<reqInterface.IObservationWeatherResistanceReq, AxiosResponse>("/agricultural_labour/observation_weather_resistance/", params)
    return res.data
  }

  patchObservationWeatherResistance = async (id: number | string, params: Partial<reqInterface.IObservationWeatherResistanceReq>) => {
    const res = await this.ctx.patch<Partial<reqInterface.IObservationWeatherResistanceReq>, AxiosResponse>(`/agricultural_labour/observation_weather_resistance/${id}/`, params)
    return res.data
  }

  deleteObservationWeatherResistanceById = async (id: string | number) => {
    const res = await this.ctx.delete<unknown, AxiosResponse>(`/agricultural_labour/observation_weather_resistance/${id}/`)
    return res.data
  }
  getProductivityList = async (params: reqInterface.IProductivityReqList) => {
    const res =  await this.ctx.get<reqInterface.IProductivityReqList, IPaginatedResult<resInterface.IProductivity>>("/agricultural_labour/productivity/", params)
    return res.data
  }
  getProductivityById= async (id: number | string) => {
    const res =  await this.ctx.get<unknown, resInterface.IProductivity>(`/agricultural_labour/productivity/${id}/`)
    return res.data
  }
  postProductivity = async (params: reqInterface.IProductivityReq) => {
    const res =  await this.ctx.post<reqInterface.IProductivityReq, resInterface.IProductivity>("/agricultural_labour/productivity/", params)
    return res.data
  }
  patchProductivityById = async (id: number | string, params: Partial<reqInterface.IProductivityReq>) => {
    const res =  await this.ctx.patch<Partial<reqInterface.IProductivityReq>, resInterface.IProductivity>(`/agricultural_labour/productivity/${id}/`, params)
    return res.data
  }
  deleteProductivityById = async (id: number | string) => {
    const res =  await this.ctx.delete<unknown, AxiosResponse>(`/agricultural_labour/productivity/${id}/`)
    return res.data
  }

  getLabRateList = async (params: reqInterface.ILabRateListParams) => {
    const res =  await this.ctx.get<reqInterface.ILabRateListParams, IPaginatedResult<resInterface.ILabRate>>("/agricultural_labour/cultivar_field_variety_rate/", params)
    return res.data
  }

  getLabRateById = async (id: number | string) => {
    const res =  await this.ctx.get<unknown, resInterface.ILabRate>(`/agricultural_labour/cultivar_field_variety_rate/${id}/`)
    return res.data
  }

  postLabRate = async (params: reqInterface.ILabRateReq) => {
    const res =  await this.ctx.post<reqInterface.ILabRateReq, resInterface.ILabRate>("/agricultural_labour/cultivar_field_variety_rate/", params)
    return res.data
  }

  patchLabRateById = async (id: number | string ,params: Partial<reqInterface.ILabRateReq>) => {
    const res =  await this.ctx.patch<Partial<reqInterface.ILabRateReq>, resInterface.ILabRate>(`/agricultural_labour/cultivar_field_variety_rate/${id}/`, params)
    return res.data
  }

  deleteLabRateById = async (id: number | string) => {
    const res =  await this.ctx.delete<unknown, AxiosResponse>(`/agricultural_labour/cultivar_field_variety_rate/${id}/`)
    return res.data
  }

  getWeatherForecastById = async (id: number | string) => {
    const res = await this.ctx.get<unknown, resInterface.IGetWeatherForecast>(`/agricultural_labour/location/${id}/get_weather_forecast/`);
    return res.data;
  };
  getContextTimePeriodWeatherCalculations = async (id: number | string,start_date: string | number, end_date: string | number) => {
    const res = await this.ctx.get<unknown, resInterface.ITimePeriodWeatherCalculations>(`/agricultural_labour/location/${id}/get_context_time_period_weather_calculations/?start_date=${start_date}&end_date=${end_date}`);
    return res.data;
  };
  getWeatherHistoryOneDay = async (id: number | string,date: string | number,) => {
    const res = await this.ctx.get<unknown, resInterface.IWeatherHistoryOneDay>(`/agricultural_labour/location/${id}/get_context_date_location_weather_history_entry/?date=${date}`);
    return res.data;
  };
  getWeatherHistoryForForm = async (id: number | string,date: string | number,) => {
    const res = await this.ctx.get<unknown, resInterface.IWeatherHistoryForForm>(`/agricultural_labour/location/${id}/get_context_date_location_weather_history/?date=${date}`);
    return res.data;
  };
  getWeatherHistoryYear = async (id: number | string,year: string | number,) => {
    const res = await this.ctx.get<unknown, resInterface.IWeatherHistoryYear>(`/agricultural_labour/location/${id}/get_context_year_location_weather_history_entry/?year=${year}`);
    return res.data;
  };
  getWeatherCultivarFieldWork = async(params) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IWeatherCultivarFieldWork>
    >(`/agricultural_labour/weather_cultivar_field_work`, params);

    return res.data;
  };

  getWeatherCultivarFieldWorkById = async (id: number | string) => {
    const res = await this.ctx.get<reqInterface.IList, resInterface.IWeatherCultivarFieldWork>(
      `/agricultural_labour/weather_cultivar_field_work/${id}/`
    );
    return res.data;
  };
  
  postWeatherCultivarFieldWork = async (params) => {
    const res = await this.ctx.post<
      reqInterface.IWeatherCultivarFieldWorkReq,
      resInterface.IWeatherCultivarFieldWork
    >(`/agricultural_labour/weather_cultivar_field_work/`, params);

    return res.data;
  };
  
  patchWeatherCultivarFieldWorkById = async (id: number | string, params: IWeatherCultivarFieldWorkReq) => {
    const res = await this.ctx.patch<
    reqInterface.IWeatherCultivarFieldWorkReq,
    IWeatherCultivarFieldWork
    >(`/agricultural_labour/weather_cultivar_field_work/${id}/`, params);
    return res.data;
  };
  deleteWeatherCultivarFieldWorkById = async (id: number | string) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IWeatherCultivarFieldWork>
    >(`/agricultural_labour/weather_cultivar_field_work/${id}/`);
    return res.data;
  };
  
  downloadSowingReportHp = async (params: reqInterface.DownloadCultivarFieldSowingReportReq) => {
    const res = await this.ctx.post<reqInterface.DownloadCultivarFieldSowingReportReq, Blob>("/agricultural_labour/cultivar_field_variety/download_sowing_report_hp/", params, {
      responseType: "blob"
    })
    return res.data
  }
  downloadSowingReportOos = async (params: reqInterface.DownloadCultivarFieldSowingReportReq) => {
    const res = await this.ctx.post<reqInterface.DownloadCultivarFieldSowingReportReq, Blob>("/agricultural_labour/cultivar_field_variety/download_sowing_report_oos/", params, {
      responseType: "blob"
    })
    return res.data
  }
  downloadSowingReportPhyto = async (params: reqInterface.DownloadCultivarFieldSowingReportReq) => {
    const res = await this.ctx.post<reqInterface.DownloadCultivarFieldSowingReportReq, Blob>("/agricultural_labour/cultivar_field_variety/download_sowing_report_phyto/", params, {
      responseType: "blob"
    })
    return res.data
  }

  downloadVarietyMetrics = async (params: reqInterface.DownloadCultivarFieldSowingReportReq) => {
    const res = await this.ctx.post<reqInterface.DownloadCultivarFieldSowingReportReq, Blob>("/agricultural_labour/cultivar_field/download_varieties_metrics/", params, {
      responseType: "blob"
    })
    return res.data
  }
  
  downloadResultsSummary = async (params: reqInterface.DownloadCultivarFieldSowingReportReq) => {
    const res = await this.ctx.post<reqInterface.DownloadCultivarFieldSowingReportReq, Blob>("/agricultural_labour/cultivar_field_variety/download_results_summary/", params, {
      responseType: "blob"
    })
    return res.data
  }

  downloadSeedArrivalJournal = async (plotId: number | string, params: reqInterface.DownloadSeedArrivalReq) => {
    const res = await this.ctx.post<reqInterface.DownloadSeedArrivalReq, Blob>(`/agricultural_labour/seed_arrival/download_seed_arrival_journal/${plotId}/`, params, {
      responseType: "blob"
    })
    return res.data
  }

  downloadSeedArrival = async (plotId: number | string, params: reqInterface.DownloadSeedArrivalReq) => {
    const res = await this.ctx.post<reqInterface.DownloadSeedArrivalReq, Blob>(`/agricultural_labour/seed_arrival/download_seed_arrival/${plotId}/`, params, {
      responseType: "blob"
    })
    return res.data
  }
  
  getActiveEffectiveTemp = async (id: number | string, start_date: number | string, end_date: number | string, active_temp_context: number | string, base_temp: number | string ) => {
    const res =  await this.ctx.get<unknown, resInterface.IActiveEffectiveTemp>(`/agricultural_labour/cultivar_field/${id}/get_context_active_effective_temperatures/?start_date=${start_date}&end_date=${end_date}&active_temp_context=${active_temp_context}&base_temp=${base_temp}`)
    return res.data
  }
}

export default AgriculturalLabourApi;
