import React, { useEffect } from "react";
import qs from "qs";
import { AxiosError } from "axios";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";
import {
  ICultivarFieldVariety
} from "@/api/interfaces/responses";
import {
  StyledMenuItem
} from "@/components/StyledMenuItem";
import { buttonDotsStyle } from "@/apps/Table/style";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DeleteIcon } from "@/components/icons";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import {
  CELL_TYPES
} from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import {
  useQuery,
  useStores,
  useRightSidebar
} from "@/hooks";
import {
  SWR_KEYS,
  STATUSES_CULTIVAR_FIELD_VARIETY_KEYS
} from "@/const";
import CultivarFieldVarietyStatusChip
  from "@/components/Chips/CultivarFieldVarietyStatusChip";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

/**
 * @component BranchCells
 * * компонент ячейки таблицы Филиала
 * @param
 */

export const BranchCells = (
  _: string,
  cell: ICultivarFieldVariety,
): JSX.Element => {
  return (
    <Box>
      <Typography variant="body2">
        {cell.plan?.branch?.name || "-"}
      </Typography>
    </Box>
  );
};

/**
 * @component StatusCells
 * * компонент ячейки таблицы статуса
 * @param
 */

export const StatusCells = (
  _: string,
  cell: ICultivarFieldVariety,
): JSX.Element => {

  return (
    <CultivarFieldVarietyStatusChip status={cell.status}/>
  );
};

/**
 * @component YearCells
 * * компонент ячейки таблицы года испытания
 * @param
 */

export const YearCells = (
  _: string,
  cell: ICultivarFieldVariety,
): JSX.Element => {
  return (
    <Box>
      <Typography variant="body2">
        {cell.trial ? cell.trial.year_of_testing : "-"}
      </Typography>
    </Box>
  );
};

/**
 * @component CultivarCells
 * * компонент ячейки таблицы наименование культуры
 * @param
 */

export const CultivarCells = (
  _: string,
  cell: ICultivarFieldVariety,
): JSX.Element => {
  return (
    <Box>
      <Typography variant="body2">
        {cell.plan?.cultivar?.name || "-"}
      </Typography>
    </Box>
  );
};

/**
 * @component VarietyCells
 * * компонент ячейки таблицы Сорта
 * @param
 */

export const VarietyCells = (
  _: string,
  cell: ICultivarFieldVariety,
): JSX.Element => {
  return (
    <Box>
      <Typography variant="body2">
        {cell.variety?.name || "-"}
      </Typography>
    </Box>
  );
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCells = (
  _: string,
  cultivarFieldVariety: ICultivarFieldVariety,
  itemsInPage: any[],
): JSX.Element => {
  const { api, modalStore, swrStore, userStore } = useStores();
  const [anchorEl, setAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const query = useQuery<any>();
  const {
    sidebarProps,
    handleCloseInstantly
  } = useRightSidebar();
  const [, setSearchParams] = useSearchParams();

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: "true" },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);

  const mutateTable =
    swrStore.mutators[SWR_KEYS.getCultivarFieldVarietyLabList()];

  const mutateWithCheckingLast = () => {
    // если один эл-т на странице перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateTable && mutateTable();
    }
  };

  const handleDelete = (
    resolve: () => void,
    reject: (error: AxiosError) => void,
  ) => {
    api.agriculturalLabour
      .deleteCultivarFieldVarietyById(cultivarFieldVariety.id)
      .then(() => {
        if (cultivarFieldVariety.id == sidebarProps.id) {
          setSearchParams(undefined);
          handleCloseInstantly();
        }
        mutateWithCheckingLast();
        resolve();
      })
      .catch(reject)
      .finally(() => setAnchorEl(null));
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.universal,
      id: cultivarFieldVariety.id,
      entityName: "лабораторный опыт",
      handleDelete: handleDelete,
    };

    modalStore.open(
      VARIANT_MODAL.OK_OR_NOT_DELETE,
      modalProp,
    );
  };


  const canDeleteLab = userStore.isPermission(
    PERMISSIONS.agricultural_labourLabCultivarFieldVariety,
    PERMISSION_CRUD.delete
  );


  if(cultivarFieldVariety.status === STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.COMPLETED || cultivarFieldVariety.status === STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.NOT_COMPLETED || !canDeleteLab){
    return <></>
  }

  return (
    <Box>
      <IconButton
        sx={buttonDotsStyle()}
        onClick={handleToggle}
      >
        <MoreVertIcon/>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={handleClickDelete}>
          <DeleteIcon color="error"/>
          <Typography color="error">
            Удалить
          </Typography>
        </StyledMenuItem>
      </Menu>
    </Box>
  );
};
