import dayjs from "dayjs";
import yup from "@/utils/yup";
import { v4 as uuidv4 } from "uuid";
import {
  IFile,
  STATUSES
} from "@/components/FileDropzone/const";
import {
  IStatementFile,
  IEntity,
  IObservation
} from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  OBSERVATION_DATE: "observation_date",
  VEGETATION_STAGE: "vegetation_stage",
  ALLOCATION_NUMBER: "allocation_number",
  INDICATORS: "indicators",
  INDICATOR_VARIANT: "indicator_variant",
  INDICATOR: "indicator",
  INDICATOR_VALUE: "indicator_value",
  FILE_LIST: "file_list",
  DISEASES: "diseases",
  DISEASE: "disease",
  DAMAGE: "damage",
  WEATHER_CONDITION: "weather_condition",
  RATING: "rating",
  WEATHER_CONDITIONS: "weather_resistances",
  UNIT: "unit",
  OFF_TYPE_PLANTS_NUMBER: "off_type_plants_number",
  UNIFORMITY: "uniformity",
} as const;

export const initialValues = {
  [FIELDS_NAME.OBSERVATION_DATE]: null,
  [FIELDS_NAME.VEGETATION_STAGE]: null,
  [FIELDS_NAME.ALLOCATION_NUMBER]: 1,
  [FIELDS_NAME.INDICATORS]: [],
  [FIELDS_NAME.FILE_LIST]: [],
  [FIELDS_NAME.DISEASES]: [],
  [FIELDS_NAME.WEATHER_CONDITIONS]: [],
  [FIELDS_NAME.OFF_TYPE_PLANTS_NUMBER]: undefined,
  [FIELDS_NAME.UNIFORMITY]: undefined,
};

export interface IValue {
  id: number;
  label: string;
}

export interface IFormik {
  [FIELDS_NAME.OBSERVATION_DATE]: null;
  [FIELDS_NAME.VEGETATION_STAGE]: IValue | null;
  [FIELDS_NAME.ALLOCATION_NUMBER]: number;
  [FIELDS_NAME.INDICATORS]: {
    indicator: IValue | null,
    indicator_variant?: IValue | null,
    indicator_value?: string | null,
    off_type_plants_number?: number, 
    uniformity?: boolean,
  }[];
  [FIELDS_NAME.FILE_LIST]: IFile[];
  [FIELDS_NAME.DISEASES]: {
    disease: IValue | null,
    unit: IValue | null,
    damage: string
  }[];
  [FIELDS_NAME.WEATHER_CONDITIONS]: {
    weather_condition: IEntity | null,
    rating: number
  }[];
}

export const dataValidationSchema = yup.object().shape({
  [FIELDS_NAME.OBSERVATION_DATE]: yup.mixed().required(),
  [FIELDS_NAME.VEGETATION_STAGE]: yup.mixed().required()
});

export const indicatorsValidationSchema = yup.object().shape({
  [FIELDS_NAME.INDICATORS]: yup.array().of(yup.object().shape({
  [FIELDS_NAME.INDICATOR]: yup.mixed().required(),
  [FIELDS_NAME.INDICATOR_VARIANT]: yup.mixed().required(),
  [FIELDS_NAME.OFF_TYPE_PLANTS_NUMBER]: yup.number().required(),
  }))
});
export const indicatorsValidationSchemaHp = yup.object().shape({
  [FIELDS_NAME.INDICATORS]: yup.array().of(yup.object().shape({
  [FIELDS_NAME.INDICATOR]: yup.mixed().required(),
  [FIELDS_NAME.INDICATOR_VALUE]: yup.number().test(
    'indicator-value',
    'Значение должно соответствовать выбранному параметру',
    function(value) {
      const { parent } = this;
      
      const indicator = parent[FIELDS_NAME.INDICATOR];
      
      if (!indicator || !value) {
        return false; 
      }

      if ((indicator.min_parameter_value !== undefined && value < indicator.min_parameter_value) ||
      (indicator.max_parameter_value !== undefined && value > indicator.max_parameter_value)) {
        return this.createError({
          message: `Значение должно быть между ${indicator.min_parameter_value} и ${indicator.max_parameter_value}`
        });
      }
      if ((indicator.min_parameter_value === null) || (indicator.max_parameter_value === null)) {
        return true
      }
      
      return true;
    }
  ),
  }))
});

export const weatherConditionsValidationSchema = yup.object().shape({
  [FIELDS_NAME.WEATHER_CONDITIONS]: yup.array().of(yup.object().shape({
    [FIELDS_NAME.WEATHER_CONDITION]: yup.mixed().required(),
    [FIELDS_NAME.RATING]: yup.number().required()
  }))
});

export const diseasesValidationSchema = yup.object().shape({
  [FIELDS_NAME.DISEASES]: yup.array().of(yup.object().shape({
    [FIELDS_NAME.DISEASE]: yup.mixed().required(),
    [FIELDS_NAME.DAMAGE]: yup.number().required(),
    [FIELDS_NAME.UNIT]: yup.mixed().required()
  }))
});

export const serializeFormToValue = (values: IFormik) => {
  return (
    {
      observation_date: values.observation_date ? dayjs(values.observation_date).format("YYYY-MM-DD") : undefined,
      allocation_number: values.allocation_number,
      vegetation_stage: values.vegetation_stage?.id,
      diseases: Array.isArray(values.diseases) && values.diseases.length ? values.diseases.map(el => ({
        disease: el.disease?.id as number,
        unit: el.unit?.id as number,
        damage: el.damage
      })) : undefined,
      indicators: Array.isArray(values.indicators) && values.indicators.length ? values.indicators.map(el => el.indicator_variant?.id as number) : undefined,
      off_type_plants_number: Array.isArray(values.indicators) && values.indicators.length ? values.indicators.map(el => el.off_type_plants_number) : undefined,
      uniformity: Array.isArray(values.indicators) && values.indicators.length ? values.indicators.map(el => el.uniformity) : undefined,
      indicator_value: Array.isArray(values.indicators) && values.indicators.length ? values.indicators.map(el => el.indicator_value) : undefined,
      indicator: Array.isArray(values.indicators) && values.indicators.length ? values.indicators.map(el => el.indicator?.id) : undefined,
      weather_resistances: Array.isArray(values.weather_resistances) && values.weather_resistances.length ? values.weather_resistances.map(el => ({
        weather_condition: el.weather_condition?.id as number,
        rating: el.rating
      })) : undefined,
      file_list: values.file_list ? values.file_list.filter(el => !el.id) : [],
    }
  );
};

export const serializeValueToForm = (values: IObservation) => {
  return {
    [FIELDS_NAME.OBSERVATION_DATE]: values.observation_date ? dayjs(values.observation_date) : null,
    [FIELDS_NAME.ALLOCATION_NUMBER]: values.allocation_number,
    [FIELDS_NAME.VEGETATION_STAGE]: values.vegetation_stage ? {
      id: values.vegetation_stage.id,
      label: values.vegetation_stage.name
    } : null,
    [FIELDS_NAME.FILE_LIST]: values.files.map(serializeStatementFile)
  };
};

export const serializeStatementFile = (statementFile: IStatementFile) => {
  return {
    uuid: uuidv4(),
    id: statementFile.id,
    name: statementFile.file_name,
    formatSize: statementFile.file_size,
    preview: statementFile.file_url,
    status: STATUSES.SUCCESS
  };
};