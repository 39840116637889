import React, { useEffect } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Box,
  Divider,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";
import { EditIcon, DeleteIcon, } from "@/components/icons";
import { buttonDotsStyle } from "../../style";
import {
  StyledMenuItem
} from "@/components/StyledMenuItem";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import { useQuery, useStores } from "@/hooks";
import {
  CELL_TYPES
} from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { resInterface } from "@/api/interfaces";
import theme from "@/theme";
import { SWR_KEYS } from "@/const";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  delete: "Удалить",
};

export const NumberCell = (
  _: string,
  cell: resInterface.IDuty,
): JSX.Element => {
  const { number } = cell;
  return (
    <Box>
      <Typography fontSize={14} color={theme.palette.blackAndWhite.gray}>
        {number ?? "-"}
      </Typography>
    </Box>
  );
};

export const NameCell = (
  _: string,
  cell: resInterface.IDuty,
): JSX.Element => {
  const { name } = cell;
  return (
    <Box>
      <Typography fontSize={14}>
        {name ?? "-"}
      </Typography>
    </Box>
  );
};

export const ResidentCell = (
  _: string,
  cell: resInterface.IDuty,
): JSX.Element => {
  const { resident } = cell;
  return (
    <Box>
      <Typography fontSize={14}>
        {resident ?? "-"}
      </Typography>
    </Box>
  );
};

export const NonResidentCell = (
  _: string,
  cell: resInterface.IDuty,
): JSX.Element => {
  const { non_resident } = cell;
  return (
    <Box>
      <Typography fontSize={14}>
        {non_resident ?? "-"}
      </Typography>
    </Box>
  );
};

/**
 * @component EditingCell
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCell = (
  _: string,
  cell: resInterface.IDuty,
  itemsInPage: any[],
): JSX.Element => {
  const { api, modalStore, swrStore,userStore } = useStores();
  const [anchorEl, setAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: "true" },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickEdit = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    modalStore.open(VARIANT_MODAL.ADD_DUTY, {
      theme: THEME_MODAL.W_555,
      initialValues: {
        ...cell,
        cultivar: cell.cultivars
      },
      id: cell.id,
    });
    setAnchorEl(null);
  };

  const mutateList =
    swrStore.mutators[
      SWR_KEYS.getDutyList()
      ];

  const deleteWithCheckingLast = () => {
    // если один эл-т на странице перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateList && mutateList();
    }
  };

  const handleDelete = (resolve, reject) => {
    api.statement
      .deleteDutyById(cell.id)
      .then(() => {
        deleteWithCheckingLast();
        resolve();
      })
      .catch(reject);
    setAnchorEl(null);
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.handbook,
      id: cell.id,
      handleDelete: handleDelete,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(
      VARIANT_MODAL.OK_OR_NOT_DELETE,
      modalProp,
    );
  };

  const canEditDuty= userStore.isPermission(
    PERMISSIONS.registrationDuty,
    PERMISSION_CRUD.change
  )
  const canDeleteDuty = userStore.isPermission(
    PERMISSIONS.registrationDuty,
    PERMISSION_CRUD.delete
  )

  if (!canEditDuty && !canDeleteDuty) {
    return <></>
  }

  return (
    <Box>
      <IconButton
        sx={buttonDotsStyle()}
        onClick={handleToggle}
      >
        <MoreVertIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {canEditDuty && 
        <StyledMenuItem onClick={handleClickEdit}>
          <EditIcon/>
          {EDITING_CELL.editing}
        </StyledMenuItem>
        }
        {canEditDuty && canDeleteDuty && 
        <Divider/>
        }
        {canDeleteDuty &&  
        <StyledMenuItem onClick={handleClickDelete}>
          <DeleteIcon color={"error"}/>
          <Typography color="error">
            {EDITING_CELL.delete}
          </Typography>
        </StyledMenuItem>
        }
      </Menu>
    </Box>
  );
};
