import React from "react";
import { IIcon } from "./interfaces";

const FileIcon = ({ className }: IIcon): React.ReactElement => {
  return (
    <svg 
      className={className} 
      xmlns="http://www.w3.org/2000/svg" 
      width="20.000000" 
      height="20.000000" 
      viewBox="0 0 20 20" 
      fill="currentColor">
        <path 
          id="draft" 
          d="M5 18.33C4.54 18.33 4.14 18.17 3.82 17.84C3.49 17.51 3.33 17.12 3.33 16.66L3.33 3.33C3.33 2.87 3.49 2.48 3.82 2.15C4.14 1.82 4.54 1.66 5 1.66L11.66 1.66L16.66 6.66L16.66 16.66C16.66 17.12 16.5 17.51 16.17 17.84C15.85 18.17 15.45 18.33 15 18.33L5 18.33ZM10.83 7.5L10.83 3.33L5 3.33L5 16.66L15 16.66L15 7.5L10.83 7.5Z" 
          fill="currentColor" 
          fillOpacity="1.000000" 
          fillRule="nonzero" />
    </svg>

  );
};

export default FileIcon;
