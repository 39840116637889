import React, { useLayoutEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { AxiosError } from "axios";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { Box, CircularProgress } from "@mui/material";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { useStores } from "@/hooks";
import {
  isUndefined,
  errorToString,
  pickFrom
} from "@/utils/helpers";
import {
  KEYS_SIDEBAR_VARIANT_MODULES,  
  SWR_KEYS,
  TRIAL_TYPE
} from "@/const";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import {
  initialValues,
  validationSchema,
  formToValue,
  IFormik,
  valueToForm,
  TRIAL_TYPE_EXPERT_ASSESSMENT,
} from "@/pages/AddExpertAssessment/const";
import { TOAST_TYPES } from "@/apps/Toast";
import FormExpertAssessment
  from "@/pages/AddExpertAssessment/components/FormExpertAssessement";
import {
  QUERY_STRING_MICRO_ROUTS_EXPERT_ASSESSMENT
} from "@/apps/RightSidebar/const";

/**
 * Страница добавления плана экспертных оценок
 * */
const AddExpertAssessmentPage = () => {
  const { id } = useParams();
  const {
    api,
    headerStore,
    queryStringSidebarCollector,
    toastStore,
    swrStore
  } = useStores();
  const navigate = useNavigate();

  const swrKey = SWR_KEYS.getPlanningByIdKey(id as string);

  const mutateList =
    swrStore.mutators[SWR_KEYS.getPlanningTable()];

  const fetcher = ([, id]: [string, string | undefined]) => {
    if (isUndefined(id)) return null;
    return api.planning.getPlanningById(Number(id));
  };

  const { data, mutate, isLoading } = useSWR(
    [swrKey, id],
    fetcher
  );

  const showToast = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error"
      }
    });
  };

  const handleBack = (id?: number | string) => {
    if (id) {
      const { str } = queryStringSidebarCollector.setup({
        tab: QUERY_STRING_MICRO_ROUTS_EXPERT_ASSESSMENT.INFO.path,
        keyContent: KEYS_SIDEBAR_VARIANT_MODULES.EXPERT_ASSESSMENT,
        module_id: id,
      });
      navigate({
        pathname: ROUTES_PATHS.planning_expert_assessment,
        search: `?${str}`
      });
    } else {
      navigate(ROUTES_PATHS.planning_expert_assessment);
    }
  };
  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const serializedValues = formToValue(values);
    const payload = {
      ...serializedValues,
      expert_assessment: true,
      trial_type: id 
        ? TRIAL_TYPE.HP
        : TRIAL_TYPE_EXPERT_ASSESSMENT
    };

    const promise = id 
      ? api.planning.patchPlanningById(id, payload) 
      : api.planning.postGeneratePlanningPlan(payload);
    promise
      .then((data) => {
        handleBack(data.id)
        mutateList && mutateList();
        id && mutate();
      })
      .catch((error: AxiosError) => {
        const formikErrors = pickFrom(error?.response?.data, Object.keys(initialValues));
        if (formikErrors) {
          helpers.setErrors(formikErrors);
        } else {
          showToast(error);
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  const title = id ? "Редактировать план на участке заявителя" : "Добавить план на участке заявителя";

  useLayoutEffect(() => {
    headerStore.setProps({
      title: title,
      handleBack: () => handleBack(id)
    });
    headerStore.setHeader(VARIANT_HEADER.DEFAULT);
  }, [id, title]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80}/>
      </Box>
    );
  }

  const value = id ? valueToForm(data) : initialValues;

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={value}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={FormExpertAssessment}
    />
  );
};

export default observer(AddExpertAssessmentPage);
