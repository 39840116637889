import React from "react";
import { FastField, Field, Form, useFormikContext } from "formik";
import { useStores } from "@/hooks";
import { useTheme } from "@mui/material/styles";
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import { AGR_MACHINERY_TYPES, AGRICULTURAL_MACHINERY_NAME, IProps } from "../const";
import FastRegexTextField from "@/components/FastRegexTextField";
import { IAgriculturalLabourLocation, IBranchItem, IPlot, IRegionItem } from "@/api/interfaces/responses";
import { BranchAutocomplete, LocationAutoComplete, PlotAutoComplete, RegionAutocomplete } from "@/components/Autocompletes";
import ImageForm from "./ImageFormStep";
import { YearDatePicker } from "@/components/Fields";


/**
 * Форма создания/редактирования сорта
 * @param modalProps пропсы
 * @param modalProps.initialValue - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const AgriculturalMachineryForm: React.FC<IProps> = ({ modalProps }) => {
    const { modalStore } = useStores();
    const theme = useTheme();
    const formik = useFormikContext<any>();

    const renderItems = AGR_MACHINERY_TYPES?.map((el) => (
        <MenuItem key={el.value} value={el.value}>
            {el.label}
        </MenuItem>
    ));

    const title = modalProps.agrMachineryId
        ? "Редактировать запись"
        : "Добавить запись";

    const handleRegionClear = () => {
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.REGION, "");
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.BRANCH, "");
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.PLOT, "");
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.LOCATION, "");
    };

    const handleChangeRegion = (payload: IRegionItem) => {
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.REGION, payload);
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.BRANCH, "");
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.PLOT, "");
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.LOCATION, "");
    };

    const handleBranchClear = () => {
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.BRANCH, "");
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.PLOT, "");
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.LOCATION, "");
    };

    const handleChangeBranch = (payload: IBranchItem) => {
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.BRANCH, payload);
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.PLOT, "");
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.LOCATION, "");
    };

    const handlePlotClear = () => {
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.PLOT, "");
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.LOCATION, "");
    };

    const handleChangePlot = (payload: IPlot) => {
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.PLOT, payload);
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.LOCATION, "");
    };

    const handleLocationClear = () => {
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.LOCATION, "");
    };

    const handleChangeLocation = (payload: IAgriculturalLabourLocation) => {
        void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.LOCATION, payload);
    };


    return (
        <Form noValidate>
            <Box>
                <Box p={3}>
                    <Typography fontSize={12} color={theme.palette.blackAndWhite.gray}>
                        Сельхозтехника
                    </Typography>
                    <Typography variant='h2'>{title}</Typography>
                </Box>
                <Divider />
                <Stack direction={"row"} gap={2} p={2}>
                    <Stack direction={"column"} width={'100%'} gap={2}>
                        <FastRegexTextField name={AGRICULTURAL_MACHINERY_NAME.NAME}
                            label="Название"
                            required
                            fullWidth
                            size="small" />
                        <FastRegexTextField name={AGRICULTURAL_MACHINERY_NAME.IDENTIFICATION_NUMBER}
                            label="Инвентарный номер"
                            fullWidth
                            size="small" />
                        <RegionAutocomplete
                            name={AGRICULTURAL_MACHINERY_NAME.REGION}
                            handleChange={handleChangeRegion}
                            onClear={handleRegionClear}
                        />
                        <PlotAutoComplete name={AGRICULTURAL_MACHINERY_NAME.PLOT}
                            handleChange={handleChangePlot}
                            q={{ branch: formik.values.branch?.id }}
                            onClear={handlePlotClear}
                            disabled={!formik.values.branch}
                        />
                    </Stack>

                    <Stack direction={"column"} width={'100%'} gap={2}>
                        <Field name={AGRICULTURAL_MACHINERY_NAME.TYPE}>
                            {({ field, meta }) => (
                                <FormControl size='small' variant='outlined' fullWidth required>
                                    <InputLabel id='select-label'>Тип</InputLabel>
                                    <Select
                                        {...field}
                                        label='Тип'
                                        error={meta.touched && !!meta.error}
                                        helperText={meta.touched && meta.error}
                                        required
                                        fullWidth
                                        disabled={meta.isSubmitting}
                                    >
                                        {renderItems}
                                    </Select>
                                </FormControl>
                            )}
                        </Field>
                        <YearDatePicker
                            name={AGRICULTURAL_MACHINERY_NAME.PRODUCTION_YEAR}
                            label="Год производства"
                            fullWidth
                        />
                        <BranchAutocomplete name={AGRICULTURAL_MACHINERY_NAME.BRANCH}
                            q={{ region: formik.values.region?.id }}
                            handleChange={handleChangeBranch}
                            onClear={handleBranchClear}
                            disabled={!formik.values.region}
                        />
                        <LocationAutoComplete
                            name={AGRICULTURAL_MACHINERY_NAME.LOCATION}
                            handleChange={handleChangeLocation}
                            q={{ plot: formik.values.plot?.id }}
                            onClear={handleLocationClear}
                            disabled={!formik.values.plot}
                        />

                    </Stack>
                </Stack>
                <FastField name={AGRICULTURAL_MACHINERY_NAME.CONDITION}>
                    {({ field }) => (
                        <FormControl>
                            <RadioGroup {...field}>
                                <Stack
                                    p={"0 10px 0 10px"}
                                    direction="row"
                                    alignItems={"center"}
                                >
                                    <FormControlLabel
                                        value={"operational"}
                                        control={<Radio color="secondary" />}
                                        label="Исправно"
                                    />
                                    <FormControlLabel
                                        value={"requires_repair"}
                                        control={<Radio color="secondary" />}
                                        label="Требует ремонта"
                                    />
                                    <FormControlLabel
                                        value={"unfit"}
                                        control={<Radio color="secondary" />}
                                        label="Не пригодно"
                                    />
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                    )}
                </FastField>
                <Stack p={2}>
                    <ImageForm id={modalProps.initialValues?.id} />

                </Stack>

                <Divider />
                <Box p={3} display='flex' justifyContent='space-between'>
                    <Button color='red' onClick={() => modalStore.close()}>
                        отмена
                    </Button>
                    <Button
                        type='submit'
                        disabled={formik.isSubmitting}
                        variant='contained'
                        color='primary'
                        startIcon={<CheckIcon />}
                    >
                        Сохранить
                    </Button>
                </Box>
            </Box>
        </Form>
    );
};

export default AgriculturalMachineryForm
