import yup from "@/utils/yup";
import {
  IAgriculturalLabourWork
} from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  TYPE: "type",
  DRIVERS_QUANTITY: "drivers_quantity",
  DRIVERS_PAY_RATE: "drivers_pay_rate",
  DRIVERS_PAY_GRADE: "drivers_pay_grade",
  DRIVERS_ADDITIONAL_PAYMENT_PCT: "drivers_additional_payment_pct",
  WORKERS_QUANTITY: "workers_quantity",
  WORKERS_PAY_RATE: "workers_pay_rate",
  WORKERS_PAY_GRADE: "workers_pay_grade",
  WORKERS_ADDITIONAL_PAYMENT_PCT: "workers_additional_payment_pct",
  SHIFT_PRODUCTION_RATE: "shift_production_rate",
  REFERENCE_PRODUCTION_RATE: "reference_production_rate",
  GSM: "gsm",
  DRIVERS_HOUR_RATE: "drivers_hour_rate",
  WORKERS_HOUR_RATE: "workers_hour_rate",
  COMMENT: "comment"
} as const;

interface IValue {
  id: number;
  label: string;
}

export const defaultValues =     {
  [FIELDS_NAME.TYPE]: null,
  [FIELDS_NAME.DRIVERS_QUANTITY]:  "",
  [FIELDS_NAME.DRIVERS_PAY_RATE]:  "",
  [FIELDS_NAME.DRIVERS_PAY_GRADE]:  "",
  [FIELDS_NAME.DRIVERS_ADDITIONAL_PAYMENT_PCT]:  "",
  [FIELDS_NAME.WORKERS_QUANTITY]:  "",
  [FIELDS_NAME.WORKERS_PAY_RATE]:  "",
  [FIELDS_NAME.WORKERS_PAY_GRADE]:  "",
  [FIELDS_NAME.WORKERS_ADDITIONAL_PAYMENT_PCT]:  "",
  [FIELDS_NAME.SHIFT_PRODUCTION_RATE]:  "",
  [FIELDS_NAME.REFERENCE_PRODUCTION_RATE]:  "",
  [FIELDS_NAME.GSM]:  "",
  [FIELDS_NAME.DRIVERS_HOUR_RATE]: "",  
  [FIELDS_NAME.WORKERS_HOUR_RATE]: "",  
  [FIELDS_NAME.COMMENT]: "",  
}

export const valueToForm = (work: IAgriculturalLabourWork): IFormik => {
  return {
    type: work.type ? {
      id: work.type.id,
      label: work.type.work_type
    }: null,
    gsm: work.gsm,
    drivers_additional_payment_pct: work.drivers_additional_payment_pct,
    drivers_pay_grade: work.drivers_pay_grade,
    drivers_pay_rate: work.drivers_pay_rate,
    drivers_quantity: work.drivers_quantity,
    reference_production_rate: work.reference_production_rate,
    shift_production_rate: work.shift_production_rate,
    workers_additional_payment_pct: work.workers_additional_payment_pct,
    workers_pay_grade: work.workers_pay_grade,
    workers_pay_rate: work.workers_pay_rate,
    workers_quantity: work.workers_quantity,
    drivers_hour_rate: work.drivers_hour_rate,
    workers_hour_rate: work.workers_hour_rate,
    comment: work.comment
  }
}

export const formToValue = (work: IFormik) => {
  return {
    type: work.type?.id as number,
    gsm: work.gsm as number,
    drivers_additional_payment_pct: work.drivers_additional_payment_pct as string,
    drivers_pay_grade: work.drivers_pay_grade as number,
    drivers_pay_rate: work.drivers_pay_rate as number,
    drivers_quantity: work.drivers_quantity as number,
    reference_production_rate: work.reference_production_rate as number,
    shift_production_rate: work.shift_production_rate as number,
    workers_additional_payment_pct: work.workers_additional_payment_pct as string,
    workers_pay_grade: work.workers_pay_grade as number,
    workers_pay_rate: work.workers_pay_rate as number,
    workers_quantity: work.workers_quantity as number,
    drivers_hour_rate: work.drivers_hour_rate as number,
    workers_hour_rate: work.workers_hour_rate as number,
    comment: work.comment
  }
}

export interface IFormik {
  [FIELDS_NAME.TYPE]: IValue | null,
  [FIELDS_NAME.DRIVERS_QUANTITY]: number | string,
  [FIELDS_NAME.DRIVERS_PAY_RATE]: number | string,
  [FIELDS_NAME.DRIVERS_PAY_GRADE]: number | string,
  [FIELDS_NAME.DRIVERS_ADDITIONAL_PAYMENT_PCT]: number | string,
  [FIELDS_NAME.WORKERS_QUANTITY]: number | string,
  [FIELDS_NAME.WORKERS_PAY_RATE]: number | string,
  [FIELDS_NAME.WORKERS_PAY_GRADE]: number | string,
  [FIELDS_NAME.WORKERS_ADDITIONAL_PAYMENT_PCT]: number | string ,
  [FIELDS_NAME.SHIFT_PRODUCTION_RATE]: number | string,
  [FIELDS_NAME.REFERENCE_PRODUCTION_RATE]: number | string,
  [FIELDS_NAME.GSM]: number | string,
  [FIELDS_NAME.DRIVERS_HOUR_RATE]: number | string,  
  [FIELDS_NAME.WORKERS_HOUR_RATE]: number | string, 
  [FIELDS_NAME.COMMENT]: string,   
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.TYPE]: yup.mixed().required().nullable(),
  [FIELDS_NAME.DRIVERS_QUANTITY]: yup.number().nullable(),
  [FIELDS_NAME.DRIVERS_PAY_RATE]: yup.number().nullable(),
  [FIELDS_NAME.DRIVERS_PAY_GRADE]: yup.number().nullable(),
  [FIELDS_NAME.DRIVERS_ADDITIONAL_PAYMENT_PCT]: yup.number().nullable(),
  [FIELDS_NAME.WORKERS_QUANTITY]: yup.number().nullable(),
  [FIELDS_NAME.WORKERS_PAY_RATE]: yup.number().nullable(),
  [FIELDS_NAME.WORKERS_PAY_GRADE]: yup.number().nullable(),
  [FIELDS_NAME.WORKERS_ADDITIONAL_PAYMENT_PCT]: yup.number().nullable(),
  [FIELDS_NAME.SHIFT_PRODUCTION_RATE]: yup.number().nullable(),
  [FIELDS_NAME.REFERENCE_PRODUCTION_RATE]: yup.number().nullable(),
  [FIELDS_NAME.GSM]: yup.number().nullable(),
  [FIELDS_NAME.DRIVERS_HOUR_RATE]: yup.number().nullable(),  
  [FIELDS_NAME.WORKERS_HOUR_RATE]: yup.number().nullable(),
  [FIELDS_NAME.COMMENT]: yup.string().nullable(),
});
