import React, { useEffect, useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
    Box,
    Divider,
    IconButton,
    Menu,
    SxProps,
    Typography,
} from "@mui/material";
import { EditIcon, DeleteIcon } from "@/components/icons";
import { buttonDotsStyle } from "../../style";
import { StyledMenuItem } from "@/components/StyledMenuItem";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { useQuery, useStores } from "@/hooks";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import theme from "@/theme";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import { SWR_KEYS } from "@/const";
import { ShortText } from "@/components/ShortText";
import { resInterface } from "@/api/interfaces";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

export const COLUMN_EDITING = {
    value: "editing",
    label: "",
};

export const EDITING_CELL = {
    editing: "Редактировать",
    duplicate: "Копировать",
    delete: "Удалить",
};

export const cellVarietyStyle = (
    backgroundColor: string,
    textColor: string,
): SxProps => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    padding: "3px 5px",
    color: textColor,
    backgroundColor: backgroundColor,
    borderRadius: "100px",
    minWidth: "160px",
    maxWidth: "170px",
    textWrap: "nowrap",
});

/**
 * @component type order
 * * компонент ячейки таблицы
 * @param
 */

export const VARIETY_TYPES = {
    common_hybrid: "Простой гибрид",
    trilinear_hybrid: "Трёхлинейный гибрид",
    complex_hybrid: "Сложный гибрид",
    first_generation_hybrid: "Гибрид первого поколения",
    genetically_modified: "Генетически модифицированный",
    cultivated_on_republic_territory:"Возделывается на территории республики",
    cross: "Кросс",
    line: "Линия",
    species: "Порода",
    parent_component: "Родительский компонент",
    variety_line_hybrid: "Сортолинейный гибрид",
    variety_population: "Сорт-популяция",
    type: "Тип",
    G_common_hybrid: "Простой гибрид",
    M_trilinear_hybrid: "Трёхлинейный гибрид",
    I_complex_hybrid: "Сложный гибрид",
    C_first_generation_hybrid: "Гибрид первого поколения",
    B_genetically_modified: "Генетически модифицированный",
    A_cultivated_on_republic_territory: "Возделывается на территории республики",
    D_cross: "Кросс",
    E_line: "Линия",
    F_species: "Порода",
    H_parent_component: "Родительский компонент",
    J_variety_line_hybrid: "Сортолинейный гибрид",
    K_variety_population: "Сорт-популяция",
    L_type: "Тип",
} as const;

export const VarietyTypeCell = (variety: any): JSX.Element => {
    const backgroundColor = theme.palette.blackAndWhite.grayLight ?? theme.palette.blackAndWhite.white;
    const textColor = theme.palette.blackAndWhite.black ?? theme.palette.blackAndWhite.white;
    const varietyType = VARIETY_TYPES[variety] ?? "-";
    return (
        <ShortText title={varietyType} sx={cellVarietyStyle(backgroundColor, textColor)} text={varietyType} textLength={15} />
    );
};


export const СultivarCell = (
    _: string,
    cell: resInterface.IVarieties,
): JSX.Element => {
    const { cultivar } = cell;
    return (
        <Box>
            <Typography fontSize={14}>
                {cultivar?.name ?? "-"}
            </Typography>
        </Box>
    );
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCellVariety = (_: string, cell: any, itemsInPage: any[]): JSX.Element => {
    const { api, modalStore, swrStore, userStore } = useStores();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const query = useQuery<any>();
    const [, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (itemsInPage.length === 1) {
            const params = qs.stringify(
                { ...query, last: 'true' },
                { arrayFormat: "comma" },
            );
            setSearchParams(params);
        } else {
            const params = qs.stringify(
                { ...query, last: undefined },
                { arrayFormat: "comma" },
            );
            setSearchParams(params);
        }
    }, [itemsInPage.length]);



    // функция открытия/закрытия компонента дропдауна
    const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    // функция закрытия компонента дропдауна (для клика вне попапа)
    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        modalStore.open(VARIANT_MODAL.VARIETIES, {
            theme: THEME_MODAL.W_555,
            initialValues: cell,
            varietyId: cell.id,
        });
        setAnchorEl(null);
    };

    const mutateList =
        swrStore.mutators[SWR_KEYS.getVarietyList()];

    const deleteWithCheckingLast = () => {
        // если один эл-т на странице перенаправляем на предыдущую страницу
        if (itemsInPage.length === 1 && query.page > 1) {
            const params = qs.stringify(
                { ...query, page: query.page - 1 },
                { arrayFormat: "comma" },
            );
            setSearchParams(params);
        } else {
            mutateList && mutateList();
        }
    };

    const handleDelete = (resolve, reject) => {
        api.regulatoryInfo.deleteVariety(cell.id).then(
            () => {
                deleteWithCheckingLast();
                resolve()
            }
        ).catch(reject);
        setAnchorEl(null);
    };

    const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        const modalProp = {
            theme: THEME_MODAL.W_555,
            type: CELL_TYPES.handbook,
            id: cell.id,
            handleDelete: handleDelete,
        };

        modalStore.setTheme(THEME_MODAL.W_555);
        modalStore.open(VARIANT_MODAL.OK_OR_NOT_DELETE, modalProp);
    };

    const canEditVariety= userStore.isPermission(
        PERMISSIONS.regulatory_infoVariety,
        PERMISSION_CRUD.change
      )
      const canDeleteVariety = userStore.isPermission(
        PERMISSIONS.regulatory_infoVariety,
        PERMISSION_CRUD.delete
      )
    
      if (!canEditVariety && !canDeleteVariety) {
        return <></>
      }

    return (
        <Box>
            <IconButton sx={buttonDotsStyle()} onClick={handleToggle}>
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {canEditVariety && 
                <StyledMenuItem onClick={handleClickEdit}>
                    <EditIcon />
                    {EDITING_CELL.editing}
                </StyledMenuItem>
                }
                {canEditVariety && canDeleteVariety && 
                <Divider />
                }
                {canDeleteVariety && 
                <StyledMenuItem onClick={handleClickDelete}>
                    <DeleteIcon color={"error"} />
                    <Typography color='error'>{EDITING_CELL.delete}</Typography>
                </StyledMenuItem>
                }
            </Menu>
        </Box>
    );
};
