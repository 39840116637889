import React from "react";
import {
  Box,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import theme from "@/theme";
import { IOrderPlot } from "@/api/interfaces/responses";
import {
  PLOT_LABEL_TYPES,
  PLOT_TYPES_COLOR,
  PLOT_TYPES_ICON,
} from "@/pages/AddOrderCultivar/const";
import {
  QUERY_STRING_MICRO_ROUTS_ORDER_CULTIVAR,
  RIGHT_SIDEBAR_TYPES,
  RIGHT_SIDEBAR_TYPES_LEVELS,
} from "@/apps/RightSidebar/const";
import { KEYS_SIDEBAR_VARIANT_MODULES } from "@/const";
import { useRightSidebar, useStores } from "@/hooks";
import MoreMenu from "./MoreMenu";
import { TOrderCultivarStatus } from "@/api/types";
import qs from "qs";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

interface IProp {
  item: IOrderPlot;
  isDetailOpen?: boolean;
  status?: TOrderCultivarStatus
  isHidden?: boolean;
}

const OrderPlotItem: React.FC<IProp> = ({
  item,
  status,
  isDetailOpen = true,
  isHidden,
}) => {
  const { id, plot, hp, oos, phito, order_cultivar } = item;
  const { queryStringSidebarCollector, userStore } = useStores();
  const { handleOpen } = useRightSidebar();
  const [, setSearchParams] = useSearchParams();

  const VARIANTS = Array.from(
    Object.entries({ hp, oos, phito }),
    ([key, value]) => ({
      key,
      visible: value,
      icon: PLOT_TYPES_ICON[key],
      text: PLOT_LABEL_TYPES[key],
      color: PLOT_TYPES_COLOR[key],
    }),
  ).filter((item) => item.visible);

  const handleClick = () => {
    if (isDetailOpen) {
      const { str } = queryStringSidebarCollector.setup({
        tab: QUERY_STRING_MICRO_ROUTS_ORDER_CULTIVAR
          .CULTIVAR_DATA.path,
        keyDetail:
          RIGHT_SIDEBAR_TYPES_LEVELS[
            RIGHT_SIDEBAR_TYPES.ORDER_CULTIVAR
          ].PLOT,
        keyContent:
          KEYS_SIDEBAR_VARIANT_MODULES.ORDER_CULTIVAR,
        module_id: queryStringSidebarCollector.getCurrentModuleId(),
        detail_id: id,
      });

      handleOpen({
        type: RIGHT_SIDEBAR_TYPES_LEVELS[
          RIGHT_SIDEBAR_TYPES.ORDER_CULTIVAR
        ].PLOT,
        modalProps: {
          id: order_cultivar?.id,
          detail_id: id,
        },
      });
      const params = qs.parse(str)
      setSearchParams(qs.stringify({...params, plot_status: status}));
    }
  };

  const canAdd = userStore.isPermission(
    PERMISSIONS.regulatory_infoOrderCultivar,
    PERMISSION_CRUD.add
  );

  const canChange = userStore.isPermission(
    PERMISSIONS.regulatory_infoOrderCultivar,
    PERMISSION_CRUD.change
  );

  const canDelete = userStore.isPermission(
    PERMISSIONS.regulatory_infoOrderCultivar,
    PERMISSION_CRUD.delete
  );

  const canChangeItem = canAdd && canChange && canDelete

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      onClick={handleClick}
      sx={isDetailOpen ? { cursor: "pointer" } : {}}
    >
      <Stack direction="column" width="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          marginBottom="8px"
        >
          <Tooltip
            placement="bottom-start"
            title="Название"
          >
            <Typography variant="h4">{`${plot?.name} ГСУ`}</Typography>
          </Tooltip>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
          >
            {VARIANTS.map(({ key, icon, text, color }) => (
              <Tooltip
                key={key}
                placement="bottom-start"
                title={text}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <img
                    src={icon}
                    height={15}
                    width={15}
                    alt="Иконка"
                  />
                  <Typography variant="h6" sx={{ color }}>
                    {text}
                  </Typography>
                </Stack>
              </Tooltip>
            ))}
          </Stack>
        </Stack>
        <Stack direction="row">
          <Box width="40px" mr="6px">
            <Tooltip placement="bottom-start" title="ГСУ">
              <Typography
                variant="p14Medium"
                color={theme.palette.blackAndWhite.gray}
              >
                {plot?.id}
              </Typography>
            </Tooltip>
          </Box>
          <Box>
            <Tooltip
              placement="bottom-start"
              title="Филиал"
            >
              <Typography variant="p14Medium">
                {plot?.branch?.name}
              </Typography>
            </Tooltip>
          </Box>
        </Stack>
      </Stack>
      {isHidden || !canChangeItem ? null : <MoreMenu item={item} />}
    </Stack>
  );
};

export default OrderPlotItem;
