import yup from "@/utils/yup";
import { IVarieties } from "@/api/interfaces/responses";
import { reqInterface } from "@/api/interfaces";

export interface IProps {
  handlePop: () => void;
  modalProps: {
    key: string;
    initialValue?: IVarieties;
    varietyId?: number;
    cultivarEditable?: boolean;
    onSubmit?: (variety: IVarieties) => void;
    submitOnMount?: boolean;
  };
}

export enum VARIETY_FIELD_NAME {
  NAME = "name",
  LATIN_VARIETY_NAME = "latin_variety_name",
  CULTIVAR = "cultivar",
  FULLNAME = "fullname",
  SELECTION_NUMBER = "selection_number",
  IS_STANDARD = "is_standard",
  IS_BENCHMARK = "is_benchmark",
  UPOV_CHECK = "UPOV_check",
  TYPE = "type",
  IN_REGISTER = 'in_register',
  IS_INDICATOR = 'is_indicator',
  CIPHER = 'cipher',
  REGIONS = 'regions',
  IS_UNIQUE_VARIETY_NAME = "is_unique_variety_name",
}
export interface IRegions {
  id: number;
}

export type TForm = Omit<reqInterface.IVarietiesReq, "cultivar" | "regions"> & {
  cultivar: {
    id: number;
  };
  regions: IRegions[];
};


export const mapValueToForm = (value) => {
  return {
    [VARIETY_FIELD_NAME.NAME]: value?.name || "",
    [VARIETY_FIELD_NAME.FULLNAME]: value?.fullname || "",
    [VARIETY_FIELD_NAME.LATIN_VARIETY_NAME]: value?.latin_variety_name || '',
    [VARIETY_FIELD_NAME.SELECTION_NUMBER]: value?.selection_number || "",
    [VARIETY_FIELD_NAME.TYPE]: value?.type || null,
    [VARIETY_FIELD_NAME.CULTIVAR]: value?.cultivar
      ? {
        id: value.cultivar?.id,
        name: value.cultivar?.name,
      }
      : "",
    [VARIETY_FIELD_NAME.IS_STANDARD]: value?.is_standard || false,
    [VARIETY_FIELD_NAME.IS_BENCHMARK]: value?.is_benchmark || false,
    [VARIETY_FIELD_NAME.UPOV_CHECK]: value?.UPOV_check || false,
    [VARIETY_FIELD_NAME.IN_REGISTER]: value?.in_register || false,
    [VARIETY_FIELD_NAME.CIPHER]: value?.cipher || '',
    [VARIETY_FIELD_NAME.IS_INDICATOR]: value?.is_indicator || false,
    [VARIETY_FIELD_NAME.REGIONS]: value?.regions?.length
      ? value?.regions?.map((i) => ({
        ...i,
        id: i.id,
        name: i.name,
      })) : []
  };
};

export const mapFormToValue = (values: TForm) => {
  const regions = values.regions.map((i) => i.id);

  return {
    ...values,
    [VARIETY_FIELD_NAME.CULTIVAR]: values?.cultivar?.id,
    [VARIETY_FIELD_NAME.REGIONS]: regions,
  };
};

export const validationSchema = yup.object().shape({
  [VARIETY_FIELD_NAME.TYPE]: yup.string().trim().required(),
  [VARIETY_FIELD_NAME.CULTIVAR]: yup.mixed().required(),
  [VARIETY_FIELD_NAME.NAME]: yup.string().trim().required(),
  [VARIETY_FIELD_NAME.FULLNAME]: yup.string().trim(),
  [VARIETY_FIELD_NAME.LATIN_VARIETY_NAME]: yup
    .string()
    .trim()
    .matches(/[a-zA-Z]+/gu, {
      message: "Возможно использовать только латинские символы",
    }),
  [VARIETY_FIELD_NAME.SELECTION_NUMBER]: yup.string().trim(),
  [VARIETY_FIELD_NAME.IS_STANDARD]: yup.boolean(),
  [VARIETY_FIELD_NAME.IS_BENCHMARK]: yup.boolean(),
  [VARIETY_FIELD_NAME.UPOV_CHECK]: yup.boolean(),
  [VARIETY_FIELD_NAME.IN_REGISTER]: yup.boolean(),
  [VARIETY_FIELD_NAME.IS_INDICATOR]: yup.boolean(),
  [VARIETY_FIELD_NAME.REGIONS]: yup.array(),
});