import React, { useEffect } from "react";
import {
  FastField,
  FieldArray,
  Form,
  useFormikContext
} from "formik";
import { Box } from "@mui/system";
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  Tooltip
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  ChipWrap
} from "@/pages/AddStatement/components/SubjectsFormStep/styles";
import {
  IDiseaseList,
  IEntity
} from "@/api/interfaces/responses";
import AddChip from "@/components/Chips/AddChip";
import CheckIcon from "@mui/icons-material/Check";
import { IFormik, PHYTO_FIELD_NAME } from "./const";
import { useStores } from "@/hooks";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import {
  PERMISSION_CRUD,
  PERMISSIONS
} from "@/premissions";
import { StyledList } from "../../styles";
import { YearDatePicker } from "@/components/Fields";
import { useLocation, useParams } from "react-router-dom";

interface IProps {
  cultivar: IEntity;
}

const PhytoTrialForm: React.FC<IProps> = ({ cultivar }) => {
  const { modalStore, userStore } = useStores();
  const formik = useFormikContext<IFormik>();
  const { values } = formik;
  const { trialId } = useParams();
  const location = useLocation();

  const handleAcceptDiseases = (values: IDiseaseList[]) => {
    const newValues = values.map((el) => ({
      id: el.id,
      name: el.name
    }));
    formik.setFieldValue(PHYTO_FIELD_NAME.DISEASES, newValues);
  };
  const handleOpenModal = () => {
    modalStore.open(VARIANT_MODAL.SELECT_DISEASES, {
      onSubmit: handleAcceptDiseases,
      cultivar,
      initialValue: formik.values.diseases
    });
  };

  const isViewDisease = userStore.isPermission(
    PERMISSIONS.regulatory_infoDisease,
    PERMISSION_CRUD.view
  );


  const handleRegionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const id = Number(event.target.name);
    const checkedRegions =
      formik.values[PHYTO_FIELD_NAME.REGIONS];

    if (event.target.checked) {
      formik.setFieldValue(PHYTO_FIELD_NAME.REGIONS, [
        ...checkedRegions,
        id,
      ]);
    } else {
      formik.setFieldValue(
        PHYTO_FIELD_NAME.REGIONS,
        checkedRegions.filter((val) => val !== id),
      );
    }
  };

  /**
   * Список регионов
   */
  const renderRegionItems = values.context.regions?.map(
    (el: any) => {
      return (
        <Box key={el.id}>
          {el?.is_deleted ? null : (
            <ListItem>
              <FormControlLabel
                name={String(el.id)}
                checked={values[
                  PHYTO_FIELD_NAME.REGIONS
                ].includes(el.id)}
                control={<Checkbox color="secondary" />}
                label={
                  <Typography
                    fontSize={14}
                  >{`${el.number} - ${el.name}`}</Typography>
                }
              />
            </ListItem>
          )}
        </Box>
      );
    },
  );

  useEffect(() => {
    if (!trialId) {
      const regionIds =
        values.context.regions?.map(
          (region) => region.id,
        ) || [];
      formik.setFieldValue(
        PHYTO_FIELD_NAME.REGIONS,
        regionIds,
      );
    }
  }, [location]);

  return (
    <Box p={3}>
      <Box sx={{ maxWidth: 600 }}>
        <Form noValidate>
          <Stack direction="column" spacing="20px">
            <Typography variant="h3">Данные испытания</Typography>
            <YearDatePicker
            name={PHYTO_FIELD_NAME.YEAR_OF_TESTING}
            label="Год испытания"
            required
            />
            <FieldArray
              name={PHYTO_FIELD_NAME.DISEASES}
              render={(helpers) => {
                const values = helpers.form.values[helpers.name];
                const meta = helpers.form.getFieldMeta(helpers.name);
                return (
                  <FormControl required>
                    <FormLabel>Устойчивость к заболеваниям</FormLabel>
                    <ChipWrap>
                      {Array.isArray(values) &&
                        values.map((value: IEntity, index) => (
                          <Chip
                            key={value.id}
                            size="small"
                            variant="outlined"
                            label={value.name}
                            onDelete={() => helpers.remove(index)}
                          />
                        ))}
                      {
                        <Tooltip
                          title={
                            !isViewDisease ? "У вас недостаточно прав" : ""
                          }
                          followCursor
                        >
                          <Box>
                            <AddChip
                              disabled={!isViewDisease}
                              onClick={handleOpenModal}
                            />
                          </Box>
                        </Tooltip>
                      }
                    </ChipWrap>
                    <FormHelperText error={meta.touched && !!meta.error}>
                      {meta.touched && meta.error}
                    </FormHelperText>
                  </FormControl>
                );
              }}
            />
            <FastField name={PHYTO_FIELD_NAME.PAYMENT_TYPE}>
              {({ field }) => (
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Тип оплаты
                  </FormLabel>
                  <RadioGroup {...field}>
                    <Stack direction="row">
                      <FormControlLabel
                        value="budget"
                        control={<Radio color="secondary" />}
                        label="Бюджет"
                      />
                      <FormControlLabel
                        value="paid"
                        control={<Radio color="secondary" />}
                        label="Платные"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )}
            </FastField>
            <FastField name={PHYTO_FIELD_NAME.REGIONS}>
              {({ meta }) => (
                <FormControl required>
                  <FormLabel>Регионы допуска</FormLabel>
                  <FormGroup onChange={handleRegionChange}>
                    <StyledList>
                      {renderRegionItems}
                    </StyledList>
                  </FormGroup>
                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </FastField>
            <Box>
              <Button
                startIcon={<CheckIcon />}
                disabled={formik.isSubmitting}
                variant="contained"
                type="submit"
              >
                Готово
              </Button>
            </Box>
          </Stack>
        </Form>
      </Box>
    </Box>
  );
};

export default PhytoTrialForm;
