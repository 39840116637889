import { TOrder } from "@/apps/Table/interfaces";
import { IColorsStatus } from "./pages/Statement/const";
import theme from "./theme";
import { TPlanFuct } from "./interfaces";

/**
 * Подвид испытаний ХП
 */
export const HP_TYPE = {
  ACCORDING_REGIONS_RF: "according_regions_rf", //По данным государственных испытаний в регионах РФ
  ACCORDING_EXPERT_ASSESSMENT:
    "according_expert_assessment", //По экспертной оценке
  ACCORDING_APPLICANT: "according_applicant", // По данным заявителя
} as const;

// 1 гектар = 10000 квадратных метров
export const M_IN_HA = 10_000

export const ERROR_MESSAGES = {
  SERVER: "Ошибка на сервере",
  REQUIRED: "Это поле необходимо заполнить",
};

export const SEO_TITLES = {
  MAIN: "Главная",
  STATEMENT: "Заявки",
  PLANNING: "Планирование",
  AGRICULTURAL: "Карта ГСУ",
  AGRICULTURAL_WORK: "С/Х работы",
  OFF_BUDGET: "Производство",
  HANDBOOK: "Справочники",
  PROFILE: "Профиль",
  LOGIN: "Авторизация",
  PASSWORD_RESET: "Сброс пароля",
  MAP: "Карта ГСУ",
  MAP_AREA:"Карта участка",
  MAP_FIELD:"Карта поля",
  MAP_CULTIVAR: "Карта культуры"
}

export const SWR_KEYS = {
  getDutyList: () => "/registration/duty/",
  getDutyById: (id: number | string) =>
    `/registration/duty/${id}/`,
  getStatementDuties: (id: number) =>
    `getStatementDuties/${id}`,
  getEntities: (name: string) => `getEntities/${name}`,
  getOffBudget: () => `getOffBudget`,
  getOffBudgetById: (id) => `getOffBudgetById/${id}`,
  getLightZone: () => `getLightZone`,
  getCultivar: () => `getCultivar`,
  getCultivarById: (id: string | number) =>
    `getCultivarById/${id}`,
  getRegions: () => `getRegions`,
  getCountries: () => `getCountries`,
  getCountryById: (id: number) => `getCountryById/${id}`,
  getEmployeeList: () => `getEmployeeList`,
  getEmployeeById: (id: number) => `getEmployeeById/${id}`,
  getLightZones: () => `getLightZones`,
  getLightZoneById: (id: string | number) =>
    `getlightZoneById/${id}`,
  getAddress: () => `getAddress`,
  getAddressById: (id: string | number) =>
    `getAddressById/${id}`,
  getStatements: () => `getStatements`,
  getPlanningTable: () => "/planning",
  getPlanningPlotList: (id: string | number) =>
    `/plan_plot/${id}`,
  getGroupPlanPlotList: (id: number | null) =>
    `/planning/plan_group_plot/${id}`,
  getPlanningByIdKey: (id: string | number) =>
    `getPlanningById/${id}`,
  getStatementByIdKey: (id: string | number) =>
    `getStatementById/${id}`,
  getPlanningHpTable: () => "/plan_hp",
  getPlanningHpByIdKey: (id: string | number) =>
    `getPlanningHpById/${id}`,
  getRemarksByStatementIdKey: (id: string | number) =>
    `/registration/remark_statement/${id}`,
  getTrialByStatementIdKey: (id: string | number) =>
    `/registration/trial/${id}`,
  getRefusalRemarksByStatementIdKey: (
    id: string | number,
  ) => `/registration/remark/${id}/refusal`,
  getRevisionRemarksByStatementIdKey: (
    id: string | number,
  ) => `/registration/remark/${id}/revision`,
  getTrialById: (id: string | number) =>
    `getTrialById/${id}`,
  getDiseaseList: () => "getDiseaseList",
  getTrialList: () => "/trial/trial/",
  getDiseaseByID: (id: number) => `getDiseaseList/${id}`,
  getPlanBranchesList: () => "getPlanBranchesList",
  getBranchById: (id: string | number) =>
    `/regulatory_info/branch/${id}`,
  getPlotList: () => `getPlotList/`,
  getUnitList: () => "getUnitList/",
  getUnitById: (id: number) => `getUnitById/${id}`,
  getMaterialTypeList: () => "getMaterialTypeList/",
  getDistributionList: () => "getDistributionList/",
  getOrganizationList: () => "getOrganizationList/",
  getOffBudgetCultivars: () => `getOffBudgetCultivars/`,
  getOffBudgetCultivar: (id: string | number) =>
    `getOffBudgetCultivar/${id}`,
  getOffBudgetChemistry: (
    id: string | number,
    type: string,
  ) => `getOffBudgetChemistry/${id}/${type}/`,
  getOffBudgetComWork: (id: string | number) =>
    `getOffBudgetComWork/${id}/`,
  getEffectivityList: (id: string | number) =>
    `getEffectivityList/${id}`,
  getRegionsList: () => "getRegionsList",
  getVegetationCausesList: () => "/regulatory_info/vegetation_causes/",
  getVegetationCausesById: (id: number) => `/regulatory_info/vegetation_causes/${id}/`,
  getVegetationStageList: () => "/regulatory_info/vegetation_stage/",
  getVegetationStageById: (id: number) => `/regulatory_info/vegetation_stage/${id}/`,
  getRegionById: (id: string | number) =>
    `getRegionById/${id}`,
  getChemistryList: (type: string) =>
    `getChemistry/${type}`,
  getChemistriesList: () => `/regulatoryInfo/chemistry/`,
  getRateList: () => `/regulatory_info/rate/`,
  getChemistryById: (id: number | string) =>`/regulatoryInfo/chemistry/${id}/`,
  getWorkList: () =>
    `agricultural_labour/work/`,
  getWorkType: () =>
    `agricultural_labour/worktype/`,
  getWorkByID: (id: string | number) =>
    `agricultural_labour/work/${id}/`,
  getVarietyIndicator: (id: string | number, indicator_type: string) => 
    `/regulatory_info/variety_indicator/${id}/${indicator_type}/`,
  addOffBudgetWageFund: (id: string | number) =>
    `addOffBudgetWageFund/${id}/`,
  getPostList: () => "getPostList",
  getPostById: (id: number) => `getPostById/${id}`,
  getDistrictList: () => '/regulatory_info/district/',
  getDistrictById: (id: number) => `/regulatory_info/district/${id}/`,
  getLightZoneForDistricts: () => "regulatory_info/light_zone/list_for_districts/",
  getDepartmentList: () => "regulatory_info/department/",
  getDepartmentById: (id: number) => `regulatory_info/department/${id}/`,
  getOffBudgetWageFund: (id: string | number) =>
    `getOffBudgetWageFund/${id}`,
  addOffBudgetExpense: (id: string | number) =>
    `addOffBudgetExpense/${id}/`,
  getExpenseList: () => "getExpenseList",
  getRegularityInfo: () => `getRegularityInfo/`,
  addInvestment: (id: string | number) =>
    `addInvestment/${id}/`,
  getInvestmentList: (id: string | number) =>
    `getInvestmentList/${id}`,
  getPlanList: () => `getPlanList/`,
  getAgriculturalLabourLocationList: () =>
    "/agricultural_labour/location/",
  getAgriculturalLabourFieldList: () =>
    "/agricultural_labour/field/",
  getAgriculturalLabourCultivarFieldList: () =>
    "/agricultural_labour/cultivar_field/",
  getAgriculturalLabourLocation: (id: string | number) =>
    `/agricultural_labour/location/${id}/`,
  getAgriculturalLabourField: (id: string | number) =>
    `/agricultural_labour/field/${id}/`,
  getAllSortFromField: (id) =>
    `agricultural_labour/cultivar_field/get_all_cultivar_fields/${id}`,
  getAgriculturalLabourCultivarField: (
    id: string | number,
  ) => `/agricultural_labour/cultivar_field/${id}/`,
  getAgriculturalMachinery: () => "/regulatory_info/agricultural_machinery/",
  getAgriculturalMachineryById: (id: number| string) => `/regulatory_info/agricultural_machinery/${id}/`,
  getAllFIeldFromArea: () =>
    `/agricultural_labour/variety_field/all_polygon/`,
  getDestinationList: () => "/regulatory_info/destination/",
  getOrderCultivarList: () => "getOrderCultivarList",
  getBranchList: () => "getBranchList",
  getPlotDirection: () => "/regulatory_info/plot_direction/",
  getOrderList: () => "getOrderList",
  getOrderCultivarById: (id: number) =>
    `getOrderCultivarById/${id}`,
  getOrderPlotList: (id?: string | number) =>
    `getOrderPlotList/${id}`,
  getOrderPlotById: (id: number) =>
    `getOrderPlotById/${id}`,
  getCultivarGroupList: () => "/api/regulatory_info/cultivar_group/",
  getCultivarGroupById: (id: number) => `/api/regulatory_info/cultivar_group/${id}/`,
  getIndicatorMethodology: () =>
    `/api/regulatory_info/indicator_methodology/`,
  getCultivarUsageTypeList: () =>
    "getCultivarUsageTypeList",
  getCultivarUsageTypeById: (id: number | string) => `/api/regulatory_info/cultivar_usage_type/${id}/`,
  getVarietyPlanPlotTrial: (id: string | number) =>
    `getVarietyPlanPlot/${id}`,
  getVarietyPlanPlot: (id: string | number) =>
    `getVarietyPlanPlot/${id}`,
  getVarietyPlanPlotNoGroup: (id: string | number) =>
    `getVarietyPlanPlotNoGroup/${id}`,
  getOrderCultivarUsageType: (id: string | number) =>
    `getOrderCultivarUsageType/${id}`,
  getOrderCultivarGroups: (id: string | number) =>
    `getOrderCultivarGroups/${id}`,
  getQuantityDistributionList: () =>
    "getQuantityDistributionList",
  getQuantityDistributionItem: (id: string | number) =>
    `getQuantityDistributionItem/${id}`,
  getRegulatoryInfoUnitList: () =>
    `getRegulatoryInfoUnitList`,
  getVarietyList: () => "/regulatory_info/variety/",
  getVarietyById: (id: number | string) =>
    `/regulatory_info/variety/${id}/`,
  getBudgetaryTrailCalc: () => "/trial/budgetary_trials_calc/",
  getBudgetaryTrailCalcyById: (id: number | string) =>
    `/trial/budgetary_trials_calc/${id}/`,
  getCultivarForVarietyList: () =>  
    "/regulatory_info/cultivar/list_for_varieties/",
  getSeedArrivalList: () =>
    "/agricultural_labour/seed_arrival/",
  getSeedArrivalById: (id: string | number) =>
    `/agricultural_labour/seed_arrival/${id}/`,
  getReproductionList: () => "getReproductions",
  getReproductionById: (id: string | number) =>
    `getReproductionsById/${id}`,
  getAgriculturalSeedArrival: (id: string | number) =>
    `/agricultural_labour/seed_arrival/${id}/`,
  getMaterialTypeById: (id: string | number) =>
    `getMaterialTypeList/${id}`,
  getRemarkList: () => "getRemarkList",
  getRemarkById: (id: number) => `getRemarkById/${id}`,
  getQuantityDistributionCultivars: () =>
    "getQuantityDistributionCultivars",
  getSubjectList: () => "getSubjectList",
  getSubjectById: (id: number) => `getSubjectById/${id}`,
  getOrganizationById: (id: number) =>
    `getOrganizationById/${id}`,
  getOrderById: (id: number) => `getOrderById/${id}`,
  getPlanningPlotById: (id: string | number) =>
    `/planning/plan_plot/${id}/`,
  getMethodologyGroupingList: (id: string | number) =>
    `/planning/plan_plot/${id}/indicator_methodology_for_grouping/`,
  getPlanGroupPlotList: () => `/planning/plan_group_plot/`,
  getVarietyPlanPlotList: (
    groupID: string | number | null,
  ) => `/planning/variety_plan_plot/${groupID}/`,
  getOffbudgetPlanById: (id: string | number) =>
    `getOffbudgetPlanById/${id}`,

  getIndicatorList: () => "getIndicatorList",
  getIndicatorListItem: (id: string | number) =>
    `getIndicatorList/${id}`,
  getIndicatorVariantList: () =>
    `getIndicatorVariantListItem/`,
  getIndicatorVariantListItem: (id: string | number) =>
    `getIndicatorVariantListItem/${id}`,
  getVariantList: () => '/api/regulatory_info/variant/',
  getMethodologyList: () => "getMethodologyList",
  getMethodologyItem: (id: string | number) =>
    `getMethodologyList/${id}`,
  getCultivarField: () => 
    "/agricultural_labour/cultivar_field/",
  getCultivarFieldVarietyList: () =>
    "/agricultural_labour/cultivar_field_variety/",
  getCultivarFieldVarietyLabList: () =>
    "/agricultural_labour/cultivar_field_variety/?trial__trial_type=lab",
  getCultivarFieldVarietyLabListTestingExpertReview: () =>
    "/agricultural_labour/cultivar_field_variety/testing-expert-review",
  getCultivarFieldVarietyById: (id: number | string) =>
    `/agricultural_labour/cultivar_field_variety/${id}/`,
  getCultivarFieldWorkList: () =>
    `/agricultural_labour/cultivar_field_work/`,
  getCultivarFieldWorkById: (id: number | string) =>
    `/agricultural_labour/cultivar_field_work/${id}/`,
  getCultivarFieldWorkChemistryList: () =>
    `/agricultural_labour/cultivar_field_work_chemistry/`,
  getTechMapPlanList: () =>
    `/agricultural_labour/tech_map_plan/`,
  getObservationList: () =>
    `/agricultural_labour/observation/`,
  getObservationById: (id: number | string) =>
    `/agricultural_labour/observation/${id}/`,
  getRegionMain: () => `/regulatory_info/region/main/`,
  getBranchMainById: (id: number | string) =>
    `/regulatory_info/branch/main/${id}/`,
  getBranchMainProductionById: (id: number | string) => `/regulatory_info/branch/main_production/${id}/`,
  getBranchMainTrialsById: (id: number | string) => `/regulatory_info/branch/main_trials/${id}/`,
  getPlotMainById: (id: number | string) =>
    `/regulatory_info/plot/main/${id}/`,
  getPlotMainProductionById: (id: number | string) => `/regulatory_info/plot/main_production/${id}/`,
  getPlotMainTrialsById: (id: number | string) => `/regulatory_info/plot/main_trials/${id}/`,
  getLocationMainById: (id: number | string) =>
    `/agricultural_labour/location/main/${id}/`,
  getLocationMainProductionById: (id: number | string) => `/agricultural_labour/location/main_production/${id}/`,
  getLocationMainTrialsById: (id: number | string) => `/agricultural_labour/location/main_trials/${id}/`,
  getTechMapPlanById: ( id: number | string ) => `/agricultural_labour/tech_map_plan/${id}/`,
  getOffBudgetCultivarsGroupPlotLocation: ( id: number | string ) => `/extrabudgetary/com_cultivar/by_plot_and_location/${id}/`,
  getObservationIndicatorList: () => `/agricultural_labour/observation_indicator/`,
  getObservationDiseaseList: () => `/agricultural_labour/observation_disease/`,
  getObservationWeatherResistanceList: () => `/agricultural_labour/observation_weather_resistance/`,
  getPlotsForRegulatoryInfo: () => `/regulatory_info/plot/plots_for_regulatory_info/`,
  getPlotItemForRegulatoryInfo: (id: number | string) => `/regulatory_info/plot/${id}/plot_for_regulatory_info_by_id/`,
  getPlotsList: () => `/regulatory_info/plot/`,
  getPlotById: (id: number | string) => `/regulatory_info/plot/${id}/`,
  getExpenses: () => `/regulatory_info/expense/`,
  getExpenseById: (id: number | string) => `/regulatory_info/expense/${id}/`,
  getProductivityList: () => `/agricultural_labour/productivity/`,
  getProductivityById: (id: number | string) => `/agricultural_labour/productivity/${id}/`,
  getLabRateList: () => `/agricultural_labour/cultivar_field_variety_rate/`,
  getAmountsContextStatmentById: (id: number | string,type_trial: string,year: string | number) => `registration/statement/${id}/get_amounts_context_statement/?type_trial=${type_trial}&year=${year}`,
  cultivarFieldVariety: () => '/agricultural_labour/cultivar_field_variety/',
  getRates: () => `/regulatory_info/rate/`,
  getRateById: (id: number | string) => `/regulatory_info/rate/${id}/`,
  getSurvey: () => '/api/registration/survey/',
  getWeatherForecastById: (id: number | string) => `/agricultural_labour/location/${id}/get_weather_forecast/`,
  getContextTimePeriodWeatherCalculations: (id: number | string, start_date: string | number, end_date: string | number) => `/agricultural_labour/location/${id}/get_context_time_period_weather_calculations/?start_date=${start_date}&end_date=${end_date}`,
  getWeatherHistoryOneDay: (id: number | string, date: string | number) => `/agricultural_labour/location/${id}/get_context_date_location_weather_history_entry/?date=${date}`,
  getWeatherHistoryForForm: (id: number | string, date: string | number) => `/agricultural_labour/location/${id}/get_context_date_location_weather_history/?date=${date}`,
  getWeatherHistoryById: (id: number | string) => `/weather/weather_history/${id}/`,
  getWeatherHistoryYear: (id: number | string, year: string | number) => `/agricultural_labour/location/${id}/get_context_year_location_weather_history_entry/?year=${year}`,
  getYearWeatherHistoryById: (id: number) => `/weather/year_weather_history/${id}/`,
  getWeatherCultivarFieldWork: () => `api/agricultural_labour/weather_cultivar_field_work`,
  getWeatherCultivarFieldWorkById: (id: number) => `api/agricultural_labour/weather_cultivar_field_work/${id}/`,
  getActiveEffectiveTemp: (id: number | string, start_date: number | string, end_date: number | string, active_temp_context: number | string, base_temp: number | string ) => `/agricultural_labour/cultivar_field/${id}/get_context_active_effective_temperatures/?start_date=${start_date}&end_date=${end_date}&active_temp_context=${active_temp_context}&base_temp=${base_temp}`,
  getAgreementList: () => `/registration/agreement/`,
  getAgreementById: (id: number) => `/registration/agreement/${id}/`,
  getListOfAgreement: () => `/registration/agreement_type/list_for_agreements/`,
  getAgreementType: () => `/registration/agreement_type/`,
  getManualLink: () => '/settings/manual/',
};

/**
 * Подвид испытаний ООС
 */
export const OOS_TYPE = {
  ACCORDING_APPLICANT: "according_applicant", // По данным заявителя
  BASED_ON_TEST_RESULTS: "based_on_test_results", // По результатам испытаний на госсортучастках
  ACCORDING_APPLICANT_SORTING_PLANTS:
    "according_applicant_sorting_plants", // По данным заявителя с одновременной закладкой опытов на госсортучасках
  NOT_CARRIED_OUT: "not_carried_out", //ООС не проводится
  ACCORDING_AUTHORIZED_ORGANIZATION:
    "according_authorized_organization", // По данным уполномоченной организации
};

export const VARIETY_STATUS = {
  SOWED: "sowed",
  NOT_SOWED: "not_sowed",
  AWAIT_SOWING: "await_sowing",
} as const;
export const TRIAL_TYPE = {
  HP: "hp",
  OOS: "oos",
  PHYTO: "phyto",
  LAB: "lab",
} as const;

export const DISTRIBUTION_TYPE = {
  HP: "hp",
  OOS: "oos",
  PHYTO: "phyto",
  FROST: "frost",
  COLLECTION: "collection",
  CUSTOMS: "customs",
  GMO: "gmo",
} as const;

export const ALLOCATION_TITLE = {
  1: "Повторение I",
  2: "Повторение II",
  3: "Повторение III",
  4: "Повторение IV",
} as const;

export const ALLOCATION_TYPE = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
} as const;

export const DISTRIBUTION_ORGANOZATIONS = {
  plot: "plot",
  organization: "organization",
} as const;

export const CULTIVAR_FIELD_WORK_STATUS = {
  PLANNED: "planned",
  EXECUTION: "execution",
  COMPLETED: "completed",
} as const;

export const DISTRIBUTION_ORGANOZATIONS_LABEL = {
  plot: "ГСУ",
  organization: "Организация",
} as const;

export const DISTRIBUTION_TYPE_LABEL = {
  HP: "Хоз. полезность",
  OOS: "ООС",
  PHYTO: "Фитопатология",
  FROST: "Морозоустойчивость",
  COLLECTION: "Коллекция",
  CUSTOMS: "Таможенный контроль",
  GMO: "Испытания на ГМО",
} as const;

export const STATUSES_DISTRIBUTION_KEYS = {
  execution: "execution",
  completed: "completed",
};

export const STATUSES_DISTRIBUTION = {
  execution: "Исполнение",
  completed: "Завершено",
};

export const AUTH_AND_PASS_RECOVER_FORM_STEPS = {
  AUTH_FORM: "authForm",
  EMAIL_FORM: "emailForm",
  CODE_FORM: "codeForm",
  NEW_PASS_FORM: "newPassForm",
};

export const FILES_TYPE = {
  PHOTO: "photo", // Фото
  APPLICATION_FORM: "application_form", // Заявление по форме
  QUESTIONNAIRE: "questionnaire", // Анкета
  DESCRIPTION: "description", // Описание
  PARENTAL_LINE_QUESTIONNAIRE:
    "parental_line_questionnaire", // Анкета на родительские линии
  RIGHT_OF_FILING: "right_of_filing", // Документ, подтверждающий право подачи заявки
  CERTIFICATE_OF_STABILITY: "certificate_of_stability", // Справка на устойчивость
  LETTER_OF_GUARANTEE: "letter_of_guarantee", // Гарантийное письмо
  SEED_QUALITY: "seed_quality", // Документы о сортовых и посевных качествах семян
  VETERINARY_AND_PHYTOSANITARY_SUPERVISION:
    "veterinary_and_phytosanitary_supervision", // Документы по ветеринарному и фитосанитарному надзору
};

export const STATEMENT_TYPE = {
  PATENT_STATEMENT: "patent_statement",
  APPROVAL_STATEMENT: "approval_statement",
} as const;
export const PROFILE_PASS_RECOVER_FORM_STEPS = {
  MAIN_FORM: "mainForm",
  CHANGE_PASS_FORM: "changeForm",
  EMAIL_FORM: "emailForm",
  CODE_FORM: "codeForm",
  NEW_PASS_FORM: "newPassForm",
};

export enum Roles {
  Unauthorized = "unauthorized",
  Guest = "adminGuest",
  Admin = "admin",
  AdminBuisness = "adminBusiness",
  AdminContent = "adminContent",
  AdminPress = "adminPress",
  AdminSales = "adminSales",
  AdminInvestments = "adminInvestments",
  AdminOez = "adminOez",
  Parent = "Parent",
  Student = "Student",
}

export type Role = `${Roles}`;

export const MACHINERY_LABEL = {
  tractor: 'Трактор',
  combine: 'Комбайн',
  attachment: 'Навесное оборудование',
} as const;

export const roleNamesHash = {
  [Roles.Unauthorized]: "Не авторизован",
  [Roles.Guest]: "Не выбрано",
  [Roles.Admin]: "Администратор",
  [Roles.AdminBuisness]: "Администратор-бизнес",
  [Roles.AdminContent]: "Контент-менеджер",
  [Roles.AdminPress]: "Пресс-служба",
  [Roles.AdminSales]: "Управление закупками",
  [Roles.AdminInvestments]: "Департамент инвестиций",
  [Roles.AdminOez]: "Управление ОЭЗ",
};

export const STATUS_CODES = {
  _200: 200,
  _204: 204,
  _400: 400,
  _401: 401,
  _403: 403,
  _404: 404,
  _429: 429,
  _500: 500,
};

export const CHEMISTRY_TYPES = {
  FERTILIZER: "fertilizer", // Удобрение
  PESTICIDE: "pesticide", // Средство защиты
} as const;

export const DEFAULT_LINKS = {
  error_server: "/500",
  not_found: "/404",
  root: "/",
  login: "/login",
  password_reset: "/password-reset-request",
};

export const TOKEN_KEYS = {
  AUTH_TOKEN_KEY: "auth_token",
  REFRESH_TOKEN_KEY: "refresh_token",
  REFRESH_TOKEN_EXPIRY: "refresh_token_expiry",
  ACCESS_TOKEN_EXPIRY: "access_token_expiry",
  SESSION_TOKEN_KEY: "session_token_key",
};

export const SCHEMES_INVALID_TIPS = {
  EMAIL_FORMAT: "Неверный формат e-mail",
  REQUIRED: "Заполните поле",
  OLD_PASSWORD_FAILED: "Указан неверный пароль",
  AUTH_FAILED: "Указан неверный логин или пароль",
  MISMATCH_PASS: "Пароли не совпадают",
  CODE_CHECK_FAILED: "Код введен неверно",
  EMAIL_NOT_FOUND:
    "Указанный e-mail не найден в системе. Проверьте правильность написания или обратитесь к администратору",
};

export const REGEX = {
  SOFT_PASS: /^([!@#$%^&*()\-_=+{};:,<.>a-zA-Z0-9])*$/, // Пароль может содержать только латинские буквы, цифры и спецсимволы
  HARD_PASS: /^.*(?=.{12,})(?=.*\d)((?=.*[A-Z]){1}).*$/, // Пароль должен содержать не менее 12 знаков, включать хотя бы одну заглавную латинскую букву и одну цифру
};

export const ANIMATION_TIME = {
  SMALL: 300,
  MEDIUM: 800,
};

export type FiltersType =
  | "autocomplete"
  | "date"
  | "datesRange"
  | "select"
  | "year"
  | "yearsRange"
  | "yearPicker"
  | "checkbox";

export type SelectOption = {
  value: string | number;
  label: string;
};

export const FILTERS_TYPE = {
  AUTOCOMPLETE: "autocomplete",
  CHECKBOX: "checkbox",
} as const;

export const PAGE_SIZE = 10;

export const SORT: { [key in TOrder]: TOrder } = {
  asc: "asc",
  desc: "desc",
};

export const BENEFIT_VARIANTS = {
  without_benefit: "Без льгот",
  benefit_20: "Льгота 20%",
  benefit_50: "Льгота 50%",
};

export const VARIETY_VARIANTS = {
  variety: "Сорт",
  hybrid: "Гибрид",
};

export const VARIETY_TYPES = {
  common_hybrid: "Простой гибрид",
  trilinear_hybrid: "Трёхлинейный гибрид",
  complex_hybrid: "Сложный гибрид",
  first_generation_hybrid: "Гибрид первого поколения",
  genetically_modified: "Генетически модифицированный",
  cultivated_on_republic_territory:
    "Возделывается на территории республики",
  cross: "Кросс",
  line: "Линия",
  species: "Порода",
  parent_component: "Родительский компонент",
  variety_line_hybrid: "Сортолинейный гибрид",
  variety_population: "Сорт-популяция",
  type: "Тип",
  G_common_hybrid: "Простой гибрид",
  M_trilinear_hybrid: "Трёхлинейный гибрид",
  I_complex_hybrid: "Сложный гибрид",
  C_first_generation_hybrid: "Гибрид первого поколения",
  B_genetically_modified: "Генетически модифицированный",
  A_cultivated_on_republic_territory:
    "Возделывается на территории республики",
  D_cross: "Кросс",
  E_line: "Линия",
  F_species: "Порода",
  H_parent_component: "Родительский компонент",
  J_variety_line_hybrid: "Сортолинейный гибрид",
  K_variety_population: "Сорт-популяция",
  L_type: "Тип",
} as const;

export const KEYS_SIDEBAR_VARIANT_MODULES = {
  PLANNING: "planning",
  PLANNING_HP: "planning_hp",
  STATEMENT: "statement",
  OFFBUDGET: "offbudget",
  OFFBUDGET_SIDEBAR_FACT: "offbudget_fact",
  AGRICULTURAL_WORKS: "agricultural_works",
  AGRICULTURAL_WORKS_FIELDS: "agricultural_works_fields",
  ORDER_CULTIVAR: "order_cultivar",
  CULTIVARS: "cultivars",
  LIGHT_ZONE: "light_zone",
  UNITS: "units",
  QUANTITY: "quantity",
  REGIONS: "regions",
  EMPLOYEE: "employee",
  ADDRESS: "address",
  POSTS: "posts",
  COUNTRIES: "countries",
  DISEASE: "disease",
  MATERIAL_TYPE: "material_type",
  SEED_ARRIVAL: "seed_arrival",
  REMARK: "remark",
  SUBJECT: "subject",
  ORDER: "order",
  REPRODUCTIONS: "reproductions",
  CULTIVAR_FIELD: "cultivar_field",
  CULTIVAR_FIELD_WORK: "cultivar_field_work",
  CULTIVAR_FIELD_VARIETY: "cultivar_field_variety",
  CULTIVAR_FIELD_VARIETY_LAB: "cultivar_field_variety_lab",
  CULTIVAR_FIELD_VARIETY_LAB_TESTING_EXPERT_REVIEW: "cultivar_field_variety_lab_testing_expert_review",
  TECH_WORK_SIDEBAR: "tech_work_sidebar",
  EXPERT_ASSESSMENT: "expert_assessment",
  TECH_MAP: "tеchnologicalMaps"
} as const;

export const HANDBOOK_NAME = {
  TЕCHNOLOGICAL_MAPS: "23"
}

export const ORDER_TYPE = {
  CULTIVAR: "cultivar",
  HP_GROUPING: "hp_grouping",
} as const;

export const STATUSES_TRIAL_KEYS = {
  completed: "completed",
  planned: "planned",
  created: "created"
} as const;

export const STATUSES_CULTIVAR_FIELD_VARIETY_KEYS = {
  OBSERVATION: "observation", // наблюдение
  NOT_SOWED: "not_sowed", // не заложено
  ABORTED: "aborted", // прервано
  COMPLETED: "completed", // выполнено
  NOT_COMPLETED: "not_completed", // невыполнено
  PLANNED: "planned" // Запланировано,
} as const;

export const STATUSES_UPLOAD_STATUS_KEYS = {
  FAILED : "failed", // ошибка
  COMPLETED: "completed", // выполнено
  PROCESSING: "processing", // обработка
} as const;
export const STATUSES_CULTIVAR_FIELD_VARIETY_LIST = [
  {
    key: STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.OBSERVATION,
    label: "Наблюдение",
  },
  {
    key: STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.NOT_COMPLETED,
    label: "Не выполнено",
  },
  {
    key: STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.COMPLETED,
    label: "Выполнено",
  },
  {
    key: STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.PLANNED,
    label: "Запланировано",
  },
];

export const STATUSES_TRIAL = {
  planned: "Запланировано",
  completed: "Завершено",
  created: "Создано",
} as const;

export const TRIAL_STATUS_COLORS: {
  [key: string]: IColorsStatus;
} = {
  [STATUSES_TRIAL_KEYS.completed]: {
    background: theme.palette.blackAndWhite.grayLight,
    text: theme.palette.blackAndWhite.black,
  },
  [STATUSES_TRIAL_KEYS.created]: {
    background: theme.palette.blackAndWhite.grayLight,
    text: theme.palette.blackAndWhite.black,
  },
  [STATUSES_TRIAL_KEYS.planned]: {
    background: theme.palette.blackAndWhite.grayLight,
    text: theme.palette.blackAndWhite.black,
  },
} as const;

export const LOCATION_KEY = {
  DEFAULT: "default",
} as const;

export const MAX_NUMBERS = {
  MAX: 999_999_999,
  MAX_PERCENT: 100,
};

export const MIN_NUMBERS = {
  MIN: 0,
  ONE: 1,
  MIN_DATE: 1900,
};

export const BROWSER_TYPE = {
  opera: "Opera",
  microsoftEdge: "Microsoft Edge",
  googleChrome: "Google Chrome",
  mozillaFirefox: "Mozilla Firefox",
  appleSafari: "Apple Safari",
  internetExplorer: "Microsoft Internet Explorer",
  UCBrowser: "UC Browser",
  samsungBrowser: "Samsung Browser",
  unknownBrowser: "Unknown browser",
};

export const PLANNING_STATUS_TYPE = {
  APPROVED: "approved",
  DRAFT: "draft",
} as const;

export const VEGETATION_CAUSE_TYPE = {
  FAILURE: "failure",
  REJECTION: "rejection",
  DEATH: "death"
} as const

export const DOWNLOAD_TRIALS_PLAN_HP_TYPES = {
  BRANCH: "branch",
  REGION: "region",
  CULTIVAR: "cultivar"
} as const

export const DOWNLOAD_SEED_ARRIVAL= {
  JOURNAL: "journal",
  DEFAULT: "default"
} as const

export const PLAN_TYPE: { [key: string]: TPlanFuct } = {
  PLAN: "plan",
  FACT: "fact",
} as const;

/**
 * @constant
 * Значения меню сайдбара
 */
export const NAVBAR_LABELS = {
  main: "Главная",
  statements: "Заявки",
  planning: "Планирование",
  agriculturalWork: "Карта ГСУ",
  agriculturalWorkDoc: "С/X работы",
  offBudget: "Производство",
  handbook: "Справочники",
  reports: "Отчеты",
  profile: "Профиль",
};

export const VARIETY_TYPE = {
  STANDART: "standart",
  TRIAL: "trial",
  SIMILAR: "similar",
  REFERENCE: "reference",
} as const;
