import { resInterface } from "@/api/interfaces";
import { PRODUCT_FORM_TYPES } from "@/apps/Handboock/templates/CultivarUsageType/cells";
import yup from "@/utils/yup";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    cultivarGroupId?: number;
    initialValues?: resInterface.ICultivarGroups;
  };
  onSubmit?: () => void;
}

export  interface IValue {
  id: number,
}

export interface TForm {
  [CULTIVAR_GROUP_FIELD_NAME.USAGE_TYPE]: IValue,
  [CULTIVAR_GROUP_FIELD_NAME.VARIANT]: IValue,
}

export enum CULTIVAR_GROUP_FIELD_NAME {
  USAGE_TYPE = 'usage_type',
  VARIANT = 'variant'
}

export const mapValueToForm = (
  value: resInterface.ICultivarGroups,
) => {
  return {
    [CULTIVAR_GROUP_FIELD_NAME.USAGE_TYPE]:
      value?.usage_type ?
        {
          id: value?.usage_type?.id,
          label: `${value?.usage_type?.cultivar?.name} - ${PRODUCT_FORM_TYPES[value?.usage_type?.product_form]} - ${value.usage_type?.name}`
        } : undefined,
    [CULTIVAR_GROUP_FIELD_NAME.VARIANT]:
      value?.variant ?
        {
          id: value?.variant?.id,
          label: `${value?.variant?.indicator?.name} - ${value?.variant?.severity}`
        } : undefined,
  };
}

export const mapFormToValue = (values: TForm) => {
  return {
    [CULTIVAR_GROUP_FIELD_NAME.USAGE_TYPE]: values?.usage_type?.id,
    [CULTIVAR_GROUP_FIELD_NAME.VARIANT]: values?.variant?.id,
  };
};


/*Validation of the form*/

export const validationSchema = yup.object().shape({
  [CULTIVAR_GROUP_FIELD_NAME.USAGE_TYPE]: yup.mixed().required(),
  [CULTIVAR_GROUP_FIELD_NAME.VARIANT]: yup.mixed().required(),
});
