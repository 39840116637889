import React, { useState } from "react";
import { IFormik, INDICATOR_TYPE } from "../const";
import { Field, Form, useFormikContext } from "formik";
import { FIELD_NAME } from "../const";
import { Alert, Box, Button, Divider, FormControl, FormHelperText, Stack } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { TRIAL_TYPE } from "@/const";
import { useInfiniteScroll, useStores } from "@/hooks";
import { IAgriculturalLabourWork, IIndicator } from "@/api/interfaces/responses";
import Autocomplete from "@/components/Autocomplete";
import { IndicatorMethodologyAutocomplete, IndicatorVariantMethodologyAutocomplete } from "@/components/Autocompletes";
import useSWR from "swr";

interface IProps {
  handleClose: () => void;
  modalProps: {
    indicatorType: string;
    varietyId: number;
  };
}

const AddVarietyIndicatorForm: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {

  const { api } = useStores();
  const [search, setSearch] = useState("");
  const formik = useFormikContext<IFormik>();
  const { indicatorType, varietyId } = modalProps;
  const isTrialTypeOOS = indicatorType === INDICATOR_TYPE.morphological;
  const isTrialTypeHP = indicatorType === INDICATOR_TYPE.economically_valuable;


  const handleChangeMethodology = (_, child) => {
    void formik.setFieldValue(FIELD_NAME.METHODOLOGY, child);
    formik.setFieldValue(FIELD_NAME.INDICATOR_METHODOLOGY, "");
    void formik.setFieldValue(FIELD_NAME.VARIANT_INDICATOR_METHODOLOGY, "");
  };

  const handleClearMethodology = () => {
    void formik.setFieldValue(FIELD_NAME.METHODOLOGY, "")
    void formik.setFieldValue(FIELD_NAME.INDICATOR_METHODOLOGY, "");
    void formik.setFieldValue(FIELD_NAME.VARIANT_INDICATOR_METHODOLOGY, "");
  };

  const handleChangeIndicatorMethodology = (payload: IIndicator) => {
    void formik.setFieldValue(FIELD_NAME.INDICATOR_METHODOLOGY, payload);
    void formik.setFieldValue(FIELD_NAME.VARIANT_INDICATOR_METHODOLOGY, "");
  };

  const handleClearIndicatorMethodology = () => {
    void formik.setFieldValue(FIELD_NAME.INDICATOR_METHODOLOGY, "");
    void formik.setFieldValue(FIELD_NAME.VARIANT_INDICATOR_METHODOLOGY, "");
  };

  const { data: variety } = useSWR(
    String(varietyId),
    () => api.regulatoryInfo.getVarietyById(varietyId)
  );

  const getKey = (index: number) => ({
    _key: String(varietyId),
    page: index + 1,
    search: search ?? undefined,
    type_trial: isTrialTypeOOS ? TRIAL_TYPE.OOS : TRIAL_TYPE.HP,
    cultivar: variety?.cultivar?.id,
  });

  const { data: methodologyData, handleScroll, isEmpty } =
    useInfiniteScroll(
      getKey,
      api.regulatoryInfo.getMethodologyList,
      {
        revalidateFirstPage: false,
      },
    );

  const economicallyValuableFilter = {
    methodology: methodologyData[0]?.id,
    type_trial_indicator: TRIAL_TYPE.HP,
    type_trial_methodology: TRIAL_TYPE.HP,
  }

  const queryIndicatorMethodology = { methodology: formik.values?.[FIELD_NAME.METHODOLOGY]?.id }

  const getLabel = (el: IAgriculturalLabourWork) => el.name;

  return (

    <Form noValidate>
      <Stack
        gap={2}
        p={3}
      >
        {isTrialTypeOOS &&
          <Field name={FIELD_NAME.METHODOLOGY}>
            {({ field, meta }) => (
              <FormControl fullWidth required>
                <Autocomplete
                  {...field}
                  fullWidth
                  getLabel={getLabel}
                  onChange={handleChangeMethodology}
                  onClear={handleClearMethodology}
                  label="Методика"
                  required
                  data={methodologyData}
                  handleScroll={handleScroll}
                  search={setSearch}
                  error={meta.touched && !!meta.error}
                />
                {isEmpty && (
                  <Alert sx={{ mt: 2 }} severity="warning">
                    Справочник Методики не заполнен по
                    данной культуре
                  </Alert>
                )}
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>}

        <IndicatorMethodologyAutocomplete
          key={"1"}
          name={FIELD_NAME.INDICATOR_METHODOLOGY}
          handleChange={handleChangeIndicatorMethodology}
          onClear={handleClearIndicatorMethodology}
          q={
            isTrialTypeOOS
              ? queryIndicatorMethodology
              : economicallyValuableFilter
          }
          disabled={
            isTrialTypeHP && isEmpty ||
            isTrialTypeOOS && !formik.values?.[FIELD_NAME.METHODOLOGY]?.id
          }
          required />
        {isTrialTypeHP && isEmpty && (
          <Alert severity="warning">
            Справочник Методики не заполнен по
            данной культуре
          </Alert>
        )}

        <IndicatorVariantMethodologyAutocomplete
          key={"22"}
          name={FIELD_NAME.VARIANT_INDICATOR_METHODOLOGY}
          q={{ id: formik.values[FIELD_NAME.INDICATOR_METHODOLOGY]?.id }}
          disabled={!formik.values[FIELD_NAME.INDICATOR_METHODOLOGY]}
          required />
      </Stack>
      <Divider />
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          Отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          disabled={formik.isSubmitting}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default AddVarietyIndicatorForm;
