import React from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import theme from "@/theme";
import {
  QUERY_STRING_MICRO_ROUTS_CULTIVAR,
  RIGHT_SIDEBAR_TYPES,
  RIGHT_SIDEBAR_TYPES_LEVELS,
} from "@/apps/RightSidebar/const";
import { KEYS_SIDEBAR_VARIANT_MODULES } from "@/const";
import { useStores } from "@/hooks";
import useRightSidebar from "@/hooks/useRightSidebar";
import { CultureMenu } from "./MenuCulture";
import { MutatorCallback } from "swr/_internal";
import { IOBCultivar } from "@/api/interfaces/responses";
import qs from "qs";

interface ICulturesItemProps {
  renderData: IOBCultivar;
  planId: number;
  mutate: MutatorCallback;
  isDetailOpen?: boolean;
  isHidden?: boolean;
  locationID?: number;
  isRenderInTheForm?: boolean;
}

export const CulturesItem: React.FC<ICulturesItemProps> = (
  props,
) => {
  const {
    planId,
    renderData,
    mutate,
    isDetailOpen = true,
    isHidden = false,
    locationID,
    isRenderInTheForm = false,
  } = props;

  const { queryStringSidebarCollector } = useStores();
  const { handleOpen } = useRightSidebar();
  const [, setSearchParams] = useSearchParams();
  
  const EMPTY_FIELD = "-";

  const handleClick = () => {
    if (isRenderInTheForm) return; 
    if (isDetailOpen) {
      const { str } = queryStringSidebarCollector.setup({
        tab: RIGHT_SIDEBAR_TYPES_LEVELS[
          RIGHT_SIDEBAR_TYPES.CULTIVAR
        ].PRODUCTION,
        inner_tab:
          QUERY_STRING_MICRO_ROUTS_CULTIVAR.SEEDS.path,
        keyDetail: RIGHT_SIDEBAR_TYPES.CULTIVAR,
        keyContent: KEYS_SIDEBAR_VARIANT_MODULES.OFFBUDGET,
        module_id: planId,
        detail_id: renderData.id,
      });
      handleOpen({
        type: RIGHT_SIDEBAR_TYPES.CULTIVAR,
        modalProps: {
          id: planId,
          detail_id: renderData.id,
        }
      });
      setSearchParams(str);
      const params = qs.parse(str)
      setSearchParams(qs.stringify({...params, location: locationID}));
    }
  };

  return (
    <Grid
      sx={{
        marginBottom: "10px",
        padding: "18px 16px",
        border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
        borderRadius: "4px",
        width: "inherit",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <Grid
        container
        pb={1}
        justifyContent={"space-between"}
      >
        <Typography variant="h4">
          {renderData.cultivar.name}
        </Typography>
        {isHidden ? null : (
          <CultureMenu
            planId={planId}
            data={renderData}
            mutate={mutate}
            locationID={locationID}
          />
        )}
      </Grid>
      <Grid
        container
        alignItems={"flex-start"}
        pr={8}
        gap={6}
      >
        <Box>
          <Typography
            variant="body2"
            fontWeight={400}
            fontSize={12}
            lineHeight={"120%"}
            sx={{ color: theme.palette.blackAndWhite.gray }}
          >
            Объём работ, га:
          </Typography>
          <Typography
            fontWeight={400}
            lineHeight={"120%"}
            variant="body2"
          >
            {renderData.acreage || EMPTY_FIELD}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body2"
            fontWeight={400}
            fontSize={12}
            lineHeight={"120%"}
            sx={{ color: theme.palette.blackAndWhite.gray }}
          >
            Себестоимость, руб.:
          </Typography>
          <Typography
            fontWeight={400}
            lineHeight={"120%"}
            variant="body2"
          >
            {renderData.total_cost || EMPTY_FIELD}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
