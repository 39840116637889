import React, { useEffect } from "react";
import { AxiosError } from "axios";
import qs from "qs";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  IconButton,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import { IPlanning } from "@/api/interfaces/responses";
import {
  PLANNING_TRIALS_TYPES_COLOR,
  PLANNING_TRIALS_TYPES_ICON,
  PLANNING_TRIALS_TYPES,
  EDITING_CELL,
  PLANNING_STATUS_NAME,
  PLANNING_ENTITY_NAME,
} from "../const";
import { nameAuthorCellsStyle } from "../style";
import dayjs from "dayjs";
import {
  StyledMenuItem
} from "@/components/StyledMenuItem";
import { buttonDotsStyle } from "@/apps/Table/style";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DeleteIcon } from "@/components/icons";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import {
  CELL_TYPES
} from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { useQuery, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import PlanningStatusChip
  from "@/components/Chips/PlanningStatusChip";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

/**
 * @component NumberCells
 * * компонент ячейки таблицы номера заявки
 * @param
 */

export const NumberCells = (
  _: string,
  storage: IPlanning,
): JSX.Element => {
  return (
    <Box>
      <Typography variant="body2">
        {storage.number}
      </Typography>
    </Box>
  );
};

/**
 * @component TrialTypeCells
 * * компонент ячейки таблицы Типа Испытаний
 * @param
 */

export const TrialTypeCells = (
  _: string,
  storage: IPlanning,
): JSX.Element => {
  const trialType = storage.trial_type;
  const trialTypeText = trialType
    ? PLANNING_TRIALS_TYPES[trialType]
    : "";
  const trialTypeIcon = trialType
    ? PLANNING_TRIALS_TYPES_ICON[trialType]
    : "";
  const trialTypeColors = trialType
    ? PLANNING_TRIALS_TYPES_COLOR[trialType]
    : "";

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
    >
      <img
        src={trialTypeIcon}
        height={15}
        width={15}
        alt="иконка"
      />
      <Typography
        variant="body2"
        sx={{ color: trialTypeColors }}
      >
        {trialTypeText}
      </Typography>
    </Stack>
  );
};

/**
 * @component CultivarCells
 * * компонент ячейки таблицы наименование культуры
 * @param
 */

export const CultivarCells = (
  _: string,
  { cultivar }: IPlanning,
): JSX.Element => {
  return (
    <Box sx={nameAuthorCellsStyle()}>
      <Typography variant="body2">
        {cultivar.name}
      </Typography>
    </Box>
  );
};

/**
 * @component BranchCell
 * * компонент ячейки таблицы филиал
 * @param
 */

export const BranchCells = (
  _: string,
  { branch }: IPlanning,
): JSX.Element => {
  return (
    <Box>
      <Typography
        variant="body2"
        sx={{
          maxWidth: "300px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {branch?.name}
      </Typography>
    </Box>
  );
};

/**
 * @component DateCells
 * * компонент ячейки таблицы дата создания заявки
 * @param
 */

export const DateCells = (
  _: string,
  storage: IPlanning,
): JSX.Element => {
  const date = dayjs(storage.created_at).format(
    "DD.MM.YYYY",
  );

  return (
    <Box>
      <Typography variant="body2">{date}</Typography>
    </Box>
  );
};

/**
 * @component StatusCells
 * * компонент ячейки таблицы статуса плана
 * @param
 */

export const StatusCells = (
  _: string,
  storage: IPlanning,
): JSX.Element => {
  return (
    <PlanningStatusChip status={storage.status}/>
  );
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCells = (
  _: string,
  storage: IPlanning,
  itemsInPage: any[],
): JSX.Element => {
  const status = storage.status;
  const { 
    api, 
    modalStore, 
    swrStore, 
    userStore 
  } = useStores();
  const [anchorEl, setAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: "true" },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);

  const mutateTable =
    swrStore.mutators[SWR_KEYS.getPlanningTable()];

  const mutateWithCheckingLast = () => {
    // если один эл-т на странице перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateTable && mutateTable();
    }
  };

  const handleDeletePlanning = (
    resolve: () => void,
    reject: (error: AxiosError) => void,
  ) => {
    api.planning
      .deletePlanningById(storage.id)
      .then(() => {
        mutateWithCheckingLast();
        resolve();
      })
      .catch(reject)
      .finally(() => setAnchorEl(null));
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.universal,
      id: storage.id,
      entityName: PLANNING_ENTITY_NAME,
      handleDelete: handleDeletePlanning,
    };

    modalStore.open(
      VARIANT_MODAL.OK_OR_NOT_DELETE,
      modalProp,
    );
  };

  const canDeletePlan = userStore.isPermission(
    PERMISSIONS.planningPlan,
    PERMISSION_CRUD.delete
  );

  if (!canDeletePlan) return <></>;

  if (status === PLANNING_STATUS_NAME.approved)
    return <></>;

  return (
    <Box>
      <IconButton
        sx={buttonDotsStyle()}
        onClick={handleToggle}
      >
        <MoreVertIcon/>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={handleClickDelete}>
          <DeleteIcon color="error"/>
          <Typography color="error">
            {" "}
            {EDITING_CELL.delete}
          </Typography>
        </StyledMenuItem>
      </Menu>
    </Box>
  );
};
