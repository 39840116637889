import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell } from "@/apps/Handboock/cells";
import { cellButtons, cellPointText, headerCellStyle } from "../style";

import { ADDRESS_HEADER_LABELS, ADDRES_KEYS } from "../const";

import { TAddressKeys } from "../interfaces";
import { EditingCellsAddress } from "../templates/Address/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: ""
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const AddressColumns: IColumn<TAddressKeys>[] = [
  {
      label: ADDRESS_HEADER_LABELS.one_line,
      key: ADDRES_KEYS.one_line,
      contentHeader: ADDRESS_HEADER_LABELS.one_line,
      bodyCellProps: {
        render: PointerTextCell,
        styles: cellPointText,
      },
      headerCellProps: {
        styles: headerCellStyle,
      },
    },
  {
    key: "editing",
    label: ADDRESS_HEADER_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellsAddress,
      styles: cellButtons,
    },
  },
];
