import { SxProps, Theme } from "@mui/material/styles";

export const tableWrapperStyle = (theme: Theme): SxProps => ({
  width: "100%",
  padding: theme.spacing(3),
});

export const cellNumber = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
  color: theme.palette.blackAndWhite.gray,
  whiteSpace: "nowrap",
});

export const cellText = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
  whiteSpace: "nowrap",
});

export const cellStatus = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
});

export const headerCellStyle = (theme: Theme): SxProps => ({
  whiteSpace: "nowrap",
  color: theme.palette.blackAndWhite.black,
  fontWeight: "500",
});

export const textCellsStyle = (): SxProps => ({
  display: "flex",
  alignItems: "center",
  gap: "6px",
});

export const styledButton = (theme: Theme) => ({
  background: "transparent",
  border: "1px solid",
  borderColor: theme.palette.blue.lightBlue,
  color: theme.palette.blue.lightBlue,
  padding: "5px 17px",
  '&:hover': {
    color: theme.palette.blue.lightBlue,
    backgroundColor: theme.palette.blue.hoverBlue,
    borderColor: theme.palette.blue.hoverBlue,
  },
});