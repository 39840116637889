import React, { Fragment, ReactElement, useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Table from "@/apps/Table";
import { observer } from "mobx-react";
import { useQuery, useRightSidebar, useStores } from "@/hooks";
import { IPaginatedResult } from "@/api/interfaces/responses";
import { DEFAULT_SIZE_PAGE } from "@/components/Pagination/const";
import { SWR_KEYS } from "@/const";
import { IErrorResponse } from "@/interfaces";
import useSWR, { SWRResponse } from "swr";
import { isUndefined } from "swr/_internal";
import { IPropsSidebar, TOrgKeys } from "../../interfaces";
import AddIcon from "@mui/icons-material/Add";
import qs from "qs";
import Filters from "@/components/Filters";
import { Box, Button } from "@mui/material";

import { IFilter } from "@/interfaces";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { resInterface } from "@/api/interfaces";
import { OrgColumns } from "../../models/organizations";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

export const getFilters = (): IFilter[] => {
  return [];
};

/**
 * Компонент, представляющий таблицу организаций
 * @returns {ReactElement}
 */
const OrgTable: React.FC<IPropsSidebar> = ({
  handleOpenRightSidebar,
}): ReactElement => {
  const { api, swrStore, modalStore, userStore } = useStores();
  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [queryParams, setQueryParams] = useState<any>(query);

  // закрытие правого сайдбара при переходе на другую страницу
  const { handleCloseInstantly } = useRightSidebar();

  useEffect(() => {
    return () => {
      handleCloseInstantly();
    };
  }, []);
  
  // переменная которая показывает применены ли фильтры
  const isFilters: boolean = Object.keys(queryParams).length > 0;
  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orderingArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering) ? query.ordering : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] = useState<string[]>(orderingArray);

  const {
    data,
    error,
    isLoading,
    mutate,
  }: SWRResponse<IPaginatedResult<resInterface.IOrganization>, IErrorResponse> = useSWR(
    {
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      search: queryParams.search,
      key: SWR_KEYS.getOrganizationList(),
    },
    api.statement.getOrganizationList,
  );

  const filters = getFilters();

  const handleChangeOrg = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleOpenCreateOrgModal = () => {
    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.ORGANIZATION, { theme: THEME_MODAL.W_555 });
  };

  const handleChangeOrderBy = (value) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  useEffect(() => {
    setQueryParams(query);
  }, [location.search]);

  swrStore.addMutator(SWR_KEYS.getOrganizationList(), mutate);

  const canAddOrganization = userStore.isPermission(
    PERMISSIONS.registrationOrganizationDischarge,
    PERMISSION_CRUD.add
  )

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Filters
          filters={filters}
          defaultSearch={query.search}
        />
        {canAddOrganization && 
        <Button
          sx={{
            whiteSpace: "nowrap",
            flexShrink: "0",
          }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenCreateOrgModal}
        >
          Добавить запись
        </Button>
        }
      </Box>

      <Table<resInterface.IOrganization, TOrgKeys>
        columns={OrgColumns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangeOrg}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={"Добавить запись"}
        textEmptyTableWithFilter={
          "Не найдено ни одной записи"
        }
        textEmptyTable={"Еще не создано ни одной записи"}
        onRowClick={handleOpenRightSidebar}
        emptyTableAddEntity={handleOpenCreateOrgModal}
        overflowY="scroll"
      />
    </Fragment>
  );
};

export default observer(OrgTable);
