import React from "react";
import { IIcon } from "./interfaces";

const DownloadIcon = ({ className }: IIcon): React.ReactElement => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="13.333344"
      viewBox="0 0 13.3334 13.3333"
      fill="currentColor"
    >
      <path 
        id="download" 
        d="M6.66 10L2.5 5.83L3.66 4.62L5.83 6.79L5.83 0L7.5 0L7.5 6.79L9.66 4.62L10.83 5.83L6.66 10ZM1.66 13.33C1.2 13.33 0.81 13.17 0.48 12.84C0.16 12.51 0 12.12 0 11.66L0 9.16L1.66 9.16L1.66 11.66L11.66 11.66L11.66 9.16L13.33 9.16L13.33 11.66C13.33 12.12 13.17 12.51 12.84 12.84C12.51 13.17 12.12 13.33 11.66 13.33L1.66 13.33Z" 
        fill="currentColor" 
        fillOpacity="1.000000" 
        fillRule="nonzero" />
    </svg>
  );
};

export default DownloadIcon;