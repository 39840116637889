import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Tab } from "@mui/material";

import { ROUTES_PATHS } from "@/apps/AppRouter/const";

import { StyledTabs, tabStyles } from "./style";

import { PLANNING_NAVS } from "../const";
import {
  PERMISSIONS,
  PERMISSION_CRUD
} from "@/premissions";
import { useStores } from "@/hooks";

// /**
//  * Возвращает компонент для заголовка раздела "Планирование".
//  * @returns React.FC Возвращает React-элемент для отображения заголовка раздела "Планирование".
//  */

const HeaderAgriculturalWorkDoc: React.FC = () => {
  const location = useLocation();
  const { userStore } = useStores()
  const [value, setValue] = React.useState(location.pathname);

  const canViewPlanning = userStore.isPermission(
    PERMISSIONS.planningPlan, 
    PERMISSION_CRUD.view
  );

  const canViewPlanningEA = userStore.isPermission(
    PERMISSIONS.planningEAPlan, 
    PERMISSION_CRUD.view
  );

  const canViewPlanningCrop = userStore.isPermission(
    PERMISSIONS.regulatory_infoOrderCultivar,
    PERMISSION_CRUD.view
  );

  const canViewPlanningPlanHP = userStore.isPermission(
    PERMISSIONS.planningPlanHP, 
    PERMISSION_CRUD.view
  );
  
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const tabs = [
    {
      value: ROUTES_PATHS.planning,
      label: PLANNING_NAVS.planning,
      to: ROUTES_PATHS.planning,
      canView: canViewPlanning,
    },
    {
      value: ROUTES_PATHS.planning_expert_assessment,
      label: PLANNING_NAVS.expertAssessment,
      to: ROUTES_PATHS.planning_expert_assessment,
      canView: canViewPlanningEA,
    },
    {
      value: ROUTES_PATHS.planning_crop,
      label: PLANNING_NAVS.crop,
      to: ROUTES_PATHS.planning_crop,
      canView: canViewPlanningCrop,
    },
    {
      value: ROUTES_PATHS.planningHp,
      label: PLANNING_NAVS.plan_hp,
      to: ROUTES_PATHS.planningHp,
      canView: canViewPlanningPlanHP,
    },
  ];

  const filteredTabs = tabs.filter((tab => tab.canView));

  useEffect(() => {
    if (value !== location.pathname) {
      setValue(location.pathname);
    }
  }, [location.pathname]);

  return (
    <StyledTabs
      value={value}
      onChange={handleChange}
      variant="scrollable"
    >
      {
        filteredTabs.map(tab => (
          <Tab 
            key={tab.value}
            component={NavLink}
            value={tab.value}
            label={tab.label}
            to={tab.to}
            sx={tabStyles}
          />
        ))
      }
    </StyledTabs>
  );
};

export default HeaderAgriculturalWorkDoc;
