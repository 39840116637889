import yup from "@/utils/yup";

import { reqInterface } from "@/api/interfaces";

import { IEntity, IOffBudgetCultivar } from "@/api/interfaces/responses";
import {
  commaStringToNumber,
  isObject,
  isUndefined,
} from "@/utils/helpers";

export interface IPropsAddCulture {
  handleClose: () => void;
  branchID?: number;
  modalProps: {
    key: string;
    cultivarId?: number;
    planId: number;
    locationID?: number;
    branch?: number;
    initialValues?: {
      cultivar?: { id: number; name: string };
      hasLocation: boolean;
    };
  };
  onSubmit?: () => void;
}

export type TForm = Omit<
  reqInterface.IOBCultivar,
  | "cultivar" 
  | "branch"
  | "plot"
  | "location"
  > & {
  cultivar: {
    id: number;
    name: string;
  };
  branch: IEntity;
  plot: IEntity;
  location: IEntity;
};

export enum CULTIVAR_FIELD_NAME {
  COM_PLAN_FACT = "com_plan_fact",
  CULTIVAR = "cultivar",
  ACREAGE = "acreage",
  PRODUCTIVITY = "productivity",
  CROPPAGE = "croppage",
  SALES_VOLUME = "sales_volume",
  PRICE = "price",
  SEEDING_RATE = "seeding_rate",
  SEEDS_NEEDS = "seeds_needs",
  SEEDS_AVAILABLE = "seeds_available",
  SEEDS_PRICE = "seeds_price",
  LOCATION = "location",
  PLOT = "plot",
}

export const setValueToForm = (
  value: IOffBudgetCultivar,
  planId: number,
) => {
  return Object.entries(CULTIVAR_FIELD_NAME).reduce(
    (formData, [, fieldEnum]) => {
      const fieldValue = !isUndefined(value[fieldEnum])
        ? value[fieldEnum]
        : planId;

      if (!isObject(fieldValue)) {
        formData[fieldEnum] = fieldValue
          ? commaStringToNumber(String(fieldValue))
          : null;
      } else {
        formData[fieldEnum] = fieldValue;
      }

      return formData;
    },
    {},
  );
};

export const validationSchema = yup.object().shape({
  [CULTIVAR_FIELD_NAME.CULTIVAR]: yup.mixed().required(),
  [CULTIVAR_FIELD_NAME.LOCATION]: yup.mixed().required(),
  [CULTIVAR_FIELD_NAME.ACREAGE]: yup.number().nullable(),
  [CULTIVAR_FIELD_NAME.PRODUCTIVITY]: yup.number().nullable(),
  [CULTIVAR_FIELD_NAME.CROPPAGE]: yup.number().nullable(),
  [CULTIVAR_FIELD_NAME.SALES_VOLUME]: yup.number().nullable(),
  [CULTIVAR_FIELD_NAME.PRICE]: yup.number().nullable(),
  [CULTIVAR_FIELD_NAME.SEEDING_RATE]: yup.number().nullable(),
  [CULTIVAR_FIELD_NAME.SEEDS_NEEDS]: yup.number().nullable(),
  [CULTIVAR_FIELD_NAME.SEEDS_AVAILABLE]: yup.number().nullable(),
  [CULTIVAR_FIELD_NAME.SEEDS_PRICE]: yup.number().nullable(),
});
