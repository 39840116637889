import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  IBranchMainProductionValue
} from "@/api/interfaces/responses";

interface IProps {
  data: IBranchMainProductionValue[];
}

const titleNames = {
  total: "Итого"
};

const ProductionTable: React.FC<IProps> = ({ data }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none" }}
    >
      <Table>
        <TableHead>
          <TableRow>

            <TableCell>
              Культура
            </TableCell>

            <TableCell align="center" colSpan={2}>
              Площадь, га
            </TableCell>
            <TableCell />

            <TableCell align="center" colSpan={2}>
              Урожай, ц
            </TableCell>
            <TableCell />

            <TableCell align="center" colSpan={2}>
              Урожайность, ц/га
            </TableCell>
            <TableCell />

            <TableCell align="center" colSpan={2}>
              Прибыль, руб.
            </TableCell>
            <TableCell />

            <TableCell align="center" colSpan={2}>
              Цена продажи, руб./т
            </TableCell>

          </TableRow>
          <TableRow>

            <TableCell />

            <TableCell align="center">
              План
            </TableCell>
            <TableCell align="center">
              Факт
            </TableCell>
            <TableCell />

            <TableCell align="center">
              План
            </TableCell>
            <TableCell align="center">
              Факт
            </TableCell>
            <TableCell />

            <TableCell align="center">
              План
            </TableCell>
            <TableCell align="center">
              Факт
            </TableCell>
            <TableCell />

            <TableCell align="center">
              План
            </TableCell>
            <TableCell align="center">
              Факт
            </TableCell>
            <TableCell />

            <TableCell align="center">
              План
            </TableCell>
            <TableCell align="center">
              Факт
            </TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            const sx = { fontWeight: row.name === "total" ? 700 : 400 };
            return (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row" sx={sx}>
                  {titleNames[row.name] || row.name}
                </TableCell>

                <TableCell align="center" sx={sx}>
                  {row.acreage}
                </TableCell>
                <TableCell align="center" sx={sx}>
                  {row.acreage_fact}
                </TableCell>
                <TableCell />

                <TableCell align="center" sx={sx}>
                  {row.croppage}
                </TableCell>
                <TableCell align="center" sx={sx}>
                  {row.croppage_fact}
                </TableCell>
                <TableCell />

                <TableCell align="center" sx={sx}>
                  {row.productivity}
                </TableCell>
                <TableCell align="center" sx={sx}>
                  {row.productivity_fact}
                </TableCell>
                <TableCell />

                <TableCell align="center" sx={sx}>
                  {row.profit}
                </TableCell>
                <TableCell align="center" sx={sx}>
                  {row.profit_fact}
                </TableCell>
                <TableCell />

                <TableCell align="center" sx={sx}>
                  {row.price}
                </TableCell>
                <TableCell align="center" sx={sx}>
                  {row.price_fact}
                </TableCell>

              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductionTable;
