import React from "react";
import { AddEntities } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { PAGE_SIZE } from "@/const";
import { resInterface } from "@/api/interfaces";
import {
  IDiseaseList,
  IEntity,
} from "@/api/interfaces/responses";
import {
  PERMISSION_CRUD,
  PERMISSIONS
} from "@/premissions";
import { VARIANT_MODAL } from "@/apps/Modals/const";

export interface IProps {
  _key,
  handleClose: () => void;
  modalProps: {
    cultivar: IEntity;
    initialValue?: IDiseaseList[];
    onSubmit?: (value: IDiseaseList[]) => void;
  };
}

const SelectDiseases: React.FC<IProps> = ({
  _key,
  handleClose,
  modalProps
}) => {
  const { api, userStore, modalStore } = useStores();
  const handleAccept = (value: IDiseaseList[]) => {
    modalProps.onSubmit && modalProps.onSubmit(value);
    handleClose();
  };

  const fetcher = (args) => {
    const { page, search, cultivar, first_ids } = args;
    return api.regulatoryInfo.getDiseaseList({
      page,
      page_size: PAGE_SIZE,
      search,
      cultivar,
      first_ids
    });
  };

  const handleAdd = (payload: resInterface.IDiseaseList[]) => {
    modalStore.open(VARIANT_MODAL.DISEASE, {
      initialValues: {
        cultivar: [modalProps.cultivar]
      },
      handleAccept: (value: resInterface.IDiseaseList) => {
        modalStore.setModalPropsByKey(_key, {
          ...modalProps,
          initialValue: [...payload, value]
        });
      }
    });
  };

  const getLabel = (value: IDiseaseList): string => value.name;

  const isAddDiseases = userStore.isPermission(
    PERMISSIONS.regulatory_infoDisease,
    PERMISSION_CRUD.add
  );

  const props = {
    ...modalProps,
    key: ["GET_DISEASES", modalProps?.cultivar?.id],
    title: "Устойчивость к болезням",
    addButtonLabel: "Добавить заболевание",
    isDisabledBtnAdd: isAddDiseases,
    tooltipText: "У вас недостаточно прав для добавления болезни",
    handleAccept,
    handleAdd,
    fetcher,
    getLabel: getLabel,
    q: {
      cultivar: modalProps?.cultivar ? modalProps.cultivar.id : undefined,
      first_ids: modalProps?.initialValue
        ? modalProps.initialValue.map((el) => el.id).toString()
        : undefined
    }
  };

  return (
    <AddEntities<resInterface.IDiseaseList>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default SelectDiseases;
