/**
 * описание полей в списке пермишеннов
 * ? во избежание опечаток
 * ? ключи для крудов
 */
export const PERMISSIONS = {
  //! Заявка
  registrationStatement: "registration.statement", // модуль
  registrationStatementstatusaccepted: "registration.statementstatusaccepted", // смена статуса
  registrationStatementstatusrefinement:
    "registration.statementstatusrefinement", // смена статуса
  registrationStatementstatusrejected: "registration.statementstatusrejected", // смена статуса
  registrationStatementstatusreview: "registration.statementstatusreview", // смена статуса
  trialTrial: "trial.trial",
  registrationDistributionlist: "registration.distributionlist",
  registrationUploadStatements: "registration.uploadstatements",
  registrationSurvey: "registration.survey",
  registrationSubject: "registration.subject",
  registrationStatementduties: "registration.statementduties",
  registrationRemark: "registration.remark",
  registrationRegionstatement: "registration.regionstatement",
  registrationAgreement: "registration.agreement",
  registrationOrganizationDischarge: "registration.organizationdischarge",
  registrationDuty: "registration.duty",
  //! Планирование
  planningPlan: "planning.plan",
  planningPlanDownloadedDocuments: "planning.plandownloadeddocuments",
  planningPlanApproved: "planning.planstatusapproved",
  planningEAPlan: "planning.eaplan",
  planningEAPlanStatusApproved: "planning.eaplanstatusapproved",  
  planningPlanHPStatusApproved: "planning.planhpstatusapproved",
  planningPlanHP: "planning.planhp",
  planningPlanHPDownloadedDocuments: "planning.planhpdownloadeddocuments",
  //! План использования земли
  agriculturalLabourCultivarField: "agricultural_labour.cultivarfield",
  agriculturalLabourCultivarFieldWork: "agricultural_labour.cultivarfieldwork",
  agriculturalLabourCultivarFieldWorkChemistry: "agricultural_labour.cultivarfieldworkchemistry",
  agriculturalLabourWeatherCultivarFieldWork: "agricultural_labour.weathercultivarfieldwork",
  agriculturalLabourCultivarFieldVariety: "agricultural_labour.cultivarfieldvariety",
  agriculturalLabourCultivarFieldVarietyRate: "agricultural_labour.cultivarfieldvarietyrate",
  agriculturalLabourSeedArrival: "agricultural_labour.seedarrival",
  agricultural_labourRandomize: "agricultural_labour.randomize",
  agricultural_labourCultivarfieldWorkstatusExecution: "agricultural_labour.cultivarfieldworkstatusexecution",
  agricultural_labourCultivarFieldWorkstatusCompleted: "agricultural_labour.cultivarfieldworkstatuscompleted",
  //! Карта ГСУ
  agriculturalLabourLocation: 'agricultural_labour.location',
  agriculturalLabourField: 'agricultural_labour.field',
  weatherHistory: 'weather.weatherhistory',
  weatherYearHistory: 'weather.yearweatherhistory',

  //! С/Х работы
  agricultural_labourSeedArrival: 'agricultural_labour.seedarrival',
  agricultural_labourCultivarFieldVarietyStatusObservation: "agricultural_labour.cultivarfieldvarietystatusobservation",
  agricultural_labourCultivarFieldVarietyStatusNotSowed: "agricultural_labour.cultivarfieldvarietystatusnotsowed",
  agricultural_labourCultivarFieldVarietyStatusCompleted: "agricultural_labour.cultivarfieldvarietystatuscompleted",
  agricultural_labourCultivarFieldVarietyStatusAborted: "agricultural_labour.cultivarfieldvarietystatusaborted",
  agricultural_labourObservation: "agricultural_labour.observation",
  agricultural_labourObservationAdd: "agricultural_labour.observation",
  agricultural_labourObservationChange: "agricultural_labour.observation",
  agricultural_labourObservationDelete: "agricultural_labour.observation",
  agricultural_labourObservationIndicator: "agricultural_labour.observationindicator",
  agricultural_labourObservationIndicatorChange: "agricultural_labour.observationindicator",
  agricultural_labourObservationIndicatorDelete: "agricultural_labour.observationindicator",
  agricultural_labourObservationindicatorAdd: "agricultural_labour.observationindicator",
  agricultural_labourObservationDisease: "agricultural_labour.observationdisease",
  agricultural_labourObservationDiseaseAdd: "agricultural_labour.observationdisease",
  agricultural_labourObservationDiseaseChange: "agricultural_labour.observationdisease",
  agricultural_labourObservationDiseaseDelete: "agricultural_labour.observationdisease",
  agricultural_labourObservationWeatherResistance: "agricultural_labour.observationweatherresistance",
  agricultural_labourProductivity: "agricultural_labour.productivity",
  agricultural_labourLabCultivarFieldVariety: "agricultural_labour.labcultivarfieldvariety",
  agricultural_labourExpertAssessmentCultivarFieldVariety: "agricultural_labour.expertassessmentcultivarfieldvariety",
  agricultural_labourCultivarFieldVarietyRate: "agricultural_labour.cultivarfieldvarietyrate",
  agricultural_labourLabCultivarFieldVarietyStatusCompleted: "agricultural_labour.labcultivarfieldvarietystatuscompleted",
  agricultural_labourLabCultivarFieldVarietyStatusNotCompleted: "agricultural_labour.labcultivarfieldvarietystatusnotcompleted",
  agricultural_labourLabCultivarFieldVarietyStatusObservation: "agricultural_labour.labcultivarfieldvarietystatusobservation",
  //! Внебюджет
  extrabudgetaryComPlanFact:'extrabudgetary.complanfact',
  extrabudgetaryComCultivar:'extrabudgetary.comcultivar',
  extrabudgetaryComInvestment: 'extrabudgetary.cominvestment',
  extrabudgetaryComEffectiveness: 'extrabudgetary.comeffectiveness',
  extrabudgetaryComcultivarChemistry: 'extrabudgetary.comcultivarchemistry',
  extrabudgetaryComExpense: 'extrabudgetary.comexpense',
  extrabudgetaryComcultivarWork: 'extrabudgetary.comcultivarwork',
  extrabudgetaryComPlanFactStatusAgreed: 'extrabudgetary.complanfactstatusagreed',
  extrabudgetaryComPlanFactStatusApproved: 'extrabudgetary.complanfactstatusapproved',
  extrabudgetaryComPlanFactStatusRejected: 'extrabudgetary.complanfactstatusrejected',
  extrabudgetaryComPlanFactStatusRevision: 'extrabudgetary.complanfactstatusrevision',
  //! Справочники
  regulatory_infoMethodology: "regulatory_info.methodology",
  regulatory_infoDisease: "regulatory_info.disease",
  regulatory_infoAgriculturalmachinery: "regulatory_info.agriculturalmachinery",
  regulatory_infoCountry: "regulatory_info.country",
  regulatory_infoPost: "regulatory_info.post",
  regulatory_infoLightZone: "regulatory_info.lightzone",
  regulatory_infoRegion: "regulatory_info.region",
  regulatory_infoPlot: 'regulatory_info.plot',
  regulatory_infoBranch: 'regulatory_info.branch',
  regulatory_infoChemistry: 'regulatory_info.chemistry',
  regulatory_infoСultivarGroup: "regulatory_info.cultivargroup",
  regulatory_infoDepartment: "regulatory_info.department",
  regulatory_infoDistrict: "regulatory_info.district",
  regulatory_infoExpense: "regulatory_info.expense",
  regulatory_infoOrder: "regulatory_info.order",
  regulatory_infoRate: "regulatory_info.rate",
  regulatory_infoVariant: "regulatory_info.variant",
  regulatory_infoVegetationStage: "regulatory_info.vegetationstage",
  regulatory_infoReproduction: "regulatory_info.reproduction",
  regulatory_infoUnit: "regulatory_info.unit",
  regulatory_infoIndicator: "regulatory_info.indicator",
  regulatory_infoVegetationCauses: "regulatory_info.vegetationcauses",
  regulatory_infoCultivarUsageType: "regulatory_info.cultivarusagetype",
  regulatory_infoQuantityDistributionList: "regulatory_info.quantitydistributionlist",
  regulatory_infoMaterialType: "regulatory_info.materialtype",
  regulatory_infoOrderCultivar: "regulatory_info.ordercultivar",
  regulatory_infoOrderCultivarStatusApproved:"regulatory_info.ordercultivarstatusapproved",
  regulatory_infoOrderDownloadedDocuments: "regulatory_info.orderdownloadeddocuments",
  //! Отчеты
  //! Промежуточные модели
  regulatory_infoFile: "regulatory_info.file", //файлы в заявке
  regulatory_infoСultivar: "regulatory_info.cultivar", // культуры
  regulatory_infoVariety: "regulatory_info.variety", // сорта
  regulatory_infoEmployee: "regulatory_info.employee", // эксперты
  regulatory_infoAddress: "regulatory_info.address", // адреса
  regulatory_infoIndicatorMethodology: "regulatory_info.indicatormethodology",
  regulatory_infoVariantIndicatorMethodology: "regulatory_info.variantindicatormethodology",
  agricultural_labourTechMapPlan: "agricultural_labour.techmapplan", // тех.карты
  agricultural_labourWork: "agricultural_labour.work", // тех.карты

  trialBudgetaryTrialsCalc: 'trial.budgetarytrialscalc',

} as const;

/**
 * вариант того какой доступ интересует
 * view - чтение
 * add - добавление
 * change - редактирование
 * delete - удаление
 */
export const PERMISSION_CRUD = {
  view: "view",
  add: "add",
  change: "change",
  delete: "delete"
} as const;
