import yup from "@/utils/yup";
import dayjs from "dayjs";
import {
  IEntity,
  IPlanning
} from "@/api/interfaces/responses";
import { isNull, isUndefined } from "@/utils/helpers";

export const FIELDS_NAME = {
  PLAN_DATE: "plan_date", // Дата плана
  YEAR: "year", // год испытания
  CULTIVAR: "cultivar", // культуры
} as const;

export const initialValues = {
  [FIELDS_NAME.PLAN_DATE]: null,
  [FIELDS_NAME.YEAR]: null,
  [FIELDS_NAME.CULTIVAR]: null,
};

export interface IValue extends IEntity {
  label: string;
}

export interface IFormik {
  [FIELDS_NAME.PLAN_DATE]: string | null,
  [FIELDS_NAME.YEAR]: number | null,
  [FIELDS_NAME.CULTIVAR]: IValue | null,
}

export const formToValue = (values: IFormik) => ({
  [FIELDS_NAME.PLAN_DATE]: dayjs(values.plan_date).local().format("YYYY-MM-DD"),
  [FIELDS_NAME.YEAR]: values.year,
  [FIELDS_NAME.CULTIVAR]: values.cultivar?.id,
});

export const valueToForm = (values?: IPlanning | null) => {
  if(isNull(values) || isUndefined(values)){
    return initialValues
  }
  return {
    [FIELDS_NAME.PLAN_DATE]: values.plan_date ? dayjs(values.plan_date) : null,
    [FIELDS_NAME.YEAR]: values.year || null,
    [FIELDS_NAME.CULTIVAR]: values.cultivar ? {
      ...values.cultivar,
      label: values.cultivar.name
    } : null,
  }
};

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.PLAN_DATE]: yup.date().required(),
  [FIELDS_NAME.YEAR]: yup.mixed().required(),
  [FIELDS_NAME.CULTIVAR]: yup.mixed().required(),
});

export const TRIAL_TYPE_EXPERT_ASSESSMENT = "expert_assessment";
