import React, {
  ReactElement,
  useLayoutEffect
} from "react";

import { useMatches } from "@/apps/Respons";
import AvatarChanger from "@/apps/AvatarChanger";

import {
  Wrapper,
  LeftSideWrapper,
  RightSideWrapper,
  VerticalWrapper,
  NameWrapper,
  WrapperLogout,
  FormLayout,
  Form,
} from "./styles";

import {
  Box,
  Button,
  Stack,
  Typography,
  useTheme
} from "@mui/material";

import { useFormik } from "formik";

import Yup from "@/utils/yup";

import LogoutIcon from "@mui/icons-material/Logout";
import LockIcon from "@mui/icons-material/Lock";

import { useStores } from "@/hooks";
import {
  THEME_MODAL,
  VARIANT_MODAL
} from "@/apps/Modals/const";
import { observer } from "mobx-react";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import Seo from "@/components/Seo";
import { SEO_TITLES, SWR_KEYS } from "@/const";
import useSWR from "swr";
import { DownloadIcon, FileIcon, Video } from "@/components/icons";

const validationSchema = Yup.object().shape({
  last_name: Yup.string().trim().required("Обязательное поле"),
  first_name: Yup.string().trim().required("Обязательное поле"),
});

const Profile = (): ReactElement => {
  const { modalStore, userStore, api, headerStore } = useStores();

  const { matches } = useMatches();
  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      last_name: userStore.last_name,
      first_name: userStore.first_name,
      middle_name: userStore.middle_name,
      phone: "",
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: () => { },
  });

  const handleClickLogout = () => {
    api.auth.postLogout();
  };

  const handleClickChangePassword = () => {
    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.CHANGE_PASS, { theme: THEME_MODAL.W_555 });
  };

  // установление хедера страницы
  useLayoutEffect(() => {
    headerStore.setEmptyProps();
    headerStore.setHeader(VARIANT_HEADER.PROFILE);
  }, []);

  const { data: manualLinkData } = useSWR(
    SWR_KEYS.getManualLink(),
    api.settings.getManualLink,
  );

  const handleUserGuideDownload = () => {
    window.open(manualLinkData?.manual_file, '_blank');
  }

  const handleVideoInstructionOpen = () => {
    window.open(manualLinkData?.manual_video_link, "_blank")
  };

  const handleVideoInstructionDownload = () => {
    window.open(manualLinkData?.manual_video_file, "_blank")
  }

  return (
    <>
      <Seo title={SEO_TITLES.PROFILE} />
      <Wrapper theme={theme} matches={matches}>
        <LeftSideWrapper matches={matches}>
          <AvatarChanger />
          <VerticalWrapper matches={matches}>
            <NameWrapper matches={matches}>
              <Typography variant={"h1"}>{userStore.first_name}</Typography>
              <Typography variant={"h1"}>{userStore.last_name}</Typography>
              <Typography
                variant={"h2"}
                sx={{
                  marginTop: "10px",
                  color: theme.palette.blackAndWhite.gray,
                }}
              >
                {userStore.post ?? "Не указано"}
              </Typography>
            </NameWrapper>
            <WrapperLogout matches={matches}>
              <Button
                onClick={handleClickLogout}
                variant="text"
                color={"error"}
                size="medium"
              >
                <LogoutIcon sx={{ marginRight: "8px" }} /> Выйти из системы
              </Button>
            </WrapperLogout>
          </VerticalWrapper>
        </LeftSideWrapper>
        <RightSideWrapper>
          <Form onSubmit={formik.handleSubmit} noValidate>
            <FormLayout matches={matches}>
              <Typography sx={{ fontWeight: "bold" }} variant={"h3"}>
                Данные профиля
              </Typography>
              <Stack direction={"row"}>
                <Stack direction={"column"} gap={"20px"}>
                  <Box sx={{ minWidth: "250px" }}>
                    <Typography
                      variant="h5"
                      color={theme.palette.blackAndWhite.gray}
                    >
                      ФИО
                    </Typography>
                    <Typography variant="h4">
                      {userStore.getFullname()}
                    </Typography>
                  </Box>

                  <Box sx={{ minWidth: "250px" }}>
                    <Typography
                      variant="h5"
                      color={theme.palette.blackAndWhite.gray}
                    >
                      Должность
                    </Typography>
                    <Typography variant="h4">{userStore.post}</Typography>
                  </Box>
                </Stack>
                <Stack direction={"column"}></Stack>
              </Stack>

              <Typography
                sx={{ marginTop: "40px", fontWeight: "bold" }}
                variant={"h3"}
              >
                Контакты
              </Typography>
              <Stack direction={"row"}>
                <Stack direction={"column"} gap={"20px"}>
                  {userStore.phones &&
                    userStore.phones.map((el) => {
                      return (
                        <Box key={el.id} sx={{ minWidth: "250px" }} flex={1}>
                          <Typography variant="h4">
                            {el.phone} {el.name ? `- ${el.name}` : ""}
                          </Typography>
                        </Box>
                      );
                    })}
                  {userStore.emails &&
                    userStore.emails.map((el) => {
                      return (
                        <Box key={el.id} sx={{ minWidth: "250px" }} flex={1}>
                          <Typography variant="h4">{el.email}</Typography>
                        </Box>
                      );
                    })}
                </Stack>
                <Stack direction={"column"}></Stack>
              </Stack>

              <Typography
                variant="h3"
                sx={{ marginTop: "40px", fontWeight: "bold" }}
              >
                Безопасность
              </Typography>
              <Button
                onClick={handleClickChangePassword}
                variant="text"
                color="primary"
                size="medium"
                sx={{
                  pl: 1,
                }}
              >
                <LockIcon sx={{ marginRight: "8px" }} /> Изменить пароль
              </Button>

              <Typography
                variant="h3"
                sx={{ marginTop: "40px", fontWeight: "bold" }}
              >
                Помощь
              </Typography>

              {manualLinkData?.manual_file ?
                <Button
                  onClick={handleUserGuideDownload}
                  variant="text"
                  size="medium"
                  sx={{
                    pl: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                    color: theme.palette.blue.main,
                    "&:hover": {
                      color: theme.palette.blue.hoverBlue
                    }
                  }}
                >
                  <FileIcon /> руководство пользователя
                </Button> : null
              }

              {manualLinkData?.manual_video_link ?
                <Button
                  onClick={handleVideoInstructionOpen}
                  variant="text"
                  size="medium"
                  sx={{
                    pl: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                    color: theme.palette.blue.main,
                    "&:hover": {
                      color: theme.palette.blue.hoverBlue
                    }
                  }}
                >
                  <Video /> Видеоинструкция
                </Button> : null
              }

              {manualLinkData?.manual_video_file ?
                <Button
                  onClick={handleVideoInstructionDownload}
                  variant="text"
                  size="medium"
                  sx={{
                    pl: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                    color: theme.palette.blue.main,
                    "&:hover": {
                      color: theme.palette.blue.hoverBlue
                    }
                  }}
                >
                  <DownloadIcon /> Скачать видеоинструкцию
                </Button> : null
              }
            </FormLayout>
          </Form>
        </RightSideWrapper>
      </Wrapper>
    </>
  );
};

export default observer(Profile);
