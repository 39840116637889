import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import DownloadCultivarFieldForm
  from "./components/DownloadCultivarFieldForm";
import {
  IFormik,
  initialValues,
  initialValuesWithPlot,
  validationSchema,
  validationSchemaWithPlot,
} from "./const";
import { TRIAL_TYPE } from "@/const";

/**
 * Компонент модалки реализующий выбор фильтров для скачивания сортоопытов
 */

interface IProps {
  modalProps: {
    title: string,
    isSowing: boolean,
    type: string,
    handleSubmit: (values: IFormik,
      helpers: FormikHelpers<FormikValues>) => void
  };
}

const DownloadCultivarField: React.FC<IProps> = ({ modalProps }) => {
const {isSowing, type} = modalProps;

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography variant="h2" fontSize={24}>
          {modalProps.title}
        </Typography>
      </Box>
      <Divider/>
      <Formik<IFormik>
        initialValues={!isSowing && type === TRIAL_TYPE.HP ? initialValuesWithPlot : initialValues}
        onSubmit={modalProps.handleSubmit}
        validationSchema={!isSowing && type === TRIAL_TYPE.HP ? validationSchemaWithPlot : validationSchema}
        component={() => <DownloadCultivarFieldForm modalProps={modalProps}/>}
      />
    </Box> 
  );
};

export default DownloadCultivarField;
