import yup from "@/utils/yup";


export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    parameterId?: number;
    initialValues?: Partial<TForm>;
    non_field_errors?: string;
  };
  onSubmit?: () => void;
}

export enum PARAMETER_FIELD_NAME {
  NAME = "name",
  TYPE_TRIAL = "type_trial",
  IS_PARAMETER = "is_parameter",
  MIN_PARAMETER_VALUE = "min_parameter_value",
  MAX_PARAMETER_VALUE ="max_parameter_value",
  UNIT = 'unit',
}

interface IValue {
  id: number,
  name: string,
}

export type TForm = {
  name: string,
  type_trial: 'hp',
  is_parameter: boolean,
  min_parameter_value: number | null,
  max_parameter_value: number | null,
  unit: IValue | null,
  non_field_errors?: string;
}

export const mapValueToForm = (value) => {
  return {
    [PARAMETER_FIELD_NAME.NAME]: value?.name,
    [PARAMETER_FIELD_NAME.UNIT]: value?.unit ? {
      ...value?.unit,
      label: value?.unit?.name
    } : null,
    [PARAMETER_FIELD_NAME.MIN_PARAMETER_VALUE]: value?.min_parameter_value || null,
    [PARAMETER_FIELD_NAME.MAX_PARAMETER_VALUE]: value?.max_parameter_value || null,

  }
};

export const mapFormToValue = (values: TForm) => {
  return {
      [PARAMETER_FIELD_NAME.NAME]: values.name,
      [PARAMETER_FIELD_NAME.IS_PARAMETER]: values.is_parameter,
      [PARAMETER_FIELD_NAME.TYPE_TRIAL]: values.type_trial,
      [PARAMETER_FIELD_NAME.UNIT]: values.unit?.id,
      [PARAMETER_FIELD_NAME.MIN_PARAMETER_VALUE]: values.min_parameter_value,
      [PARAMETER_FIELD_NAME.MAX_PARAMETER_VALUE]: values.max_parameter_value,
  }
};

export const validationSchema = yup.object().shape({
  [PARAMETER_FIELD_NAME.UNIT]: yup
    .mixed()
    .required(),
  [PARAMETER_FIELD_NAME.NAME]: yup.string().required(),
  [PARAMETER_FIELD_NAME.MIN_PARAMETER_VALUE]: yup.number().nullable(),
  [PARAMETER_FIELD_NAME.MAX_PARAMETER_VALUE]: yup.number().nullable(),
});
