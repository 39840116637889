import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import {
  TaskOutlined,
  Check,
  MoreVert,
} from "@mui/icons-material";
import {
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import dayjs from "dayjs";

import { IOrderCultivar } from "@/api/interfaces/responses";
import { buttonDotsStyle } from "@/apps/Table/style";
import { ROUTES } from "@/apps/AppRouter/const";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { useQuery, useStores } from "@/hooks";

import {
  ORDER_CULTIVAR_STATUS,
  ORDER_CULTIVAR_STATUS_COLOR,
  EDITING_CELL,
  ORDER_CULTIVAR_STATUS_NAME,
} from "../const";
import { textCellsStyle } from "../style";
import { DeleteIcon, EditIcon } from "@/components/icons";
import theme from "@/theme";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";
import { SWR_KEYS } from "@/const";
import qs from "qs";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

/**
 * @component NumberCells
 * * компонент ячейки таблицы номера приказа
 * @param
 */

export const NumberCells = (
  _: string,
  { order }: IOrderCultivar,
): JSX.Element => (
  <Box>
    <Typography variant="body2">{order.number}</Typography>
  </Box>
);

/**
 * @component CultivarCells
 * * компонент ячейки таблицы наименование культуры
 * @param
 */

export const CultivarCells = (
  _: string,
  { cultivar }: IOrderCultivar,
): JSX.Element => (
  <Box sx={textCellsStyle()}>
    <Typography variant="caption">
      {cultivar?.name}
    </Typography>
  </Box>
);

/**
 * @component StatusCells
 * * компонент ячейки таблицы статуса плана
 * @param
 */

export const StatusCells = (
  _: string,
  { status }: IOrderCultivar,
): JSX.Element => {
  const statusText = status
    ? ORDER_CULTIVAR_STATUS[status]
    : "";
  const statusColors = status
    ? ORDER_CULTIVAR_STATUS_COLOR[status]
    : "";

  return (
    <Stack
      color={statusColors}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        border: "1px solid",
        p: "2px 6px 2px 2px",
        borderRadius: 20,
        width: "fit-content",
      }}
    >
      {status === ORDER_CULTIVAR_STATUS.draft ? (
        <TaskOutlined />
      ) : (
        <Check />
      )}
      <Typography
        color={"inherit"}
        sx={{
          fontSize: "12px",
          marginLeft: "3px",
        }}
      >
        {statusText}
      </Typography>
    </Stack>
  );
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCells = (
  _: string,
  cell: IOrderCultivar,
  itemsInPage: any[],
): JSX.Element => {
  const {
    api,
    modalStore,
    toastStore,
    swrStore,
    userStore,
  } = useStores();
  const [isOpen, setIsOpen] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(isOpen);
  const navigate = useNavigate();

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setIsOpen(event.currentTarget);
  };

  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setIsOpen(null);
  };

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: "true" },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);

  const mutateList =
    swrStore.mutators[SWR_KEYS.getOrderCultivarList()];

  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();

  const mutateWithCheckingLast = () => {
    // если один эл-т на странице - перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateList && mutateList();
    }
  };

  const handleDeleteCultivar = (resolve, reject) => {
    api.regulatoryInfo
      .deleteOrderCultivar(cell.id)
      .then(() => {
        resolve();
        mutateWithCheckingLast();
      })
      .catch(reject);
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.cultivar,
      id: cell.id,
      handleDelete: handleDeleteCultivar,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(
      VARIANT_MODAL.OK_OR_NOT_DELETE,
      modalProp,
    );
  };

  const handleClickEdit = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    navigate(
      `/${ROUTES.planning}/${cell.id}/${ROUTES.editCultivar}`,
    );
  };

  const handleChangeStatusApproved = (e) => {
    e.stopPropagation();

    api.regulatoryInfo
      .updateOrderCultivar(cell.id, {
        status: ORDER_CULTIVAR_STATUS_NAME.approved,
      })
      .then(() => {
        mutateList && mutateList();
        setIsOpen(null);
      })
      .catch((err) => {
        toastStore.createToast({
          type: TOAST_TYPES.ALERT,
          toastProps: {
            message: errorToString(err),
            severity: "error",
          },
        });
      });
  };


  const canEditPlan = userStore.isPermission(
    PERMISSIONS.regulatory_infoOrderCultivar,
    PERMISSION_CRUD.change
  );

  const canDeletePlan = userStore.isPermission(
    PERMISSIONS.regulatory_infoOrderCultivar,
    PERMISSION_CRUD.delete
  );

  const canApprovedPlan = userStore.isPermission(
    PERMISSIONS.regulatory_infoOrderCultivarStatusApproved,
    PERMISSION_CRUD.change
  );

  if (!canEditPlan && !canDeletePlan && !canApprovedPlan) return <></>;

  return (
    <Box>
      {cell.status == "approved" ? null : (
        <IconButton
          sx={buttonDotsStyle()}
          onClick={handleToggle}
        >
          <MoreVert />
        </IconButton>
      )}

      <Menu
        anchorEl={isOpen}
        open={open}
        onClose={handleClose}
      >
        {cell.status === "draft" && canApprovedPlan ? (
          <MenuItem onClick={handleChangeStatusApproved}>
            <Check
              sx={{ color: theme.palette.green.main }}
            />
            <Typography
              sx={{ color: theme.palette.green.main }}
            >
              Утвердить
            </Typography>
          </MenuItem>
        ) : null}

        {canEditPlan &&
          <>
            <MenuItem onClick={handleClickEdit}>
              <EditIcon />
              {EDITING_CELL.editing}
            </MenuItem>

            <Divider />
          </>
        }

        {canDeletePlan &&
          <MenuItem onClick={handleClickDelete}>
            <DeleteIcon />
            {EDITING_CELL.delete}
          </MenuItem>
        }
      </Menu>
    </Box>
  );
};

/**
 * @component DateCells
 * * компонент ячейки таблицы времени утверждения
 * @param
 */

export const DateCells = (
  _: string,
  { order }: IOrderCultivar,
): JSX.Element => (
  <Box>
    <Typography variant="body2">
      {order?.order_date
        ? dayjs(order?.order_date).format("DD.MM.YYYY")
        : "-"}
    </Typography>
  </Box>
);
